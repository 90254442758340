import * as React from "react";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { repository } from "clientInstance";
import { Checkbox } from "components/form";
import { LibraryVariableSetResource, VariableSetContentType } from "client/resources/libraryVariableSetResource";
import { List } from "components/List/List";
import { ResourceCollection } from "client/resources/resourceCollection";
import { ProjectResource } from "client/resources/projectResource";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import routeLinks from "../../../../routeLinks";
import InfoDialogLayout from "components/DialogLayout/InfoDialogLayout";
import { SimpleList } from "../../../../components/List/SimpleList";
import { Callout, CalloutType } from "components/Callout";
import * as styles from "./SelectScriptModules.less";

class ScriptModuleList extends List<LibraryVariableSetResource> {}

class ScriptModuleListSimple extends SimpleList<LibraryVariableSetResource> {}

interface SelectScriptModulesProps {
    projectId: string;

    saveDone(): void;
}

interface SelectScriptModulesState extends DataBaseComponentState {
    scriptModules: ResourceCollection<LibraryVariableSetResource>;
    currentlySelectedScriptModules: LibraryVariableSetResource[];
    project: ProjectResource;
    loaded: boolean;
    onlyCurrentlySelected: boolean;
    originallySelected: string[];
}

class SelectScriptModules extends DataBaseComponent<SelectScriptModulesProps, SelectScriptModulesState> {
    constructor(props: SelectScriptModulesProps) {
        super(props);

        this.state = {
            loaded: false,
            scriptModules: null,
            currentlySelectedScriptModules: null,
            project: null,
            onlyCurrentlySelected: false,
            originallySelected: null,
        };
    }

    async componentDidMount() {
        return this.doBusyTask(async () => {
            const [scriptModules, project] = await Promise.all([repository.LibraryVariableSets.list({ take: 8, contentType: VariableSetContentType.ScriptModule }), repository.Projects.get(this.props.projectId)]);

            this.setState({ scriptModules, project, loaded: true, originallySelected: project.IncludedLibraryVariableSetIds });
        });
    }

    async save() {
        return this.doBusyTask(async () => {
            await repository.Projects.modify(this.state.project);
            this.props.saveDone();
        });
    }

    handleSelectionChange = (state: boolean) => {
        this.setState({ onlyCurrentlySelected: state, loaded: false }, () =>
            this.doBusyTask(async () => {
                if (!this.state.currentlySelectedScriptModules) {
                    const scriptModules = await repository.LibraryVariableSets.all({ contentType: VariableSetContentType.ScriptModule });
                    const currentlySelectedScriptModules = scriptModules.filter(item => this.state.originallySelected.includes(item.Id));
                    this.setState({ currentlySelectedScriptModules, loaded: true });
                } else {
                    this.setState({ loaded: true });
                }
            })
        );
    };

    renderList = () => {
        if (!this.state.loaded) {
            return null;
        }
        if (this.state.onlyCurrentlySelected) {
            return <ScriptModuleListSimple filterHintText="Filter by name or description" items={this.state.currentlySelectedScriptModules} onFilter={this.filter} onRow={this.buildRow} />;
        } else {
            const additionalRequestParams = new Map<string, any>();
            additionalRequestParams.set("contentType", VariableSetContentType.ScriptModule);
            return (
                <ScriptModuleList
                    filterHintText="Filter by name or description"
                    showPagingInNumberedStyle={true}
                    initialData={this.state.scriptModules}
                    onRow={this.buildRow}
                    onFilter={this.filter}
                    filterSearchEnabled={true}
                    apiSearchParams={["partialName"]}
                    additionalRequestParams={additionalRequestParams}
                />
            );
        }
    };

    renderCurrentlySelectedCheckbox = () => {
        if (!this.state.loaded) {
            return null;
        }

        return (
            <div style={{ width: "100%", display: "flex", flexDirection: "row-reverse", height: 0 }}>
                <div
                    style={{
                        top: "1rem",
                        position: "relative",
                    }}
                >
                    <Checkbox label="Only Currently Selected" value={this.state.onlyCurrentlySelected} onChange={this.handleSelectionChange} />
                </div>
            </div>
        );
    };

    render() {
        if (this.state.loaded && this.state.scriptModules.Items.length === 0) {
            return (
                <InfoDialogLayout title="Include a Script Module" busy={this.state.busy} errors={this.state.errors}>
                    <p>
                        <strong>The Library is empty.</strong> Please, go to <InternalLink to={routeLinks.library.scripts.root}> the Library</InternalLink> and add some script modules first.
                    </p>
                </InfoDialogLayout>
            );
        }

        return (
            <SaveDialogLayout title="Include a Script Module" busy={this.state.busy} errors={this.state.errors} onSaveClick={() => this.save()}>
                <Callout title={"Script Modules"} type={CalloutType.Information}>
                    Included script modules will be available to all steps in both deployments and runbooks for this project.
                </Callout>
                {this.renderCurrentlySelectedCheckbox()}
                {this.renderList()}
            </SaveDialogLayout>
        );
    }

    private filter = (filter: string, item: LibraryVariableSetResource) => {
        const matchesFilter = (n: string) => n.toLowerCase().includes(filter.toLowerCase());

        return !filter || filter.length === 0 || (item.Name && matchesFilter(item.Name)) || (item.Description && matchesFilter(item.Description));
    };

    private buildRow = (item: LibraryVariableSetResource) => {
        return (
            <div>
                <Checkbox value={this.state.project.IncludedLibraryVariableSetIds.includes(item.Id)} label={item.Name} noMargin={true} onChange={checked => this.inclusionChanged(checked, item.Id)} />
                {item.Description && <p className={styles.scriptModuleDescription}>{item.Description}</p>}
            </div>
        );
    };

    private inclusionChanged = (checked: boolean, id: string) => {
        if (checked) {
            this.setState(state => ({
                project: {
                    ...state.project,
                    IncludedLibraryVariableSetIds: [...state.project.IncludedLibraryVariableSetIds, id],
                },
            }));
        } else {
            this.setState(state => ({
                project: {
                    ...state.project,
                    IncludedLibraryVariableSetIds: state.project.IncludedLibraryVariableSetIds.filter(value => value !== id),
                },
            }));
        }
    };
}

export default SelectScriptModules;
