import { PageLayoutState, pageLayoutStateUpdated } from "components/PageLayout/reducers/pageLayoutArea";
import { connect } from "react-redux";
import IPageWrapper from "utils/pageId";
import React = require("react");

interface GlobalDispatchProps {
    onPageLayoutStateUpdated?(pageLayoutState: PageLayoutState): void;
}

export interface PageDetailProps {
    page: IPageWrapper;
}

export type PageProps = PageDetailProps & GlobalDispatchProps;

// This reports additional page information to our global state (needed so we can identify our current page
// from anywhere in our component tree ... for onboarding feedback and reporting systems).
class Page extends React.Component<PageProps> {
    componentDidMount() {
        this.registerPathAsGlobalPageId(this.props.page);
    }

    componentDidUpdate(prevProps: PageProps, prevState: PageLayoutState) {
        this.registerPathAsGlobalPageId(this.props.page);
    }

    shouldComponentUpdate(nextProps: PageProps & { children: React.ReactNode }) {
        //We need to compare individual properties in the page to avoid unintended re-renders since react generally does a shallow comparison
        return nextProps.page.Id !== this.props.page.Id || nextProps.page.Name !== this.props.page.Name || this.props.children !== nextProps.children;
    }

    render() {
        return this.props.children;
    }

    private registerPathAsGlobalPageId(page: IPageWrapper): void {
        this.props.onPageLayoutStateUpdated({ page });
    }
}

const mapGlobalActionDispatchersToProps = (dispatch: any) => {
    return {
        onPageLayoutStateUpdated: (pageLayoutState: PageLayoutState) => {
            dispatch(
                pageLayoutStateUpdated({
                    page: pageLayoutState && pageLayoutState.page,
                })
            );
        },
    };
};

const EnhancedPage = connect<{}, GlobalDispatchProps, PageDetailProps>(
    null,
    mapGlobalActionDispatchersToProps
)(Page);

export default EnhancedPage;

export const withPage = (pageProps: PageDetailProps) => {
    return <TComponentProps extends unknown>(Component: React.ComponentType<TComponentProps>) => {
        const RenderWithPage: React.StatelessComponent<TComponentProps> = componentProps => {
            return (
                <EnhancedPage {...pageProps}>
                    <Component {...componentProps} />
                </EnhancedPage>
            );
        };
        return RenderWithPage;
    };
};

type FromPropsCallback<TProps> = (mapPage: TProps) => PageDetailProps;

export const withPageFromProps = <TInnerProps extends any>(Component: React.ComponentType<TInnerProps>, mapPageProps: FromPropsCallback<TInnerProps>) => {
    const WithPageFromRouteProps: React.StatelessComponent<TInnerProps> = componentProps => {
        return (
            <EnhancedPage {...mapPageProps(componentProps)}>
                <Component {...componentProps} />
            </EnhancedPage>
        );
    };

    return WithPageFromRouteProps;
};
