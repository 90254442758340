import NamedResource from "./namedResource";
import { SensitiveValue } from "./propertyValueResource";
import { OfflineDropDestinationResource, OfflineDropDestinationType } from "./offlineDropDestinationResource";
import LinksCollection from "client/resources/linksCollection";

export enum CommunicationStyle {
    None = "None",
    TentaclePassive = "TentaclePassive",
    TentacleActive = "TentacleActive",
    Ssh = "Ssh",
    OfflineDrop = "OfflineDrop",
    AzureWebApp = "AzureWebApp",
    Ftp = "Ftp",
    AzureCloudService = "AzureCloudService",
    AzureServiceFabricCluster = "AzureServiceFabricCluster",
    Kubernetes = "Kubernetes",
}

// Emulating a resource so we can refer to this enum in dropdownlists easily etc.
export interface CommunicationStyleResource {
    Id: CommunicationStyle;
    Name: string;
}

export interface TentacleDetailsResource {
    UpgradeLocked: boolean;
    Version: string;
    UpgradeSuggested: boolean;
    UpgradeRequired: boolean;
}

export interface EndpointResource extends NamedResource {
    CommunicationStyle: CommunicationStyle;
}

export interface TentacleEndpointResource extends EndpointResource {
    Thumbprint: string;
    TentacleVersionDetails: TentacleDetailsResource;
    CertificateSignatureAlgorithm: string;
}

export interface ListeningTentacleEndpointResource extends TentacleEndpointResource {
    CommunicationStyle: CommunicationStyle.TentaclePassive;
    Uri: string;
    ProxyId: string;
}

export interface PollingTentacleEndpointResource extends TentacleEndpointResource {
    CommunicationStyle: CommunicationStyle.TentacleActive;
    Uri: string;
}

// tslint:disable-next-line:no-empty-interface
export type AgentlessEndpointResource = EndpointResource;

export interface AzureWebAppEndpointResource extends AgentlessEndpointResource {
    CommunicationStyle: CommunicationStyle.AzureWebApp;
    AccountId: string;
    ResourceGroupName: string;
    WebAppName: string;
    WebAppSlotName: string;
    DefaultWorkerPoolId: string;
}

export interface IEndpointWithMultipleAuthentication {
    AuthenticationType: string;
}

export const KubernetesCertificate: string = "KubernetesCertificate";
export const KubernetesStandard: string = "KubernetesStandard";
export const KubernetesAzure: string = "KubernetesAzure";
export const KubernetesAws: string = "KubernetesAws";

export class KubernetesCertificateAuthentication implements IEndpointWithMultipleAuthentication {
    AuthenticationType: string;
    ClientCertificate: string;

    constructor() {
        this.AuthenticationType = KubernetesCertificate;
    }
}

export class KubernetesStandardAccountAuthentication implements IEndpointWithMultipleAuthentication {
    AuthenticationType: string;
    AccountId: string;

    constructor() {
        this.AuthenticationType = KubernetesStandard;
    }
}

export class KubernetesAzureAuthentication extends KubernetesStandardAccountAuthentication {
    ClusterName: string;
    ClusterResourceGroup: string;

    constructor() {
        super();
        this.AuthenticationType = KubernetesAzure;
    }
}

export class KubernetesAwsAuthentication extends KubernetesStandardAccountAuthentication {
    AccountId: string;
    ClusterName: string;

    constructor() {
        super();
        this.AuthenticationType = KubernetesAws;
    }
}

export interface KubernetesEndpointResource extends AgentlessEndpointResource {
    Id: string;
    Links: LinksCollection;
    Name: string;
    AccountType: string;
    ClusterUrl: string;
    ClusterCertificate: string;
    Namespace: string;
    SkipTlsVerification: string;
    ProxyId: string;
    DefaultWorkerPoolId: string;
    CommunicationStyle: CommunicationStyle.Kubernetes;
    Authentication: KubernetesAwsAuthentication | KubernetesAzureAuthentication | KubernetesStandardAccountAuthentication | KubernetesCertificateAuthentication;
}

export interface AzureServiceFabricClusterEndpointResource extends AgentlessEndpointResource {
    CommunicationStyle: CommunicationStyle.AzureServiceFabricCluster;
    ConnectionEndpoint: string;
    SecurityMode: AzureServiceFabricSecurityMode;
    ServerCertThumbprint: string;
    ClientCertVariable: string;
    CertificateStoreLocation: string;
    CertificateStoreName: string;
    AadCredentialType: AADCredentialType;
    AadClientCredentialSecret: string;
    AadUserCredentialUsername: string;
    AadUserCredentialPassword: SensitiveValue;
    DefaultWorkerPoolId: string;
}

export enum AzureServiceFabricSecurityMode {
    Unsecure = "Unsecure",
    SecureClientCertificate = "SecureClientCertificate",
    SecureAzureAD = "SecureAzureAD",
}

export enum AADCredentialType {
    ClientCredential = "ClientCredential",
    UserCredential = "UserCredential",
}

export enum AzureCloudServiceEndpointDeploymentSlot {
    Staging = "Staging",
    Production = "Production",
}

export interface AzureCloudServiceEndpointResource extends AgentlessEndpointResource {
    CommunicationStyle: CommunicationStyle.AzureCloudService;
    AccountId: string;
    CloudServiceName: string;
    DefaultWorkerPoolId: string;
    StorageAccountName: string;
    Slot: AzureCloudServiceEndpointDeploymentSlot;
    SwapIfPossible: boolean;
    UseCurrentInstanceCount: boolean;
}

export interface CloudRegionEndpointResource extends AgentlessEndpointResource {
    DefaultWorkerPoolId: string;
}

export interface OfflineDropEndpointResource extends AgentlessEndpointResource {
    Destination: OfflineDropDestinationResource;
    SensitiveVariablesEncryptionPassword: SensitiveValue;
    ApplicationsDirectory: string;
    OctopusWorkingDirectory: string;
}

export interface SshEndpointResource extends AgentlessEndpointResource {
    AccountId: string;
    Host: string;
    Port: number;
    Fingerprint: string;
    Uri: string;
    ProxyId: string;
    DotNetCorePlatform: string;
}

export default EndpointResource;
