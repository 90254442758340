import FilterSearchBox from "../../../components/FilterSearchBox/index";
import ComponentRow from "../../../components/ComponentRow/index";
import * as React from "react";
import { DashboardFilters, DimensionTypes } from "areas/projects/components/DashboardDataSource/DataCube";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import ProjectDashboard from "areas/projects/components/ProjectDashboard/index";
import DashboardDataSource, { DashboardDataSourceState, dashboardDataHasProjects, dashboardDataHasEnvironments, hasReachedMinimumThresholdForHidingOnboardingOnDashboard } from "areas/projects/components/DashboardDataSource/DashboardDataSource";
import AreaTitle from "components/AreaTitle";
import { NavigationButton } from "components/Button";
import PaperLayout from "../../../components/PaperLayout/index";
import { GettingStartedDetails } from "components/GettingStarted/GettingStartedDetails";
import { Select } from "components/form";
import cn from "classnames";
import { sortBy, isEqual } from "lodash";
import MobileDetect from "mobile-detect";
import routeLinks from "../../../routeLinks";
import { IQuery, QueryStringFilters } from "components/QueryStringFilters/QueryStringFilters";
import { DashboardRenderMode } from "client/resources/performanceConfigurationResource";
import { client } from "clientInstance";
import DrawerWrapperLayout from "components/Drawer/DrawerWrapperLayout";
import Callout, { CalloutType } from "components/Callout";
import InternalLink from "components/Navigation/InternalLink";
import Section from "components/Section";
import DashboardLimitSummary from "./DashboardLimitSummary";
const styles = require("./style.less");

interface DashboardOverviewFilter {
    projectGroupId: string;
    projectName: string;
}

interface DashboardOverviewState {
    filter: DashboardOverviewFilter;
    matchCount: number;
}

const defaultFilter: DashboardOverviewFilter = {
    projectName: "",
    projectGroupId: "",
};

interface DashboardOverviewQuery extends IQuery {
    projectGroupId?: string;
    projectName?: string;
}

const DashboardQueryStringFilters = QueryStringFilters.For<DashboardOverviewFilter, DashboardOverviewQuery>();

class DashboardOverview extends BaseComponent<{}, DashboardOverviewState> {
    constructor(props: {}) {
        super(props);
        this.state = {
            filter: defaultFilter,
            matchCount: 0,
        };
    }

    render() {
        if (client.spaceId === null) {
            return (
                <main className={styles.container}>
                    <AreaTitle link={routeLinks.root} title="Dashboard" />
                </main>
            );
        }

        return (
            <main className={styles.container}>
                <DashboardQueryStringFilters filter={this.state.filter} getQuery={getQueryFromFilters} getFilter={getFilter} onFilterChange={filter => this.setState({ filter })} />
                <AreaTitle link={routeLinks.root} title="Dashboard">
                    <NavigationButton label="Configure" href={routeLinks.dashboard.configure} />
                </AreaTitle>
                <DrawerWrapperLayout>
                    <DashboardDataSource filters={this.createDashboardFilters()} render={this.renderDataSource} />
                </DrawerWrapperLayout>
            </main>
        );
    }

    private showDashboard(dashboardData: DashboardDataSourceState) {
        if (dashboardDataHasProjects(dashboardData) && !dashboardDataHasEnvironments(dashboardData)) {
            return (
                <Callout type={CalloutType.Warning} title="The dashboard cannot be rendered without environments">
                    To tell Octopus where to deploy your software, <InternalLink to={routeLinks.infrastructure.environments.root}>create your first environment</InternalLink> in the infrastructure area.
                </Callout>
            );
        }

        const cube = dashboardData.cube;
        const groups = sortBy(cube.projectGroupIndex, g => g.Name.toLowerCase()).map(g => ({ value: g.Id, text: g.Name }));
        const hasFilter = !isEqual(defaultFilter, this.state.filter);
        // Disable autoFocus filtering for mobile (Android has issues and is annoying users).
        const md = new MobileDetect(window.navigator.userAgent);
        const autoFocus = md.isPhoneSized() ? false : true;

        return (
            <Section>
                <div className={styles.filterHeaderContainer} key="A" role="search">
                    <div className={styles.filterFieldContainer}>
                        <ComponentRow className={styles.filter}>
                            {groups.length > 1 && (
                                <div className={styles.filterField}>
                                    <Select hintText="Project group" items={groups} onChange={this.handleGroupChange} value={this.state.filter.projectGroupId} allowClear={true} />
                                </div>
                            )}
                            <div className={styles.filterField}>
                                <FilterSearchBox hintText="Project name" value={this.state.filter.projectName} onChange={this.handleNameChange} autoFocus={autoFocus} fullWidth={true} />
                            </div>
                            <DashboardLimitSummary matchCount={this.state.matchCount} projectLimit={dashboardData.projectLimit} hasFilter={hasFilter} totalProjects={Object.keys(dashboardData.cube.projectIndex).length} />
                        </ComponentRow>
                    </div>
                </div>
                <ProjectDashboard
                    key="B"
                    cube={dashboardData.cube}
                    filters={this.createDashboardFilters()}
                    maximumRows={dashboardData.projectLimit}
                    showDeploymentCounts={true}
                    onProjectCountChanged={this.handleProjectCountChange}
                    dashboardRenderMode={DashboardRenderMode.VirtualizeColumns}
                />
            </Section>
        );
    }

    private renderDataSource = (dataSource: DashboardDataSourceState) => {
        if (hasReachedMinimumThresholdForHidingOnboardingOnDashboard(dataSource)) {
            return (
                <div className={styles.onboardingPanelOuterContainer}>
                    <div className={styles.onboardingPanelInnerContainer}>
                        <GettingStartedDetails showIntroHeading={true} />
                    </div>
                </div>
            );
        }

        return (
            <PaperLayout flatStyle={true} fullWidth={true} busy={dataSource.busy} errors={dataSource.errors} className={styles.paper}>
                {dataSource.hasInitialLoaded && this.showDashboard(dataSource)}
            </PaperLayout>
        );
    };

    private handleGroupChange = (projectGroupId: string) => {
        this.setState(prev => ({ filter: { ...prev.filter, projectGroupId } }));
    };

    private handleNameChange = (projectName: string) => {
        this.setState(prev => ({ filter: { ...prev.filter, projectName } }));
    };

    private createDashboardFilters: () => DashboardFilters = () => {
        const filter = this.state.filter;
        const groupFilters = filter.projectGroupId !== "" ? { [filter.projectGroupId]: true } : null;
        const projectNameFilters = filter.projectName !== "" ? { [filter.projectName]: true } : null;
        return {
            rowDimension: DimensionTypes.Project,
            columnDimension: DimensionTypes.Environment,
            groupBy: DimensionTypes.ProjectGroup,
            [DimensionTypes.ProjectGroup]: groupFilters,
            [DimensionTypes.ProjectName]: projectNameFilters,
        };
    };

    private handleProjectCountChange = (matchCount: number) => {
        // don't re-render if count is the same. Easier to check
        // here than shouldComponentUpdate
        if (matchCount !== this.state.matchCount) {
            this.setState({ matchCount });
        }
    };
}

function getQueryFromFilters(filter: DashboardOverviewFilter): DashboardOverviewQuery {
    return {
        projectGroupId: filter.projectGroupId,
        projectName: filter.projectName,
    };
}

function getFilter(query: DashboardOverviewQuery): DashboardOverviewFilter {
    return {
        projectGroupId: query.projectGroupId || "",
        projectName: query.projectName || "",
    };
}

export default DashboardOverview;
