import * as React from "react";
import { ExpandableFormSection, Summary } from "components/form";
import DateFormatter from "utils/DateFormatter/DateFormatter";
import { Moment } from "moment";
import TimePicker from "areas/projects/components/Releases/Deployments/NowOrLater/TimePicker/timePicker";

interface ScheduleStartProps {
    startTime?: Moment;
    onStartTimeChanged?(startTime: Moment): void;
}

export default class ScheduleStart extends React.Component<ScheduleStartProps, any> {
    render() {
        return (
            <div>
                {this.props.startTime && (
                    <ExpandableFormSection
                        errorKey="ScheduleStartTime"
                        title="Start Time"
                        summary={
                            this.props.startTime
                                ? Summary.summary(
                                      <span>
                                          <strong>{DateFormatter.dateToCustomFormat(this.props.startTime, "hh:mm A")}</strong>
                                      </span>
                                  )
                                : Summary.placeholder("Please select at what time the schedule should execute.")
                        }
                        help="Select at what time the schedule should execute."
                    >
                        <TimePicker time={this.props.startTime} onTimeChanged={this.props.onStartTimeChanged} />
                    </ExpandableFormSection>
                )}
            </div>
        );
    }
}
