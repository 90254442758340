import * as React from "react";
import BaseComponent from "components/BaseComponent";
import { StringRadioButtonGroup, RadioButton, Note } from "components/form";
import { BoundSelect } from "components/form/Select/Select";
import { ActionTemplateParameterResource, ControlType } from "client/resources";
import PackageSelector, { PackageSelectorProps } from "./PackageSelector";
import { PackageSelectionMode } from "client/resources/packageReference";

interface DeferredPackageSelectorProps extends PackageSelectorProps {
    packageSelectionMode: PackageSelectionMode;
    packageSelectionModeError?: string;
    parameters: ActionTemplateParameterResource[];
    packageParameterName: string;
    packageParameterError?: string;
    onPackageSelectionModeChange(value: PackageSelectionMode): void;
    onPackageParameterChange(value: string): void;
}

export default class DeferredPackageSelector extends BaseComponent<DeferredPackageSelectorProps, any> {
    constructor(props: DeferredPackageSelectorProps) {
        super(props);
    }

    render() {
        if (this.props.projectId) {
            return this.renderPackageSelector();
        }

        const packageSelectionMode = this.props.packageSelectionMode || PackageSelectionMode.Immediate;

        return (
            <StringRadioButtonGroup value={packageSelectionMode} error={this.props.packageSelectionModeError} onChange={this.props.onPackageSelectionModeChange}>
                <RadioButton value={PackageSelectionMode.Immediate} label="Select specific package" />
                {packageSelectionMode === PackageSelectionMode.Immediate && this.renderPackageSelector()}
                <RadioButton value={PackageSelectionMode.Deferred} label="Let the project select the package" />
                {packageSelectionMode === PackageSelectionMode.Deferred && this.renderPackageParameterSelector()}
            </StringRadioButtonGroup>
        );
    }

    private renderPackageSelector() {
        return (
            <PackageSelector
                packageId={this.props.packageId}
                feedId={this.props.feedId}
                onPackageIdChange={this.props.onPackageIdChange}
                onFeedIdChange={this.props.onFeedIdChange}
                packageIdError={this.props.packageIdError}
                feedIdError={this.props.feedIdError}
                projectId={this.props.projectId}
                feeds={this.props.feeds}
                localNames={this.props.localNames}
                feedType={this.props.feedType}
                refreshFeeds={this.props.refreshFeeds}
            />
        );
    }

    private renderPackageParameterSelector() {
        const packageParameters = this.props.parameters.filter(p => p.DisplaySettings["Octopus.ControlType"] === ControlType.Package);

        return (
            <div>
                <BoundSelect
                    variableLookup={{
                        localNames: this.props.localNames,
                    }}
                    value={this.props.packageParameterName}
                    allowClear={true}
                    resetValue={""}
                    onChange={this.props.onPackageParameterChange}
                    items={packageParameters.map((p: ActionTemplateParameterResource) => ({ value: p.Name, text: p.Label || p.Name }))}
                    error={this.props.packageParameterError}
                    autoFocus={this.props.packageSelectionMode === PackageSelectionMode.Deferred}
                    label="Package parameter"
                    empty={packageParameters.length === 0 ? "No package parameters found" : null}
                    hideBindButton={true}
                />
                <Note>When using this step template in a process, the user will be prompted to select a package.</Note>
            </div>
        );
    }
}
