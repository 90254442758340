import * as React from "react";
import { RunbookSnapshotResource } from "client/resources";
import DateFormatter from "utils/DateFormatter";
import Chip from "components/Chips/Chip";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import routeLinks from "routeLinks";
import { info, white, success } from "theme/colors";
import WarningIcon from "components/WarningIcon/WarningIcon";
const styles = require("./LastPublishedChip.less");

export const RunbookSnapshotInformation: React.FC<{ runbookSnapshot: RunbookSnapshotResource; isOutOfDate?: boolean }> = ({ runbookSnapshot, isOutOfDate }) => {
    const content = runbookSnapshot && (
        <div className={styles.lastPublishedTooltipContent}>
            {isOutOfDate && (
                <>
                    <div>
                        <WarningIcon color={white} iconOverride="fa-info-circle" /> The runbook has changed since this snapshot was published
                    </div>
                    <hr />
                </>
            )}
            <div>
                Name: <strong>{runbookSnapshot.Name}</strong>
            </div>
            {runbookSnapshot.Notes && <div>{runbookSnapshot.Notes}</div>}
            <div>
                Created: <strong>{DateFormatter.dateToShortFormat(runbookSnapshot.Assembled)}</strong>
            </div>
        </div>
    );
    return content;
};

type FormTitleAndLastPublishedChipProps = { runbookName: string };

export const FormTitleForRunbook: React.FC<FormTitleAndLastPublishedChipProps> = (props: FormTitleAndLastPublishedChipProps) => {
    return (
        <div className={styles.formTitle}>
            <div>{props.runbookName}</div>
        </div>
    );
};

export interface LastPublishedChipProps {
    projectSlug: string;
    publishedRunbookSnapshot: RunbookSnapshotResource;
    isOutOfDate?: boolean;
}

export const LastPublishedChip: React.FC<LastPublishedChipProps> = ({ projectSlug, publishedRunbookSnapshot, isOutOfDate }) => {
    if (!publishedRunbookSnapshot) {
        return null;
    }

    const lastPublishedTooltip = <RunbookSnapshotInformation runbookSnapshot={publishedRunbookSnapshot} isOutOfDate={isOutOfDate} />;
    const chipColor = isOutOfDate ? info : success;
    return (
        <div className={styles.lastPublishedTooltipContainer}>
            <InternalLink
                to={
                    routeLinks
                        .project(projectSlug)
                        .operations.runbook(publishedRunbookSnapshot.RunbookId)
                        .runbookSnapshot(publishedRunbookSnapshot.Id).root
                }
            >
                <Chip fullWidth={true} description={lastPublishedTooltip} bordercolor={chipColor} labelColor={chipColor} backgroundColor={white}>
                    {isOutOfDate && <WarningIcon color={info} iconOverride="fa-info-circle" />}
                    Published {DateFormatter.dateToShortFormat(publishedRunbookSnapshot.Assembled)}
                </Chip>
            </InternalLink>
        </div>
    );
};

export default LastPublishedChip;
