import * as React from "react";
import moment from "moment";
import { Moment } from "moment";
const styles = require("./style.less");
import { DatePicker as DatePickerPopover } from "components/form";
import combineDateTime from "../combineDateTime";
import { Errors } from "components/DataBaseComponent";

interface DatePickerProps {
    date: Moment;
    onDateChanged(date: Moment): void;
}

interface DatePickerState {
    datePopupValue: Moment;
    busy?: Promise<void>;
    errors?: Errors;
}

export default class DatePicker extends React.Component<DatePickerProps, DatePickerState> {
    constructor(props: DatePickerProps) {
        super(props);
        this.state = {
            datePopupValue: moment(props.date),
        };
    }

    componentWillReceiveProps(nextProps: DatePickerProps) {
        this.setState({
            datePopupValue: moment(nextProps.date),
        });
    }

    handleDateChanged = (date: string) => {
        const combined = combineDateTime(date, this.props.date);
        this.props.onDateChanged(combined);
    };

    render() {
        const datePopupValue = moment(this.state.datePopupValue).toDate();
        return (
            <div className={styles.datePicker}>
                <DatePickerPopover
                    value={datePopupValue}
                    onChange={(value: any) => {
                        this.setState({ datePopupValue: value });
                        // Don't change the time component
                        const justDate = moment(value).format("YYYY-MM-DD");
                        this.handleDateChanged(justDate);
                    }}
                />
            </div>
        );
    }
}
