import * as React from "react";
import MuiPopover, { PopoverProps as MuiPopoverProps, PopoverClassKey } from "@material-ui/core/Popover";
import { TransitionProps } from "@material-ui/core/transitions/transition";
import { makeStyles } from "@material-ui/styles";
import { noOp } from "utils/noOp";
import { colors, Theme } from "theme";

export type Horizontal = "left" | "center" | "right";
export type Vertical = "top" | "center" | "bottom";

export interface Origin {
    horizontal: Horizontal;
    vertical: Vertical;
}

const useStyles = makeStyles<Theme, PopoverProps, PopoverClassKey>({ paper: { backgroundColor: colors.white } });

export interface PopoverProps {
    anchorEl?: HTMLElement;
    anchorOrigin?: Origin;
    className?: string;
    onClose?: () => void;
    open: boolean;
    style?: React.CSSProperties;
    transformOrigin?: Origin;
    disableAutoFocus?: boolean;
    getUpdatePosition?: (callback: () => void) => void;
    TransitionComponent?: React.ComponentType<TransitionProps>;
}

export const Popover: React.FC<PopoverProps> = props => {
    const classes = useStyles(props);

    return (
        <MuiPopover
            classes={classes}
            action={actions => (props.getUpdatePosition && actions ? props.getUpdatePosition(actions.updatePosition) : noOp())}
            anchorEl={props.anchorEl}
            anchorOrigin={props.anchorOrigin}
            className={props.className}
            onClose={props.onClose}
            open={props.open}
            style={props.style}
            transformOrigin={props.transformOrigin}
            disableAutoFocus={props.disableAutoFocus}
            TransitionComponent={props.TransitionComponent}
        >
            {props.children}
        </MuiPopover>
    );
};

export default Popover;
