import * as React from "react";
import { useProcessContext, useProjectContext } from "areas/projects/context";
import NavigationButton, { NavigationButtonProps } from "components/Button/NavigationButton";
import routeLinks from "routeLinks";
import { isRunbookProcessResource, isDeploymentProcessResource, processPermission } from "client/resources";
import { PermissionCheck } from "components/PermissionCheck";

type AddRunbookStepButtonPropChanges = Omit<NavigationButtonProps, "href" | "label"> & { href?: NavigationButtonProps["href"]; label?: NavigationButtonProps["label"] };

type AddRunbookStepButtonProps = {
    projectId: string;
    processId: string;
    runbookId: string;
    projectSlug: string;
} & AddRunbookStepButtonPropChanges;

const AddRunbookStepButton: React.FC<AddRunbookStepButtonProps> = ({ projectId, projectSlug, processId, runbookId, ...rest }) => {
    const href = routeLinks
        .project(projectSlug)
        .operations.runbook(runbookId)
        .runbookProcess.runbookProcess(processId).steps.stepTemplates;
    const processContext = useProcessContext();
    return (
        <PermissionCheck permission={processPermission(processContext.state.process)} project={projectId} wildcard={true}>
            <NavigationButton label="Add Step" href={href} {...rest} />
        </PermissionCheck>
    );
};

type AddDeploymentsStepButtonProps = {
    projectId: string;
    projectSlug: string;
};

const AddDeploymentsStepButton: React.FC<AddDeploymentsStepButtonProps> = ({ projectId, projectSlug, ...rest }) => {
    const href = routeLinks.project(projectSlug).process.stepTemplates;
    const processContext = useProcessContext();
    return (
        <PermissionCheck permission={processPermission(processContext.state.process)} project={projectId} wildcard={true}>
            <NavigationButton label="Add Step" href={href} {...rest} />
        </PermissionCheck>
    );
};

export type ContextAddStepButtonProps = AddRunbookStepButtonPropChanges & { projectSlug: string };
const ContextAddStepButton: React.FC<AddRunbookStepButtonPropChanges> = props => {
    const processContext = useProcessContext();
    const project = useProjectContext();
    const steps = processContext.state.process;

    if (isRunbookProcessResource(steps)) {
        return <AddRunbookStepButton projectSlug={project.state.model.Slug} projectId={project.state.model.Id} processId={steps.Id} runbookId={steps.RunbookId} {...props} />;
    } else if (isDeploymentProcessResource(steps)) {
        return <AddDeploymentsStepButton projectSlug={project.state.model.Slug} projectId={project.state.model.Id} {...props} />;
    }

    return null;
};

export default ContextAddStepButton;
