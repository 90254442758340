import * as React from "react";
import cn from "classnames";

const styles = require("./style.less");

export class DataTableFooter extends React.Component<any, any> {
    render() {
        return <tfoot className={cn(this.props.className, styles.dataTableFooter)}>{this.props.children}</tfoot>;
    }
}
