import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import NavigationSidebarLayout, { Navigation, NavItem } from "components/NavigationSidebarLayout/index";
import AreaTitle from "components/AreaTitle";
import BusyIndicator from "components/BusyIndicator/BusyIndicator";
import routeLinks from "routeLinks";
import Permission from "client/resources/permission";
import BusyFromPromise from "components/BusyFromPromise/BusyFromPromise";
import ErrorPanel from "components/ErrorPanel/ErrorPanel";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { DisabledChip, NewFeatureChip } from "components/Chips";
import { ProjectContextProvider, ProjectContextState } from "../../context";
import { repository } from "clientInstance";
import { ProjectGroupResource } from "client/resources";
import { connect } from "react-redux";
import { CreateReleaseButton } from "../Releases";
import { IsAllowedToSeeDeploymentsOverview } from "../ProjectsRoutes/ProjectToOverviewRedirect";
import { RunbookTemporaryCallout } from "../Runbooks/OperationsOverviewLayout";

export interface ProjectRouteParams {
    projectSlug: string;
}

interface StateProps {
    projectId?: string;
}

interface GlobalConnectedProps {
    isMultiTenancyEnabled?: boolean;
}

interface ProjectLayoutProps extends RouteComponentProps<ProjectRouteParams> {
    busy?: Promise<any> | boolean;
}

type Props = ProjectLayoutProps & StateProps & GlobalConnectedProps;
interface ProjectLayoutState extends DataBaseComponentState {
    projectGroups: ProjectGroupResource[];
}

class ProjectLayoutInternal extends DataBaseComponent<Props, ProjectLayoutState> {
    private projectId: string;
    async componentDidMount() {
        await this.doBusyTask(async () => {
            if (!this.props.projectId || this.props.projectId !== this.projectId) {
                const [project, projectGroups] = await Promise.all([repository.Projects.get(this.props.match.params.projectSlug), repository.ProjectGroups.all()]);
                this.projectId = project.Id;
                this.setState({ projectGroups });
            }
        });
    }

    render() {
        const projectSlug = this.props.match.params.projectSlug;
        return (
            <ProjectContextProvider doBusyTask={this.doBusyTask} projectIdOrSlug={projectSlug}>
                {({ state }) => {
                    const projectLogo = state.model && state.model && state.model.Links.Logo;
                    return state.model ? (
                        <main id="maincontent">
                            {this.areaTitle(state, false)}
                            {this.renderErrors()}
                            <NavigationSidebarLayout
                                logoUrl={projectLogo}
                                name={state.model.Name}
                                description={state.model.Description}
                                preNavbarComponent={
                                    <div>
                                        {state.model.IsDisabled && (
                                            <div>
                                                <DisabledChip />
                                            </div>
                                        )}
                                        <CreateReleaseButton projectId={state.model.Id} projectSlug={state.model.Slug} />
                                    </div>
                                }
                                navLinks={this.navLinks(projectSlug, state.model.Id)}
                                content={this.props.children}
                            />
                        </main>
                    ) : (
                        <AreaTitle link={routeLinks.projects.root} title="Projects" busyIndicator={this.renderBusy(true)} />
                    );
                }}
            </ProjectContextProvider>
        );
    }

    renderErrors() {
        const errors = this.state.errors;
        if (!errors) {
            return null;
        }
        return <ErrorPanel message={errors.message} details={errors.details} detailLinks={errors.detailLinks} helpText={errors.helpText} helpLink={errors.helpLink} />;
    }

    private renderBusy(forceBusy: boolean) {
        return <BusyFromPromise promise={this.props.busy || forceBusy}>{(busy: boolean) => <BusyIndicator show={busy || forceBusy} />}</BusyFromPromise>;
    }

    private areaTitle(state: ProjectContextState, forceBusy: boolean) {
        const hasAccessibleProjectGroup = this.state.projectGroups && state.model.ProjectGroupId && this.state.projectGroups.find(pg => pg.Id === state.model.ProjectGroupId);
        return !hasAccessibleProjectGroup ? (
            <AreaTitle link={routeLinks.projects.root} title="Projects" busyIndicator={this.renderBusy(forceBusy)} />
        ) : (
            <AreaTitle breadcrumbTitle="Projects" breadcrumbPath={routeLinks.projects.root} link={routeLinks.projects.filteredByGroup(state.model.ProjectGroupId)} title={hasAccessibleProjectGroup.Name} busyIndicator={this.renderBusy(forceBusy)} />
        );
    }

    private navLinks = (projectSlug: string, projectId: string) => {
        // Note: Use projectSlug from our route (and NOT this.state.project) to avoid the sidebar refreshing unnecessarily.
        const projectLinks = routeLinks.project(projectSlug || "");
        const navigationLinks: NavItem[] = [];

        let deploymentsSubLinks: NavItem[] = [];
        deploymentsSubLinks.push(
            Navigation.navItem("Overview", projectLinks.deployments.root, true, {
                permission: Permission.ReleaseView,
                project: projectId,
                tenant: "*",
            })
        );
        deploymentsSubLinks.push(
            Navigation.navItem("Process", projectLinks.deployments.process.root, null, {
                permission: Permission.ProcessView,
                project: projectId,
                tenant: "*",
            })
        );
        deploymentsSubLinks.push(
            Navigation.navItem("Channels", projectLinks.deployments.channels, null, {
                permission: Permission.ProcessView,
                project: projectId,
                tenant: "*",
            })
        );
        deploymentsSubLinks.push(
            Navigation.navItem("Releases", projectLinks.deployments.releases, null, {
                permission: Permission.ReleaseView,
                project: projectId,
                tenant: "*",
            })
        );
        deploymentsSubLinks.push(
            Navigation.navItem("Triggers", projectLinks.triggers, null, {
                permission: Permission.TriggerView,
                project: projectId,
            })
        );
        deploymentsSubLinks = deploymentsSubLinks.filter(x => !!x); // Strip any that failed permission checks.

        if (IsAllowedToSeeDeploymentsOverview(projectId) && deploymentsSubLinks.length > 0) {
            navigationLinks.push(Navigation.navGroup("Deployments", projectLinks.deployments.root, deploymentsSubLinks, null, true));
        }

        let operationsSubLinks: NavItem[] = [];
        operationsSubLinks.push(
            Navigation.navItem("Overview", projectLinks.operations.root, true, {
                permission: Permission.RunbookView,
                project: projectId,
                wildcard: true,
            })
        );
        operationsSubLinks.push(
            Navigation.navItem("Runbooks", projectLinks.operations.runbooks, null, {
                permission: Permission.RunbookView,
                project: projectId,
                wildcard: true,
            })
        );
        operationsSubLinks.push(
            Navigation.navItem("Triggers", projectLinks.operations.triggers, null, {
                permission: Permission.TriggerView,
                project: projectId,
            })
        );
        operationsSubLinks = operationsSubLinks.filter(x => !!x); // Strip any that failed permission checks.
        navigationLinks.push(
            Navigation.navGroup(
                <div>
                    Operations{" "}
                    <RunbookTemporaryCallout>
                        <NewFeatureChip />
                    </RunbookTemporaryCallout>
                </div>,
                projectLinks.operations.root,
                operationsSubLinks,
                {
                    permission: Permission.RunbookView,
                    project: projectId,
                    wildcard: true,
                },
                true
            )
        );
        let variableSubLinks: NavItem[] = [];
        variableSubLinks.push(Navigation.navItem("Project", projectLinks.variables.root, true));
        if (this.props.isMultiTenancyEnabled) {
            variableSubLinks.push(Navigation.navItem("Project Templates", projectLinks.variables.projectTemplates));
            variableSubLinks.push(Navigation.navItem("Common Templates", projectLinks.variables.commonTemplates));
        }
        variableSubLinks.push(Navigation.navItem("Library Sets", projectLinks.variables.library));
        variableSubLinks.push(Navigation.navItem("All", projectLinks.variables.all));
        variableSubLinks.push(Navigation.navItem("Preview", projectLinks.variables.preview));
        variableSubLinks = variableSubLinks.filter(x => !!x); // Strip any that failed permission checks.
        navigationLinks.push(
            Navigation.navGroup("Variables", projectLinks.variables.root, variableSubLinks, {
                permission: Permission.VariableView,
                project: projectId,
                wildcard: true,
            })
        );
        navigationLinks.push(
            Navigation.navItem("Tasks", projectLinks.tasks, null, {
                permission: Permission.TaskView,
                project: projectId,
                projectGroup: "*",
                tenant: "*",
            })
        );
        navigationLinks.push(
            Navigation.navItem("Settings", projectLinks.settings, null, {
                permission: Permission.ProjectView,
                project: projectId,
                projectGroup: "*",
                tenant: "*",
            })
        );
        return navigationLinks;
    };
}

const mapGlobalStateToProps = (state: GlobalState): GlobalConnectedProps => {
    return {
        isMultiTenancyEnabled: state.configurationArea.currentSpace.isMultiTenancyEnabled,
    };
};

export default withRouter(connect(mapGlobalStateToProps)(ProjectLayoutInternal));
