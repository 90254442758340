import * as React from "react";
import ActionTemplateSearchResource from "client/resources/actionTemplateSearchResource";
import Card from "components/Card";
import { divider, primary, white } from "theme/colors";
import MarkdownDescription from "components/MarkdownDescription";
import Logo from "components/Logo/Logo";
const styles = require("./styles.less");
import { stepCardClass } from "uiTestClasses";
import { LocationDescriptor } from "history";
import cn from "classnames";
import InternalLink from "components/Navigation/InternalLink";

interface ActionTemplateCardProps {
    template: ActionTemplateSearchResource;
    primaryAction: React.ReactNode;
    primaryRedirect?: LocationDescriptor;
    secondaryAction?: React.ReactNode;
}

const ActionTemplateCard: React.StatelessComponent<ActionTemplateCardProps> = (props: ActionTemplateCardProps) => {
    function formatText(text: string, maxLength: number) {
        text = (text || "").trim().replace(/\r?\n|\r/g, " ");
        return text.length > maxLength ? text.substr(0, maxLength) + " ..." : text;
    }

    function getStatusText(template: any) {
        if (template.IsBuiltIn) {
            return <span style={{ color: white }}>by Octopus Deploy</span>;
        }
        if (template.IsInstalled) {
            return <span>Installed</span>;
        }
        return <span style={{ color: primary }}>{formatText(template.Author, 24)}</span>;
    }

    function getStatusLogo(template: any) {
        if (template.IsBuiltIn) {
            return <em className="fontoctopus-octopus" style={{ color: white }} />;
        }
        if (template.IsInstalled) {
            return <em className="fa fa-check-square-o" aria-hidden="true" style={{ color: primary }} />;
        }
        return <em className="fa fa-users" aria-hidden="true" style={{ color: primary }} />;
    }

    function getStatusClass(template: any) {
        if (template.IsBuiltIn) {
            return styles.templateStatusBuiltIn;
        }
        if (template.IsInstalled) {
            return styles.templateStatusInstalled;
        }
        return styles.templateStatusNotInstalled;
    }

    function getBackgroundColor(template: ActionTemplateSearchResource) {
        if (template.IsBuiltIn) {
            return primary;
        }
        if (template.IsInstalled) {
            return divider;
        }
        return white;
    }

    return (
        <li>
            <Card
                logo={<Logo url={props.template.Links.Logo} />}
                className={stepCardClass}
                header={<div className={styles.name}>{formatText(props.template.Name, 46)}</div>}
                content={
                    <div className={styles.description}>
                        <MarkdownDescription markup={props.template.Description} />
                    </div>
                }
                includeContentFade={false} /* Don't set true here, or you see the contentFade onHover */
                footerIcon={getStatusLogo(props.template)}
                footerText={getStatusText(props.template)}
                footerBackgroundColor={getBackgroundColor(props.template)}
                onHoverView={
                    <div className={styles.onHoverActions}>
                        {props.primaryRedirect && (
                            <InternalLink className={cn(styles.onHoverPrimaryActions, props.primaryRedirect && styles.onHoverPrimaryActionsCursor)} to={props.primaryRedirect}>
                                <div className={styles.empty} />
                                <div className={styles.primaryAction}>{props.primaryAction}</div>
                            </InternalLink>
                        )}
                        {!props.primaryRedirect && (
                            <React.Fragment>
                                <div className={styles.empty} />
                                <div className={styles.primaryAction}>{props.primaryAction}</div>
                            </React.Fragment>
                        )}
                        {props.secondaryAction && <div className={styles.secondaryAction}>{props.secondaryAction}</div>}
                    </div>
                }
            />
        </li>
    );
};

ActionTemplateCard.displayName = "ActionTemplateCard";

export default ActionTemplateCard;
