import * as React from "react";
import BaseComponent from "components/BaseComponent";
import NavigationSidebarLayout, { Navigation } from "components/NavigationSidebarLayout";
import { Permission } from "client/resources";
import AreaTitle from "components/AreaTitle";
import routeLinks from "../../../../routeLinks";
import { connect, MapStateToProps } from "react-redux";

interface GlobalConnectedProps {
    isMultiTenancyEnabled: boolean;
}

class InternalLibraryLayout extends BaseComponent<GlobalConnectedProps, {}> {
    constructor(props: GlobalConnectedProps) {
        super(props);
    }

    render() {
        const navLinks = [
            Navigation.navItem("Certificates", routeLinks.library.certificates.root, null, { permission: Permission.CertificateView, wildcard: true }),
            Navigation.navItem("External Feeds", routeLinks.library.feeds, null, { permission: Permission.FeedView }),
            Navigation.navItem("Lifecycles", routeLinks.library.lifecycles, null, { permission: Permission.LifecycleView, wildcard: true }),
            Navigation.navItem("Packages", routeLinks.library.builtInRepository.root, null, { permission: Permission.FeedView }),
            Navigation.navItem("Build Information", routeLinks.library.buildInformationRepository.root, null, { permission: Permission.FeedView }),
            Navigation.navItem("Script Modules", routeLinks.library.scripts.root, null, { permission: Permission.VariableView, wildcard: true }),
            Navigation.navItem("Step Templates", routeLinks.library.stepTemplates.root, null, { permission: Permission.ActionTemplateView }),
            this.props.isMultiTenancyEnabled && Navigation.navItem("Tenant Tag Sets", routeLinks.library.tagSets.root, null, { permission: Permission.VariableView, wildcard: true }),
            Navigation.navItem("Variable Sets", routeLinks.library.variableSets, null, { permission: [Permission.VariableView, Permission.LibraryVariableSetView], wildcard: true }),
        ].filter(l => !!l);

        return (
            <main id="maincontent">
                <AreaTitle link={routeLinks.library.root} title="Library" />
                <NavigationSidebarLayout navLinks={navLinks} content={this.props.children} />
            </main>
        );
    }
}

const mapGlobalStateToProps = (state: GlobalState, props: {}): GlobalConnectedProps => {
    return {
        isMultiTenancyEnabled: state.configurationArea.currentSpace.isMultiTenancyEnabled,
    };
};

export const LibraryLayout = connect(mapGlobalStateToProps)(InternalLibraryLayout);

export default LibraryLayout;
