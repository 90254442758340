import * as React from "react";
import { repository } from "clientInstance";

import { connect } from "react-redux";
import { tenantsActions } from "../tenantsArea";
import { TenantResource, TenantMissingVariableResource, Permission } from "client/resources";
import NavigationSidebarLayout, { Navigation } from "components/NavigationSidebarLayout";
import MissingVariablesIcon from "../MissingVariablesIcon/MissingVariablesIcon";
import { RouteComponentProps } from "react-router";
import AreaTitle from "components/AreaTitle";
import BusyIndicator from "components/BusyIndicator/BusyIndicator";
import routeLinks from "../../../routeLinks";
import { DataBaseComponent, DataBaseComponentState } from "../../../components/DataBaseComponent/DataBaseComponent";
import BusyFromPromise from "../../../components/BusyFromPromise/BusyFromPromise";
import ErrorPanel from "../../../components/ErrorPanel/ErrorPanel";
import { withRouter } from "react-router";

interface TenantLayoutProps extends RouteComponentProps<{ tenantId: string }> {
    sectionControl?: React.ReactNode;
    busy?: Promise<any> | boolean;
}

interface TenantLayoutDispatchProps {
    onFetchTenant(tenant: TenantResource): void;
    onTenantVariablesFetched(tenantMissingVariables: TenantMissingVariableResource): void;
}
interface TenantLayoutStateProps {
    tenantName?: string;
    tenantDescription?: string;
    tenantLogoUrl?: string;
    tenantId?: string;
    hasMissingVariables?: boolean;
}

type TenantLayoutConnectedProps = TenantLayoutDispatchProps & TenantLayoutStateProps;
type TenantLayoutInternalProps = TenantLayoutProps & TenantLayoutConnectedProps;

class TenantLayoutInternal extends DataBaseComponent<TenantLayoutInternalProps, DataBaseComponentState> {
    private tenantId: string;

    constructor(props: TenantLayoutInternalProps) {
        super(props);
        this.tenantId = this.props.match.params.tenantId;
        this.state = {};
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            if (this.props.tenantId !== this.tenantId) {
                const tenant = await repository.Tenants.get(this.tenantId);
                this.props.onFetchTenant(tenant);

                const variables = await repository.Tenants.missingVariables({ tenantId: tenant.Id }, false);
                this.props.onTenantVariablesFetched(variables.find(t => t.TenantId === tenant.Id));
            }
        });
    }

    render() {
        const isUpdatedDataAvailable = this.props.tenantId === this.tenantId;
        if (!isUpdatedDataAvailable) {
            return (
                <main id="maincontent">
                    {this.areaTitle()}
                    {this.renderErrors()}
                </main>
            );
        }

        const variableLink = this.props.hasMissingVariables ? (
            <span>
                Variables <MissingVariablesIcon show={true} />
            </span>
        ) : (
            "Variables"
        );

        const tenantLinks = routeLinks.tenant(this.props.tenantId);
        const sidebarLinks = [
            Navigation.navItem("Overview", tenantLinks.overview),
            Navigation.navItem(variableLink as any, tenantLinks.variables().pathname),
            Navigation.navItem("Tasks", tenantLinks.tasks, null, {
                permission: Permission.TaskView,
                tenant: this.props.tenantId,
            }),
            Navigation.navItem("Settings", tenantLinks.settings),
        ];

        return (
            <main id="maincontent">
                {this.areaTitle()}
                {this.renderErrors()}
                <NavigationSidebarLayout logoUrl={this.props.tenantLogoUrl} name={this.props.tenantName} navLinks={sidebarLinks} content={this.props.children} description={this.props.tenantDescription} />
            </main>
        );
    }

    renderErrors() {
        const errors = this.state.errors;
        if (!errors) {
            return null;
        }
        return <ErrorPanel message={errors.message} details={errors.details} detailLinks={errors.detailLinks} helpText={errors.helpText} helpLink={errors.helpLink} />;
    }

    private renderBusy() {
        return <BusyFromPromise promise={this.props.busy}>{(busy: boolean) => <BusyIndicator show={busy} />}</BusyFromPromise>;
    }

    private areaTitle() {
        return <AreaTitle link={routeLinks.tenants} title="Tenants" busyIndicator={this.renderBusy()} />;
    }
}

const mapGlobalStateToProps = (state: GlobalState): TenantLayoutStateProps => {
    return !state.tenantsArea.currentTenant
        ? {}
        : {
              tenantName: state.tenantsArea.currentTenant.name,
              tenantDescription: state.tenantsArea.currentTenant.description,
              tenantLogoUrl: state.tenantsArea.currentTenant.logoUrl,
              tenantId: state.tenantsArea.currentTenant.id,
              hasMissingVariables: state.tenantsArea.currentTenant.hasMissingVariables,
          };
};

const mapGlobalActionDispatchersToProps = (dispatch: any) => {
    return {
        onFetchTenant: (tenant: TenantResource) => {
            dispatch(tenantsActions.tenantFetched(tenant));
        },
        onTenantVariablesFetched: (tenantMissingVariables: TenantMissingVariableResource) => {
            dispatch(tenantsActions.tenantMissingVariablesFetched(tenantMissingVariables));
        },
    };
};

const TenantLayout = withRouter(
    connect<TenantLayoutStateProps, TenantLayoutDispatchProps, TenantLayoutProps>(
        mapGlobalStateToProps,
        mapGlobalActionDispatchersToProps
    )(TenantLayoutInternal)
);

export default TenantLayout;
