import * as React from "react";
import FormBaseComponent, { OptionalFormBaseComponentState } from "components/FormBaseComponent/FormBaseComponent";
import { RouteComponentProps } from "react-router";
import {
    AADCredentialType,
    AccountResource,
    AzureCloudServiceEndpointDeploymentSlot,
    AzureCloudServiceEndpointResource,
    AzureServiceFabricClusterEndpointResource,
    AzureServiceFabricSecurityMode,
    AzureWebAppEndpointResource,
    CertificateConfigurationResource,
    CloudRegionEndpointResource,
    CommunicationStyle,
    EndpointResource,
    KubernetesEndpointResource,
    ListeningTentacleEndpointResource,
    MachineModelHealthStatus,
    MachinePolicyResource,
    MachineResource,
    OfflineDropEndpointResource,
    Permission,
    PollingTentacleEndpointResource,
    ProxyResource,
    SshEndpointResource,
    WorkerPoolResource,
    OfflineDropDestinationType,
} from "client/resources";
import { cloneDeep, find } from "lodash";
import FormPaperLayout from "components/FormPaperLayout";
import { BreadcrumbProps } from "components/PaperLayout/PaperLayout";

import { OverflowMenuItems } from "components/Menu";
import InternalRedirect from "components/Navigation/InternalRedirect";
import Callout, { CalloutType } from "components/Callout";
import BasicRepository from "client/repositories/basicRepository";
import { Checkbox, ExpandableFormSection, FormSectionHeading, Note, required, Select, Summary, Text, UnstructuredFormSection } from "components/form";
import MachinePolicySummary from "../MachinePolicySummary";
import EndpointsHelper, { EndpointCommunicationStyle } from "utils/EndpointsHelper/EndpointsHelper";
import TentacleActiveEndpoint from "../MachineSettings/Endpoints/TentacleActiveEndpoint";
import TentaclePassiveEndpoint from "../MachineSettings/Endpoints/TentaclePassiveEndpoint";
import SshEndpoint from "../MachineSettings/Endpoints/SshEndpoint";
import OfflineDropEndpoint from "../MachineSettings/Endpoints/OfflineDropEndpoint";
import AzureWebAppEndpoint from "../MachineSettings/Endpoints/AzureWebAppEndpoint";
import AzureCloudServiceEndpoint from "../MachineSettings/Endpoints/AzureCloudServiceEndpoint";
import AzureServiceFabricClusterEndpoint from "../MachineSettings/Endpoints/AzureServiceFabricClusterEndpoint";
import KubernetesEndpoint from "../MachineSettings/Endpoints/KubernetesEndpoint";
import DeprecatedEndpoint from "../MachineSettings/Endpoints/DeprecatedEndpoint";
import CloudRegionEndpoint from "../MachineSettings/Endpoints/CloudRegionEndpoint";
import { repository } from "clientInstance";
import URI from "urijs";
import routeLinks from "routeLinks";
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";

export interface MachineSettingsRouteParams {
    machineId: string;
}

interface ExtraProps<Resource extends MachineResource> {
    rootLink: string;
    repository: BasicRepository<Resource, Resource>;
    isMultiTenancyEnabled: boolean;
    onMachineSaved(machine: Resource): void;
}

export type BaseMachineSettingsProps<Resource extends MachineResource> = RouteComponentProps<MachineSettingsRouteParams> & ExtraProps<Resource> & { breadcrumbs: BreadcrumbProps };

export interface BaseMachineSettingsState<Resource extends MachineResource> extends OptionalFormBaseComponentState<Resource> {
    deleted: boolean;
    newId: string;
    machinePolicies: MachinePolicyResource[];
    machinePolicy: MachinePolicyResource;
    proxies: ProxyResource[];
    globalCertificate: CertificateConfigurationResource;
    accounts: AccountResource[];
    workerPools: WorkerPoolResource[];
    communicationStyles: EndpointCommunicationStyle[];
}

abstract class BaseMachineSettingsLayout<Props extends BaseMachineSettingsProps<Resource>, State extends BaseMachineSettingsState<Resource>, Resource extends MachineResource> extends FormBaseComponent<Props, State, Resource> {
    protected machineId: string;

    constructor(props: Props) {
        super(props);
        this.machineId = this.props.match && this.props.match.params ? this.props.match.params.machineId : null;

        this.state = this.initialState();
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            this.setState(await this.loadData(), async () => {
                // Only set our model when all our lookup data has loaded.
                const machine: Resource = this.machineId ? await this.props.repository.get(this.machineId) : this.configureNewMachine();

                this.setState({
                    model: machine,
                    cleanModel: cloneDeep(machine),
                });
                this.refreshMachinePolicy(machine.MachinePolicyId);
            });
        });
    }

    render() {
        const { breadcrumbs = {} } = this.props;

        const machineTypeFriendlyName = this.state.model && EndpointsHelper.getFriendlyName(this.state.model.Endpoint.CommunicationStyle);

        const title = this.machineId ? this.state.model && "Settings" : this.state.model && "Create " + machineTypeFriendlyName;

        const saveText: string = this.state.newId ? machineTypeFriendlyName + " created" : machineTypeFriendlyName + " details updated";

        const overFlowActions = [];
        if (this.machineId) {
            if (this.state.model && this.state.model.Id) {
                overFlowActions.push(OverflowMenuItems.item(this.state.model.IsDisabled ? "Enable" : "Disable", this.handleEnabledToggle, { permission: this.enableDisablePermission(), wildcard: true }));
            }

            overFlowActions.push(OverflowMenuItems.deleteItemDefault(machineTypeFriendlyName, this.handleDeleteConfirm, { permission: this.deletePermission(), wildcard: true }));

            overFlowActions.push([
                OverflowMenuItems.navItem("Audit Trail", routeLinks.configuration.eventsRegardingAny([this.machineId]), null, {
                    permission: Permission.EventView,
                    wildcard: true,
                }),
            ]);
        }

        return (
            <FormPaperLayout
                {...breadcrumbs}
                title={title}
                busy={this.state.busy}
                errors={this.state.errors}
                model={this.state.model}
                cleanModel={this.state.cleanModel}
                savePermission={{ permission: !this.machineId ? this.createPermission() : this.editPermission(), environment: "*", tenant: "*" }}
                onSaveClick={this.handleSaveClick}
                saveText={saveText}
                secondaryAction={this.machineId && this.renderSecondaryAction()}
                expandAllOnMount={!this.machineId}
                overFlowActions={overFlowActions}
            >
                {this.state.deleted && <InternalRedirect to={this.props.rootLink} />}
                {this.state.newId && <InternalRedirect to={this.machineLink(this.state.newId)} />}
                {this.state.model && (
                    <TransitionAnimation>
                        {this.state.cleanModel.IsDisabled && (
                            <UnstructuredFormSection stretchContent={true}>
                                <Callout type={CalloutType.Warning} title={<span> This {machineTypeFriendlyName} is currently disabled.</span>} />
                            </UnstructuredFormSection>
                        )}

                        <ExpandableFormSection
                            errorKey="Display name"
                            title="Display Name"
                            focusOnExpandAll
                            summary={this.state.model.Name ? Summary.summary(this.state.model.Name) : Summary.placeholder("Please enter a name for your " + machineTypeFriendlyName)}
                            help={"A short, memorable, unique name for this " + machineTypeFriendlyName + "."}
                        >
                            <Text
                                value={this.state.model.Name}
                                onChange={Name => this.setModelState({ Name })}
                                label="Display name"
                                validate={required("Please enter a " + machineTypeFriendlyName + " name")}
                                error={this.getFieldError("Display name")}
                                autoFocus={true}
                            />
                        </ExpandableFormSection>

                        {this.state.model.Id && (
                            <ExpandableFormSection
                                errorKey="IsDisabled"
                                title="Enabled"
                                summary={this.state.model.IsDisabled ? Summary.summary("No") : Summary.default("Yes")}
                                help={"Disable this " + machineTypeFriendlyName + " to prevent it from being included in any deployments."}
                            >
                                <Checkbox value={!this.state.model.IsDisabled} onChange={IsDisabled => this.setModelState({ IsDisabled: !IsDisabled })} label="Enabled" />
                            </ExpandableFormSection>
                        )}

                        {this.renderTypeSpecificComponents()}

                        {EndpointsHelper.hasMachinePolicy(this.state.model.Endpoint) && (
                            <ExpandableFormSection errorKey="Policy" title="Policy" summary={this.machinePolicySummary()} help={"Select the machine policy."}>
                                <Select
                                    label="Machine policy"
                                    onChange={x => {
                                        this.setModelState({ MachinePolicyId: x });
                                        this.refreshMachinePolicy(x);
                                    }}
                                    value={this.state.model.MachinePolicyId}
                                    items={this.state.machinePolicies.map(x => ({ value: x.Id, text: x.Name }))}
                                />
                                <MachinePolicySummary machinePolicy={this.state.machinePolicy} hideDescription={false} conciseView={true} />
                            </ExpandableFormSection>
                        )}

                        {/* Communication section only shown for cloud regions when there are more than one worker pool. icky. */}
                        {((this.state.model.Endpoint.CommunicationStyle === CommunicationStyle.None && this.state.workerPools.length > 1) || this.state.model.Endpoint.CommunicationStyle !== CommunicationStyle.None) && (
                            <div>
                                <FormSectionHeading title="Communication" />
                            </div>
                        )}

                        {this.renderEndpointSpecificComponent()}

                        {this.renderTenantComponent()}
                    </TransitionAnimation>
                )}
            </FormPaperLayout>
        );
    }

    protected abstract initialState(): State;
    protected abstract enableDisablePermission(): Permission;
    protected abstract createPermission(): Permission;
    protected abstract editPermission(): Permission;
    protected abstract deletePermission(): Permission;
    protected abstract machineLink(machineId: string): string;
    protected abstract async loadData(): Promise<Pick<BaseMachineSettingsState<any>, any>>;

    protected abstract renderTypeSpecificComponents(): JSX.Element;
    protected abstract renderSecondaryAction(): JSX.Element;
    protected renderTenantComponent(): JSX.Element {
        return null;
    }

    protected configureNewMachine(): Resource {
        // We'll be passed various information from the query string from machine discovery.
        const query = URI(this.props.location.search).search(true);
        const communicationStyleType = query.type as CommunicationStyle;
        const name = query.name;
        const host = query.host;
        const port = query.port;
        const uri = query.uri;
        const fingerprint = query.fingerprint;
        const thumbprint = query.thumbprint;
        const proxyId = query.proxyId;
        let endpoint: EndpointResource = {
            Id: null,
            CommunicationStyle: communicationStyleType,
            Name: name,
            Links: null,
        };
        switch (endpoint.CommunicationStyle) {
            case CommunicationStyle.TentaclePassive: {
                const tentacleEndpoint = endpoint as ListeningTentacleEndpointResource;
                tentacleEndpoint.Uri = uri;
                tentacleEndpoint.Thumbprint = thumbprint;
                tentacleEndpoint.ProxyId = proxyId;
                endpoint = tentacleEndpoint;
                break;
            }
            case CommunicationStyle.Ssh: {
                const sshEndpoint = endpoint as SshEndpointResource;
                sshEndpoint.Uri = uri;
                sshEndpoint.Host = host;
                sshEndpoint.Port = port ? port : 22;
                sshEndpoint.Fingerprint = fingerprint;
                sshEndpoint.ProxyId = proxyId;
                sshEndpoint.DotNetCorePlatform = SshEndpoint.dotnetCorePlatforms[0];
                endpoint = sshEndpoint;
                break;
            }
            case CommunicationStyle.AzureCloudService: {
                const csEndpoint = endpoint as AzureCloudServiceEndpointResource;
                csEndpoint.Slot = AzureCloudServiceEndpointDeploymentSlot.Staging;
                csEndpoint.SwapIfPossible = true;
                csEndpoint.UseCurrentInstanceCount = true;
                endpoint = csEndpoint;
                break;
            }
            case CommunicationStyle.AzureServiceFabricCluster: {
                const fabricEndpoint = endpoint as AzureServiceFabricClusterEndpointResource;
                fabricEndpoint.SecurityMode = AzureServiceFabricSecurityMode.Unsecure;
                fabricEndpoint.AadCredentialType = AADCredentialType.UserCredential;
                endpoint = fabricEndpoint;
                break;
            }
            case CommunicationStyle.Kubernetes: {
                const kubernetesEndpoint = endpoint as KubernetesEndpointResource;
                endpoint = kubernetesEndpoint;
                break;
            }
            case CommunicationStyle.OfflineDrop: {
                const offlineDropEndpoint = endpoint as OfflineDropEndpointResource;
                offlineDropEndpoint.Destination = {
                    DestinationType: OfflineDropDestinationType.Artifact,
                };
                break;
            }
            default: {
                break;
            }
        }

        const defaultMachinePolicy = this.state.machinePolicies.find(x => {
            return x.IsDefault;
        });

        const machine = {
            Id: null as string,
            MachinePolicyId: defaultMachinePolicy.Id,
            Name: name ? name : host,
            IsDisabled: false,
            HealthStatus: MachineModelHealthStatus.Unknown,
            HasLatestCalamari: true,
            StatusSummary: null as any,
            IsInProcess: true,
            Endpoint: endpoint,
            Links: null as any,
        };

        return machine as Resource;
    }

    private renderEndpointSpecificComponent() {
        switch (this.state.model.Endpoint.CommunicationStyle) {
            case CommunicationStyle.None:
                return <CloudRegionEndpoint endpoint={this.state.model.Endpoint as CloudRegionEndpointResource} workerPools={this.state.workerPools} onChange={Endpoint => this.setModelState({ Endpoint })} />;
            case CommunicationStyle.TentacleActive:
                return (
                    <TentacleActiveEndpoint
                        endpoint={this.state.model.Endpoint as PollingTentacleEndpointResource}
                        serverThumbprint={this.state.globalCertificate && this.state.globalCertificate.Thumbprint}
                        onChange={Endpoint => this.setModelState({ Endpoint })}
                    />
                );
            case CommunicationStyle.TentaclePassive:
                return (
                    <TentaclePassiveEndpoint
                        endpoint={this.state.model.Endpoint as ListeningTentacleEndpointResource}
                        serverThumbprint={this.state.globalCertificate && this.state.globalCertificate.Thumbprint}
                        proxies={this.state.proxies}
                        onChange={Endpoint => this.setModelState({ Endpoint })}
                    />
                );
            case CommunicationStyle.Ssh:
                return (
                    <SshEndpoint endpoint={this.state.model.Endpoint as SshEndpointResource} proxies={this.state.proxies} refreshAccounts={this.refreshAccounts} accounts={this.state.accounts} onChange={Endpoint => this.setModelState({ Endpoint })} />
                );
            case CommunicationStyle.OfflineDrop:
                return <OfflineDropEndpoint endpoint={this.state.model.Endpoint as OfflineDropEndpointResource} onChange={Endpoint => this.setModelState({ Endpoint })} />;
            case CommunicationStyle.AzureWebApp:
                return (
                    <AzureWebAppEndpoint
                        doBusyTask={this.doBusyTask}
                        busy={this.state.busy}
                        endpoint={this.state.model.Endpoint as AzureWebAppEndpointResource}
                        refreshAccounts={this.refreshAccounts}
                        accounts={this.state.accounts}
                        workerPools={this.state.workerPools}
                        onChange={Endpoint => this.setModelState({ Endpoint })}
                        getFieldError={this.getFieldError}
                    />
                );
            case CommunicationStyle.AzureCloudService:
                return (
                    <AzureCloudServiceEndpoint
                        doBusyTask={this.doBusyTask}
                        busy={this.state.busy}
                        endpoint={this.state.model.Endpoint as AzureCloudServiceEndpointResource}
                        refreshAccounts={this.refreshAccounts}
                        accounts={this.state.accounts}
                        workerPools={this.state.workerPools}
                        onChange={Endpoint => this.setModelState({ Endpoint })}
                        getFieldError={this.getFieldError}
                    />
                );
            case CommunicationStyle.AzureServiceFabricCluster:
                return (
                    <AzureServiceFabricClusterEndpoint
                        doBusyTask={this.doBusyTask}
                        busy={this.state.busy}
                        endpoint={this.state.model.Endpoint as AzureServiceFabricClusterEndpointResource}
                        workerPools={this.state.workerPools}
                        onChange={Endpoint => this.setModelState({ Endpoint })}
                        getFieldError={this.getFieldError}
                    />
                );
            case CommunicationStyle.Kubernetes:
                return (
                    <KubernetesEndpoint
                        doBusyTask={this.doBusyTask}
                        busy={this.state.busy}
                        endpoint={this.state.model.Endpoint as KubernetesEndpointResource}
                        refreshAccounts={this.refreshAccounts}
                        accounts={this.state.accounts}
                        workerPools={this.state.workerPools}
                        onChange={Endpoint => this.setModelState({ Endpoint })}
                        getFieldError={this.getFieldError}
                        proxies={this.state.proxies}
                    />
                );
            default: {
                return <DeprecatedEndpoint />;
            }
        }
    }

    private refreshAccounts = () => {
        return this.doBusyTask(async () => {
            this.setState({ accounts: await repository.Accounts.all() });
        });
    };

    private refreshMachinePolicy(machinePolicyId: string) {
        const machinePolicy = find(this.state.machinePolicies, x => {
            return x.Id === machinePolicyId;
        });
        this.setState({ machinePolicy });
    }

    private machinePolicySummary() {
        const machinePolicy = this.state.machinePolicies.find(x => x.Id === this.state.model.MachinePolicyId);
        if (machinePolicy) {
            return Summary.summary(machinePolicy.Name);
        }
        return Summary.placeholder("Unknown machine policy");
    }

    private communicationStyleSummary() {
        const targetType = this.state.communicationStyles.find(cs => cs.value === this.state.model.Endpoint.CommunicationStyle);
        if (targetType) {
            return Summary.summary(targetType.label);
        }
        return Summary.placeholder("Unknown type");
    }

    private handleSaveClick = async () => {
        await this.doBusyTask(async () => {
            const isNew = this.state.model.Id == null;
            const result = await this.props.repository.save(this.state.model);
            this.props.onMachineSaved(result);
            this.setState({
                model: result,
                cleanModel: cloneDeep(result),
                newId: isNew ? result.Id : null,
            });
        });
    };

    private handleDeleteConfirm = async () => {
        const result = await this.props.repository.del(this.state.model);
        this.setState(state => {
            return {
                model: null,
                cleanModel: null, //reset model so that dirty state doesn't prevent navigation
                deleted: true,
            };
        });
        return true;
    };

    private handleEnabledToggle = async () => {
        const isDisabled = !this.state.model.IsDisabled; // Toggle the current value.
        const model = this.state.model;
        model.IsDisabled = isDisabled;
        await this.doBusyTask(async () => {
            const isNew = this.state.model.Id == null;
            const result = await this.props.repository.save(this.state.model);
            this.props.onMachineSaved(result);
            this.setState({
                model: result,
                cleanModel: cloneDeep(result),
                newId: isNew ? result.Id : null,
            });
        });
    };
}

export default BaseMachineSettingsLayout;
