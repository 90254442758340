import { isDeploymentProcessResource, isRunbookProcessResource, IProcessResource } from "./deploymentProcessResource";

export enum ProcessType {
    Deployment = "Deployment",
    Runbook = "Runbook",
}

export const getProcessType = (resource: IProcessResource) => {
    if (isDeploymentProcessResource(resource)) {
        return ProcessType.Deployment;
    } else if (isRunbookProcessResource(resource)) {
        return ProcessType.Runbook;
    }
};
