import * as React from "react";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import {
    AzureCloudServiceEndpointResource,
    AzureWebAppEndpointResource,
    CommunicationStyle,
    DeploymentTargetResource,
    EndpointResource,
    EnvironmentResource,
    isDeploymentTarget,
    isWorkerMachine,
    KubernetesEndpointResource,
    ListeningTentacleEndpointResource,
    MachineResource,
    OfflineDropEndpointResource,
    PollingTentacleEndpointResource,
    SshEndpointResource,
    TenantResource,
    WorkerMachineResource,
    WorkerPoolResource,
    AzureServiceFabricClusterEndpointResource,
} from "client/resources";
import { TagIndex } from "components/tenantTagsets";
import { EnvironmentChip, environmentChipList, RoleChip, TenantChip, tenantChipList, WorkerPoolChip, workerPoolChipList } from "components/Chips/index";
import Chip from "components/Chips/Chip";
import { primaryText, secondaryBackground } from "theme/colors";
import Tag from "components/Tag";
import MachineIconHelper from "utils/MachineIconHelper";
import { ActionButton, ActionButtonType } from "components/Button";
import { OfflineDropDestinationType } from "../../../../client/resources/offlineDropDestinationResource";
import { WorkerPoolType } from "client/resources/workerPoolsSupportedTypesResouce";
import Update from "material-ui/svg-icons/action/update";
const styles = require("./style.less");

interface MachineRowProps {
    machine: MachineResource;
    tenants?: TenantResource[];
    tagIndex?: TagIndex;
    environments?: EnvironmentResource[];
    workerPools?: WorkerPoolResource[];
    needsUpgrading?: boolean;
}

interface MachineRowState {
    showAllChips: boolean;
}

const noEndpointSummary = (): React.ReactNode => null;

const endpointSummaries: { [index in CommunicationStyle]: (resource: EndpointResource) => React.ReactNode } = {
    TentaclePassive: (endpoint: ListeningTentacleEndpointResource) => endpoint.Uri,
    TentacleActive: (endpoint: PollingTentacleEndpointResource) => endpoint.Uri,
    AzureWebApp: (endpoint: AzureWebAppEndpointResource) => {
        const slotName = endpoint.WebAppSlotName ? "/" + endpoint.WebAppSlotName : "";
        return endpoint.WebAppName + slotName;
    },
    None: noEndpointSummary,
    AzureCloudService: (endpoint: AzureCloudServiceEndpointResource) => endpoint.CloudServiceName,
    OfflineDrop: (endpoint: OfflineDropEndpointResource) => {
        return endpoint.Destination.DestinationType === OfflineDropDestinationType.FileSystem ? endpoint.Destination.DropFolderPath : "Octopus Artifact";
    },
    Ssh: (endpoint: SshEndpointResource) => endpoint.Uri,
    Kubernetes: (endpoint: KubernetesEndpointResource) => endpoint.ClusterUrl,
    AzureServiceFabricCluster: noEndpointSummary,
    Ftp: noEndpointSummary, //old unsupported?
};

class MachineRow extends BaseComponent<MachineRowProps, MachineRowState> {
    private machineChipsDisplayThreshold = 3; //Show 3 chips max, then "show all" link.

    constructor(props: MachineRowProps) {
        super(props);
        this.state = {
            showAllChips: false,
        };
    }

    render() {
        const machine = this.props.machine;
        const machineIcon = MachineIconHelper.machineIcon(machine);
        return (
            <div className={styles.machineRow} key={`${machine.Id}-${this.state.showAllChips.toString()}`}>
                <div className={styles.machineImage}>
                    <img src={machineIcon} className={styles.machineIcon} alt="Machine" />
                </div>
                <div className={styles.machineNameContainer}>
                    <div className={styles.machineName}>{machine.Name}</div>
                    <div className={styles.machineSummary}>{this.getSummaryForEndpoint(machine.Endpoint)}</div>
                </div>
                <div className={styles.chipContainer}>{this.renderMachineChips(machine)}</div>
                {this.props.needsUpgrading ? (
                    <div className={styles.upgradeAvailable}>
                        <span>Upgrade available</span>
                        <Update />
                    </div>
                ) : (
                    ""
                )}
            </div>
        );
    }

    private renderMachineChips(machine: MachineResource) {
        if (isWorkerMachine(machine)) {
            return this.renderWorkerChips(machine);
        } else if (isDeploymentTarget(machine)) {
            return this.renderDeploymentTargetResourceChips(machine);
        }
    }

    private renderWorkerChips(worker: WorkerMachineResource) {
        const chipThreshold = this.machineChipsDisplayThreshold;
        const includeShowHideControl = this.props.workerPools && worker.WorkerPoolIds.length >= chipThreshold;
        const chipsToDisplay = [];

        if (this.props.workerPools) {
            let environmentsChipsList: any = null;
            if (worker.WorkerPoolIds.length >= chipThreshold && !this.state.showAllChips) {
                environmentsChipsList = <WorkerPoolChip workerPoolType={WorkerPoolType.Static} workerPoolName={`${worker.WorkerPoolIds.length.toLocaleString()} workerPools`} key="workerPoolsChipsList" />;
            } else {
                environmentsChipsList = this.renderWorkerPoolsList(worker.WorkerPoolIds);
            }
            chipsToDisplay.push(environmentsChipsList);
        }

        // Make this appear more like a ternary link, with the additional click-area of a secondary button to improve usability.
        const labelProps = {
            fontWeight: "inherit",
            fontSize: "0.8125rem",
        };
        if (includeShowHideControl) {
            return (
                <div className={styles.chipContainer}>
                    {chipsToDisplay}
                    {/*This ActionButton can't be a Ternary type, or you get a nested-link warning because Ternary = anchor tag, and all list rows are already anchors.*/}
                    <ActionButton type={ActionButtonType.Ternary} label={this.state.showAllChips ? "Show summary" : "Show all"} labelProps={labelProps} onClick={(e: any) => this.setState({ showAllChips: !this.state.showAllChips })} />
                </div>
            );
        }
        return chipsToDisplay;
    }

    private renderDeploymentTargetResourceChips(deploymentTarget: DeploymentTargetResource) {
        const chipThreshold = this.machineChipsDisplayThreshold;
        const tags = deploymentTarget.TenantTags.map(name => this.props.tagIndex[name]);
        const tagCount = tags.length;
        const includeShowHideControl =
            (this.props.environments && deploymentTarget.EnvironmentIds.length >= chipThreshold) || deploymentTarget.Roles.length >= chipThreshold || deploymentTarget.TenantIds.length >= chipThreshold || tagCount >= chipThreshold;
        const chipsToDisplay = [];

        if (this.props.environments) {
            let environmentsChipsList: any = null;
            if (deploymentTarget.EnvironmentIds.length >= chipThreshold && !this.state.showAllChips) {
                environmentsChipsList = <EnvironmentChip environmentName={`${deploymentTarget.EnvironmentIds.length.toLocaleString()} environments`} key="environmentsChipsList" />;
            } else {
                environmentsChipsList = this.renderEnvironmentsList(deploymentTarget.EnvironmentIds);
            }
            chipsToDisplay.push(environmentsChipsList);
        }

        let roleChipsList: any = null;
        // tslint:disable-next-line:prefer-conditional-expression
        if (deploymentTarget.Roles.length >= chipThreshold && !this.state.showAllChips) {
            roleChipsList = <RoleChip role={`${deploymentTarget.Roles.length.toLocaleString()} roles`} key="roleChipsList" />;
        } else {
            roleChipsList = this.renderRolesList(deploymentTarget.Roles);
        }
        chipsToDisplay.push(roleChipsList);

        let tenantChipsList: any = null;
        if (deploymentTarget.TenantIds.length >= chipThreshold && !this.state.showAllChips) {
            tenantChipsList = <TenantChip tenantName={`${deploymentTarget.TenantIds.length.toLocaleString()} tenants`} key="tenantChipsList" />;
        } else {
            tenantChipsList = this.renderTenantsList(deploymentTarget.TenantIds);
        }
        chipsToDisplay.push(tenantChipsList);

        let tenantTagChipsList: any = null;
        if (tagCount >= chipThreshold && !this.state.showAllChips) {
            const tagsList = deploymentTarget.TenantTags.map(tt => {
                const tagResource = this.props.tagIndex[tt];
                return <Tag key={tt} tagName={tagResource.Name} description={tagResource.Description} tagColor={tagResource.Color} small={true} />;
            });
            tenantTagChipsList = (
                <Chip labelColor={primaryText} backgroundColor={secondaryBackground} key="tenantTagChipsList">
                    {`${tagCount.toLocaleString()} tags`} {tagsList}
                </Chip>
            );
        } else {
            tenantTagChipsList = this.renderTenantTagsList(deploymentTarget.TenantTags);
        }
        chipsToDisplay.push(tenantTagChipsList);

        // Make this appear more like a ternary link, with the additional click-area of a secondary button to improve usability.
        const labelProps = {
            fontWeight: "inherit",
            fontSize: "0.8125rem",
        };
        if (includeShowHideControl) {
            return (
                <div className={styles.chipContainer}>
                    {chipsToDisplay}
                    {/*This ActionButton can't be a Ternary type, or you get a nested-link warning because Ternary = anchor tag, and all list rows are already anchors.*/}
                    <ActionButton type={ActionButtonType.Ternary} label={this.state.showAllChips ? "Show summary" : "Show all"} labelProps={labelProps} onClick={(e: any) => this.setState({ showAllChips: !this.state.showAllChips })} />
                </div>
            );
        }
        return chipsToDisplay;
    }

    private renderEnvironmentsList(environmentIds: string[]) {
        return environmentChipList(this.props.environments, environmentIds);
    }

    private renderWorkerPoolsList(workerPoolIds: string[]) {
        return workerPoolChipList(this.props.workerPools, workerPoolIds);
    }

    private renderRolesList(roles: string[], maxRolesToShow?: number) {
        if (maxRolesToShow) {
            return roles.slice(0, maxRolesToShow).map(r => <RoleChip role={r} key={"role-" + r} />);
        }
        return roles.map(r => <RoleChip role={r} key={"role-" + r} />);
    }

    private renderTenantsList(tenantIds: string[]) {
        return tenantChipList(this.props.tenants, tenantIds);
    }

    private renderTenantTagsList(tenantTags: string[]) {
        const tags = tenantTags.map(name => this.props.tagIndex[name]);
        return tags.map(tag => <Tag tagName={tag.Name} description={tag.Description} tagColor={tag.Color} key={tag.Name} />);
    }

    private getSummaryForEndpoint(endpoint: EndpointResource) {
        const summary = endpointSummaries[endpoint.CommunicationStyle];
        if (summary) {
            return summary(endpoint);
        }
        return null;
    }
}

export default MachineRow;
