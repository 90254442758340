// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--7-1!../../../node_modules/codemirror/lib/codemirror.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--7-1!../../../node_modules/codemirror/addon/display/fullscreen.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
exports.push([module.id, ".CodeMirror\r\n{\r\n    height: inherit;\r\n    font-family: Consolas, Menlo, Monaco, \"Courier New\", monospace;\r\n    font-size: 1rem;\r\n}\r\n\r\n.CodeMirror-fullscreen{\r\n    top: 1.875rem;\r\n}\r\n\r\n.ReactCodeMirror\r\n{\r\n    height: 100%;\r\n    top:0;\r\n    bottom:0;\r\n    left:0;\r\n    right: 0;\r\n    position: absolute;\r\n    font-size: 1rem;\r\n}\r\n\r\n.readonly .CodeMirror-cursors {\r\n    visibility: hidden !important;\r\n}\r\n", ""]);
// Exports
module.exports = exports;
