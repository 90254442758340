import * as React from "react";
import { useRequiredContext } from "hooks/useRequiredContext";
import { useDoBusyTaskEffect, DoBusyTask } from "components/DataBaseComponent";
import { repository } from "clientInstance";
import { RunbookResource, RunbookSnapshotResource } from "client/resources";

export type RunbookContextProps = ReturnType<typeof useRunbookSetup>;

const RunbookContext = React.createContext<RunbookContextProps>(null);

interface RunbookContextState {
    runbook: RunbookResource;
}

interface RunbookContextProviderProps {
    id: string;
    doBusyTask: DoBusyTask;
    children: (props: RunbookContextProps) => React.ReactNode;
}

const getStateUpdaters = (setState: React.Dispatch<React.SetStateAction<RunbookContextState>>) => {
    return {
        onRunbookUpdated: (runbook: RunbookResource) => {
            setState(current => ({ ...current, runbook }));
        },
    };
};

const useRunbookSetup = (id: string, doBusyTask: DoBusyTask) => {
    const [state, setState] = React.useState<RunbookContextState>({
        runbook: null,
    });

    const updaters = React.useMemo(() => getStateUpdaters(setState), [setState]);

    const refreshRunbook = useDoBusyTaskEffect(
        doBusyTask,
        async () => {
            const runbook = await repository.Runbooks.get(id);
            updaters.onRunbookUpdated(runbook);
        },
        [id]
    );

    const publishSnapshot = React.useCallback(
        (snapshot: RunbookSnapshotResource) => {
            if (!snapshot) {
                return;
            }

            return doBusyTask(async () => {
                const result = await repository.Runbooks.get(id);
                result.PublishedRunbookSnapshotId = snapshot.Id;
                updaters.onRunbookUpdated(await repository.Runbooks.modify(result));
            });
        },
        [id]
    );

    const supportedActions = {
        refreshRunbook,
        publishSnapshot,
        ...updaters,
    };

    return {
        actions: supportedActions,
        state,
        setState,
    };
};

export const RunbookContextProvider: React.FC<RunbookContextProviderProps> = ({ children, id, doBusyTask }) => {
    const value = useRunbookSetup(id, doBusyTask);
    return <RunbookContext.Provider value={value}>{children(value)}</RunbookContext.Provider>;
};

export interface WithRunbookContextInjectedProps {
    runbookContext: RunbookContextProps;
}

export const withRunbookContext = <T extends unknown>(Component: React.ComponentType<T & WithRunbookContextInjectedProps>) => {
    const WithRunbookContext: React.FC<T> = props => {
        const context = useRunbookContext();
        return <Component runbookContext={context} {...props} />;
    };
    return WithRunbookContext;
};

export const useRunbookContext = () => {
    return useRequiredContext(RunbookContext, "Runbook");
};

export const useOptionalRunbookContext = () => {
    return React.useContext(RunbookContext);
};
