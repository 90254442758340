import { ChannelResource, TriggerResource, TriggerActionType, RunbookResource } from "client/resources";

export function trigger(root: string) {
    return (triggerId: string | TriggerResource, triggerType: TriggerActionType = TriggerActionType.AutoDeploy) => {
        if (!!triggerId && typeof triggerId !== "string") {
            triggerId = triggerId.Id;
        }
        if (triggerType === TriggerActionType.AutoDeploy) {
            return `${root}/edit/${triggerId}`;
        }
        return `${root}/scheduled/edit/${triggerId}`;
    };
}

export function channel(root: string) {
    return (channelId: string | ChannelResource) => {
        if (!!channelId && typeof channelId !== "string") {
            channelId = channelId.Id;
        }
        return `${root}/edit/${channelId}`;
    };
}

export function scheduledTrigger(root: string) {
    return (triggerId: string | TriggerResource) => {
        if (!!triggerId && typeof triggerId !== "string") {
            triggerId = triggerId.Id;
        }
        return `${root}/scheduled/edit/${triggerId}`;
    };
}

export function steps(root: string) {
    return {
        root,
        stepTemplates: `${root}/steptemplates`,
        childStepTemplates: (parentStepId: string) => ({
            root: `${root}/childsteptemplates/${parentStepId}`,
        }),
        new: (templateType: string, parentStepId?: string, templateId?: string) => {
            let base = `${root}/step`;
            if (parentStepId) {
                base = `${base}/${parentStepId}`;
            }
            base = `${base}/new/${templateType}`;
            if (templateId) {
                base = `${base}/${templateId}`;
            }
            return base;
        },
        step: (actionId: string) => `${root}/step/${actionId}`,
    };
}

export function runbook(root: string) {
    return (runbookId: string | RunbookResource) => {
        if (!!runbookId && typeof runbookId !== "string") {
            runbookId = runbookId.Id;
        }
        return `${root}/runbooks/${runbookId}`;
    };
}
