import * as React from "react";
import { Card, Collapse } from "@material-ui/core";
import { CardTitle } from "components/form/Sections";
const styles = require("./style.less");
import Expandable, { ExpandableProps } from "components/Expandable/Expandable";

interface SimpleExpanderProps {
    error?: string;
    title: JSX.Element;
    onDidExpand?: (expanded: boolean) => void;
}

type Props = SimpleExpanderProps & ExpandableProps;

export class SimpleExpanderInternal extends React.Component<Props> {
    componentWillReceiveProps(nextProps: Props) {
        if (this.props.isExpanded !== nextProps.isExpanded) {
            if (nextProps.onDidExpand) {
                nextProps.onDidExpand(nextProps.isExpanded);
            }
        }
    }

    // we can't just use actAsExpander on card Title because it eats the click
    // and we can't add links to the title in help text or our show more info link
    handleExpand = (e: any) => {
        e.preventDefault();
        const isExpanded = !this.props.isExpanded;
        this.props.onExpandedChanged(isExpanded);
    };

    renderChild = (child: any): any => {
        if (!child) {
            return child;
        }
        const children = child.props && child.props.children ? { children: this.renderChildren(child.props.children) } : null;

        const props = children ? { ...child.props, ...children } : child.props;
        return React.isValidElement(child) ? React.cloneElement(child, props) : child;
    };

    renderChildren = (children: any) => {
        return React.Children.map(children, this.renderChild);
    };

    render() {
        return (
            <Card className={styles.formExpander}>
                <CardTitle title={this.props.title} onClick={(e: any) => this.handleExpand(e)} showExpandableButton={true} isExpanded={this.props.isExpanded} />
                <Collapse in={this.props.isExpanded} timeout="auto" unmountOnExit={true}>
                    <div className={styles.cardMedia}>{this.renderChildren(this.props.children)}</div>
                </Collapse>
            </Card>
        );
    }
}

const SimpleExpander = Expandable(SimpleExpanderInternal);
export default SimpleExpander;
