import * as React from "react";
import { ActionButton } from "components/Button";
import { ActionButtonType } from "components/Button/ActionButton";
import { isAllowed, PermissionCheckProps } from "../PermissionCheck/PermissionCheck";
import Dialog from "components/Dialog/Dialog";
const styles = require("./style.less");
import DialogTrigger from "./DialogTrigger";

interface RenderDialogProps {
    open: boolean;
    sequence: string;
    closeDialog(): void;
    openDialog(): void;
}
interface OpenDialogButtonProps {
    label: any;
    title?: string;
    disabled?: boolean | Promise<any>;
    permission?: PermissionCheckProps;
    icon?: JSX.Element;
    type?: ActionButtonType;
    wideDialog?: boolean;
    renderDialog?: (props: RenderDialogProps) => React.ReactElement<any>;
}

class OpenDialogButton extends React.Component<OpenDialogButtonProps, never> {
    constructor(props: OpenDialogButtonProps) {
        super(props);
    }

    render() {
        const disabledDueToPermission = !!this.props.permission ? !isAllowed(this.props.permission) : false;
        const permissionLabel = this.getPermissionLabel();
        const label = disabledDueToPermission ? `${permissionLabel} permission required` : this.props.label;

        return (
            <DialogTrigger
                render={({ open, openDialog, sequence, closeDialog }) => (
                    <div className={styles.buttonDialogContainer}>
                        <ActionButton type={this.props.type} icon={this.props.icon} label={label} disabled={disabledDueToPermission || this.props.disabled} onClick={openDialog} title={this.props.title || this.props.label} />
                        {this.props.renderDialog ? (
                            this.props.renderDialog({ open, openDialog, sequence, closeDialog })
                        ) : (
                            <Dialog open={open} wide={this.props.wideDialog}>
                                {this.props.children}
                            </Dialog>
                        )}
                    </div>
                )}
            />
        );
    }

    private getPermissionLabel(): string {
        if (this.props.permission === undefined) {
            return "No";
        }
        if (Array.isArray(this.props.permission.permission)) {
            return this.props.permission.permission.join(", ");
        }
        return this.props.permission.permission;
    }
}

export default OpenDialogButton;
