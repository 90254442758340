import * as React from "react";
import { repository } from "clientInstance";
import { LibraryLayout } from "../LibraryLayout/LibraryLayout";
import { OctopusPackageVersionBuildInformationMappedResource } from "client/resources";
import PaperLayout from "components/PaperLayout";
import { OverflowMenu, OverflowMenuItems } from "components/Menu";
import InternalRedirect from "components/Navigation/InternalRedirect";
import ActionList from "components/ActionList/ActionList";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import { RouteComponentProps } from "react-router";
import routeLinks from "../../../../routeLinks";
import { Permission } from "client/resources";
import BuildInformationTable from "components/BuildInformation/BuildInformationTable";

interface BuildInformationDetailState extends DataBaseComponentState {
    buildInformation?: OctopusPackageVersionBuildInformationMappedResource;
    deleted?: boolean;
}

export default class BuildInformationDetail extends DataBaseComponent<RouteComponentProps<{ id: string }>, BuildInformationDetailState> {
    private id: string;

    constructor(props: RouteComponentProps<{ id: string }>) {
        super(props);
        this.id = this.props.match.params.id;
        this.state = {};
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            await this.load();
        });
    }

    async load() {
        const buildInformation = await repository.BuildInformationRepository.get(this.id);
        let errors = null;
        if (buildInformation.IncompleteDataWarning) {
            errors = { message: `Unable to retrieve part of the build information for package '${buildInformation.PackageId}'.`, details: [buildInformation.IncompleteDataWarning], fieldErrors: {} };
        }
        this.setState({ buildInformation, errors });
    }

    render() {
        const buildInformation = this.state.buildInformation;

        const title = this.state.buildInformation && buildInformation.PackageId + " " + buildInformation.Version;

        const actions = [
            <OverflowMenu
                menuItems={[OverflowMenuItems.deleteItemDefault(title, this.handleDeleteConfirm, { permission: Permission.BuildInformationAdminister, project: "*" }, "Deleting build information means it will be unavailable for future releases")]}
            />,
        ];

        return (
            <LibraryLayout {...this.props}>
                <PaperLayout
                    title={title}
                    breadcrumbTitle={buildInformation && buildInformation.PackageId}
                    breadcrumbPath={routeLinks.library.buildInformationRepository.versions(buildInformation && buildInformation.PackageId)}
                    sectionControl={<ActionList actions={actions} />}
                    busy={this.state.busy}
                    errors={this.state.errors}
                >
                    {this.state.deleted && <InternalRedirect to={routeLinks.library.buildInformationRepository.root} />}
                    {buildInformation && <BuildInformationTable data={buildInformation} />}
                </PaperLayout>
            </LibraryLayout>
        );
    }

    private handleDeleteConfirm = async () => {
        await repository.BuildInformationRepository.deleteMany([this.id]);
        this.setState({ deleted: true });
        return true;
    };
}
