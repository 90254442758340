import NamedResource from "./namedResource";

export enum WorkerPoolType {
    Static = "StaticWorkerPool",
    Dynamic = "DynamicWorkerPool",
}

export interface WorkerPoolsSupportedTypes {
    SupportedPoolTypes: WorkerPoolType[];
}

export interface DynamicWorkerType {
    Id: string;
    Description: string;
}
