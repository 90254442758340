import * as React from "react";
import { CommitDetail } from "client/resources";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import ExternalLink from "components/Navigation/ExternalLink";

interface CommitDetailsProps {
    data: CommitDetail[];
}

export default class CommitDetails extends DataBaseComponent<CommitDetailsProps, DataBaseComponentState> {
    constructor(props: CommitDetailsProps) {
        super(props);
        this.state = {};
    }

    resolveLink = (commitDetail: CommitDetail): JSX.Element => {
        const shortCommitSha = commitDetail.Id.substring(0, 7);
        if (commitDetail.LinkUrl) {
            return (
                <div key={commitDetail.Id}>
                    <ExternalLink href={commitDetail.LinkUrl}>{shortCommitSha}</ExternalLink> - {commitDetail.Comment}
                </div>
            );
        } else {
            return (
                <div key={commitDetail.Id}>
                    {shortCommitSha} - {commitDetail.Comment}
                </div>
            );
        }
    };

    render() {
        return this.props.data.map(commitDetail => this.resolveLink(commitDetail));
    }
}
