import { TriggerFilterResource, TriggerActionResource, TriggerFilterType, TriggerActionType } from "./triggerResource";

export abstract class TriggerScheduleResource extends TriggerFilterResource {
    Timezone: string;
}

export enum TriggerScheduleIntervalType {
    OnceDaily = "OnceDaily",
    OnceHourly = "OnceHourly",
    OnceEveryMinute = "OnceEveryMinute",
}

export class TriggerScheduleIntervalResource {
    Interval: TriggerScheduleIntervalType = TriggerScheduleIntervalType.OnceDaily;
    HourInterval?: number;
    MinuteInterval?: number;
}

export enum MonthlyScheduleType {
    DateOfMonth = "DateOfMonth",
    DayOfMonth = "DayOfMonth",
}

export enum DayOfWeek {
    Monday = "Monday",
    Tuesday = "Tuesday",
    Wednesday = "Wednesday",
    Thursday = "Thursday",
    Friday = "Friday",
    Saturday = "Saturday",
    Sunday = "Sunday",
}

export class OnceDailyTriggerScheduleResource extends TriggerScheduleResource {
    StartTime: Date;

    DaysOfWeek: DayOfWeek[];

    constructor() {
        super();
        this.FilterType = TriggerFilterType.OnceDailySchedule;
    }
}

export class ContinuousDailyTriggerScheduleResource extends TriggerScheduleResource {
    RunAfter: Date;
    RunUntil: Date;

    Interval: TriggerScheduleIntervalType = TriggerScheduleIntervalType.OnceHourly;

    HourInterval?: number;
    MinuteInterval?: number;

    DaysOfWeek: DayOfWeek[];

    constructor() {
        super();
        this.FilterType = TriggerFilterType.ContinuousDailySchedule;
    }
}

export class DaysPerMonthTriggerScheduleResource extends TriggerScheduleResource {
    StartTime: Date;
    MonthlyScheduleType: MonthlyScheduleType;
    DateOfMonth?: string;
    DayNumberOfMonth?: string;
    DayOfWeek: DayOfWeek;

    constructor() {
        super();
        this.FilterType = TriggerFilterType.DaysPerMonthSchedule;
    }
}

export class CronTriggerScheduleResource extends TriggerScheduleResource {
    CronExpression: string;

    constructor() {
        super();
        this.FilterType = TriggerFilterType.CronExpressionSchedule;
    }
}

export abstract class ScopedDeploymentActionResource extends TriggerActionResource {
    ChannelId: string;
    TenantIds: string[] = [];
    TenantTags: string[] = [];
    Variables: string;
}

export class DeployLatestReleaseActionResource extends ScopedDeploymentActionResource {
    SourceEnvironmentIds: string[];
    DestinationEnvironmentId: string;
    ShouldRedeployWhenReleaseIsCurrent: boolean;

    constructor() {
        super();
        this.ActionType = TriggerActionType.DeployLatestRelease;
        this.ShouldRedeployWhenReleaseIsCurrent = true;
        this.SourceEnvironmentIds = [];
    }
}

export class DeployNewReleaseActionResource extends ScopedDeploymentActionResource {
    EnvironmentId: string;

    constructor() {
        super();
        this.ActionType = TriggerActionType.DeployNewRelease;
    }
}

export class ScheduleIntervalResource {
    IntervalType: ScheduleIntervalType;
    IntervalValue: number;
}

export class RunRunbookActionResource extends TriggerActionResource {
    EnvironmentIds: string[];
    RunbookId: string;
    TenantIds: string[] = [];
    TenantTags: string[] = [];
    constructor() {
        super();
        this.ActionType = TriggerActionType.RunRunbook;
    }
}

export enum ScheduleIntervalType {
    Once = "Once",
    Hour = "Hour",
    Minute = "Minute",
}
