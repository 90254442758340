import * as React from "react";
import Onboarding from "./Onboarding";
import { IProcessResource, TenantedDeploymentMode, WorkerPoolResource, TagSetResource, isDeploymentProcessResource, isRunbookProcessResource, processPermission } from "client/resources";
import { client, repository } from "clientInstance";
import PaperLayout from "components/PaperLayout";
import SpecialVariables from "client/specialVariables";
import DeploymentPart from "./DeploymentPart";
import { DeploymentActionResource } from "client/resources/deploymentActionResource";
import Roles from "components/Actions/Roles";
import pluginRegistry, { ActionScope } from "components/Actions/pluginRegistry";
import { NavigationButton, NavigationButtonType } from "components/Button/NavigationButton";
import SideBar, { ScriptModule } from "./SideBar";
import { ProjectResource } from "client/resources/projectResource";
import { ActionButtonType } from "components/Button/ActionButton";
import ActionList from "components/ActionList/ActionList";
import StepSorter from "./DeploymentPartSorter";
import OverflowMenu, { OverflowMenuItems, MenuItem } from "components/Menu/OverflowMenu";
import { DeploymentStepResource, StartTrigger } from "client/resources/deploymentStepResource";
import { DataBaseComponent, DataBaseComponentState, DoBusyTask } from "components/DataBaseComponent/DataBaseComponent";
import { VariableSetContentType } from "client/resources/libraryVariableSetResource";
import { EnvironmentResource } from "client/resources/environmentResource";
import { LifecycleResource } from "client/resources/lifecycleResource";
import { ResourcesById } from "client/repositories/basicRepository";
import { ChannelResource } from "client/resources/channelResource";
import { keyBy, flatten } from "lodash";
import * as tenantTagsets from "components/tenantTagsets";
import OpenDialogButton from "components/Dialog/OpenDialogButton";
import SidebarLayout from "components/SidebarLayout/SidebarLayout";
import { RouteComponentProps } from "react-router";
import routeLinks from "routeLinks";
import Permission from "client/resources/permission";
import PermissionCheck, { isAllowed } from "components/PermissionCheck/PermissionCheck";
import getActionLogoUrl from "../getActionLogoUrl";
import InternalRedirect from "components/Navigation/InternalRedirect/InternalRedirect";
import Callout, { CalloutType } from "components/Callout";
import InternalLink from "components/Navigation/InternalLink";
import { UnstructuredFormSection, Select, Checkbox } from "components/form";
import DeploymentPartContextMenu, { DeploymentProcessEditorFilter } from "./DeploymentPartContextMenu";
import FilterSearchBox from "components/FilterSearchBox";
import { NoResults } from "components/NoResults/NoResults";
import Logger from "client/logger";
import AdvancedFilterLayout from "components/AdvancedFilterLayout/AdvancedFilterLayout";
import { withProjectContext, WithProjectContextInjectedProps, ActionContextProvider } from "../../context";
import { FilterNamedResource, ProcessContextProvider, ProcessContextProps } from "../../context";
import ActionTemplateSearchResource from "client/resources/actionTemplateSearchResource";
import { generateBasicScriptStep } from "./generation";
import { ScriptingLanguage } from "components/scriptingLanguage";
import { DeleteStepsCallout } from "./DeleteStepsCallout";
import { RecentProjects } from "utils/RecentProjects/RecentProjects";

const deploymentPartStyles = require("./DeploymentPart.less");
const rollingStep = require("./step-rolling.svg");

class FilterLayout extends AdvancedFilterLayout<DeploymentProcessEditorFilter> {}

interface DeploymentProcessOverviewState extends DataBaseComponentState {
    project: ProjectResource;
    redirectTo?: string;
    open: boolean;
    isLookupDataLoaded: boolean;
    isSaving: boolean;
    selectedParentStepId: string;
    includedScriptModules: ScriptModule[];
    lifecyclePreview: LifecycleResource;
    environmentsById?: ResourcesById<EnvironmentResource>;
    channelsById?: ResourcesById<ChannelResource>;
    tagSets: TagSetResource[];
    workerPoolsById: ResourcesById<WorkerPoolResource>;
}

// tslint:disable-next-line:no-empty-interface
interface DeploymentProcessOverviewProps
    extends RouteComponentProps<{
        projectSlug: string;
        filterKeyword: string;
        environmentId: string;
    }> {
    processId: string;
    scope: ActionScope;
}

type Props = DeploymentProcessOverviewProps & WithProjectContextInjectedProps;

class DeploymentProcessOverview extends DataBaseComponent<Props, DeploymentProcessOverviewState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            redirectTo: null,
            project: null,
            open: false,
            isLookupDataLoaded: false,
            isSaving: false,
            selectedParentStepId: null,
            includedScriptModules: [],
            lifecyclePreview: null,
            tagSets: null,
            workerPoolsById: null,
        };
    }

    async loadData() {
        const project = this.props.projectContext.state.model;

        await RecentProjects.getInstance().UpdateAccessedProjectIntoLocalStorage(project.Id);
        const [scriptModules, environmentsById, lifecycle, channels, tagSets, wokerPools] = await Promise.all([
            isAllowed({ permission: Permission.LibraryVariableSetView })
                ? repository.LibraryVariableSets.all({
                      contentType: VariableSetContentType.ScriptModule,
                  })
                : [],
            repository.Environments.allById(),
            isAllowed({ permission: Permission.LifecycleView }) ? repository.Lifecycles.get(project.LifecycleId) : Promise.resolve(null),
            isAllowed({
                permission: Permission.ProcessView,
                project: project.Id,
                tenant: "*",
            })
                ? repository.Projects.getChannels(project)
                : Promise.resolve(null),
            tenantTagsets.getAll(),
            repository.WorkerPools.all(),
        ]);

        const lifecyclePreview = isAllowed({ permission: Permission.LifecycleView }) ? await repository.Lifecycles.preview(lifecycle) : null;
        const channelsById = channels ? keyBy(channels.Items, ch => ch.Id) : {};
        const workerPoolsById = keyBy(wokerPools, wp => wp.Id);
        const includedScriptModules = scriptModules.filter(sm => project.IncludedLibraryVariableSetIds.includes(sm.Id));

        this.setState({
            environmentsById,
            project,
            includedScriptModules,
            lifecyclePreview,
            channelsById,
            tagSets,
            workerPoolsById,
            isLookupDataLoaded: true,
        });
    }

    async componentDidMount() {
        await this.doBusyTask(() => this.loadData());
    }

    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} push={true} />;
        }

        return (
            <ActionContextProvider doBusyTask={this.doBusyTask}>
                {actionContext => (
                    <ProcessContextProvider scope={this.props.scope} id={this.props.processId} doBusyTask={this.doBusyTask}>
                        {processContext => {
                            const { state: stepsState, actions: stepsActions } = processContext;
                            const hasLoaded: boolean = this.state.isLookupDataLoaded && !!processContext.state.process;

                            const actions: Array<React.ReactElement<any>> = [];
                            if (hasLoaded) {
                                const overflowMenuItems: Array<MenuItem | MenuItem[]> = [];
                                if (!stepsState.process.Steps || stepsState.process.Steps.length === 0) {
                                    overflowMenuItems.push(
                                        OverflowMenuItems.item(
                                            "Load a sample process",
                                            async () => {
                                                const deploymentProcessUpdated = await addSampleStepsToProcess(this.doBusyTask, stepsState.process);
                                                processContext.actions.onStepsChange(deploymentProcessUpdated);
                                            },
                                            {
                                                permission: processPermission(processContext.state.process),
                                                project: this.props.projectContext.state.model.Id,
                                                wildcard: true,
                                            }
                                        )
                                    );
                                }
                                overflowMenuItems.push(
                                    OverflowMenuItems.downloadItem(
                                        "Download as JSON",
                                        this.state.project.Slug + "-process.json",
                                        client.resolveLinkTemplate("DeploymentProcesses", {
                                            id: stepsState.process.Id,
                                        })
                                    )
                                );
                                if (stepsState.process.Steps && stepsState.process.Steps.length > 0) {
                                    overflowMenuItems.push(
                                        OverflowMenuItems.deleteItem(
                                            "Delete all steps",
                                            "Are you sure you want to delete all steps from this process?",
                                            async () => {
                                                const deploymentProcessUpdated = await deleteAllSteps(this.doBusyTask, stepsState.process);
                                                processContext.actions.onStepsChange(deploymentProcessUpdated);
                                                return true;
                                            },
                                            () => <DeleteStepsCallout />,
                                            {
                                                permission: processPermission(processContext.state.process),
                                                project: this.props.projectContext.state.model.Id,
                                                wildcard: true,
                                            },
                                            false
                                        )
                                    );
                                }
                                overflowMenuItems.push([
                                    OverflowMenuItems.navItem("Audit Trail", routeLinks.configuration.eventsRegardingAny([stepsState.process.Id]), null, {
                                        permission: Permission.EventView,
                                        wildcard: true,
                                    }),
                                ]);
                                if (stepsState.process.Steps && stepsState.process.Steps.length > 1) {
                                    actions.push(this.reorderStepsButton(processContext, this.props.scope));
                                }
                                actions.push(this.addStepButton(processContext));
                                actions.push(<OverflowMenu menuItems={overflowMenuItems} />);
                            }
                            return (
                                <PaperLayout busy={this.state.busy} errors={this.state.errors} title="Deployment Process" sectionControl={hasLoaded && <ActionList actions={actions} />}>
                                    {hasLoaded && stepsState.process.Steps.length === 0 && <Onboarding />}
                                    {hasLoaded && stepsState.process.Steps.length > 0 && (
                                        <>
                                            <SidebarLayout
                                                sideBar={
                                                    <SideBar
                                                        steps={stepsState.process}
                                                        includedScriptModules={this.state.includedScriptModules}
                                                        lifecyclePreview={this.state.lifecyclePreview}
                                                        environmentsById={this.state.environmentsById}
                                                        onDataChanged={() => this.refreshData()}
                                                    />
                                                }
                                            >
                                                <FilterLayout
                                                    filter={stepsState.filter}
                                                    filterSections={[
                                                        {
                                                            render: (
                                                                <>
                                                                    <PermissionCheck permission={Permission.EnvironmentView} wildcard={true}>
                                                                        <Select
                                                                            value={stepsState.filter.environment && stepsState.filter.environment.Id}
                                                                            onChange={environmentId => {
                                                                                let environment: FilterNamedResource;
                                                                                if (environmentId) {
                                                                                    const resource = this.state.environmentsById[environmentId];
                                                                                    environment = {
                                                                                        Id: resource.Id,
                                                                                        Name: resource.Name,
                                                                                    };
                                                                                }
                                                                                stepsActions.onFilterChange(t => ({ ...t, environment }));
                                                                            }}
                                                                            items={Object.values(this.state.environmentsById).map(e => ({ value: e.Id, text: e.Name }))}
                                                                            allowClear={true}
                                                                            allowFilter={true}
                                                                            fieldName="environment"
                                                                        />
                                                                    </PermissionCheck>
                                                                    {Object.keys(this.state.channelsById).length > 1 && (
                                                                        <Select
                                                                            value={stepsState.filter.channel && stepsState.filter.channel.Id}
                                                                            onChange={channelId => {
                                                                                let channel: FilterNamedResource;
                                                                                if (channelId) {
                                                                                    const resource = this.state.channelsById[channelId];
                                                                                    channel = {
                                                                                        Id: resource.Id,
                                                                                        Name: resource.Name,
                                                                                    };
                                                                                }
                                                                                stepsActions.onFilterChange(t => ({ ...t, channel }));
                                                                            }}
                                                                            items={Object.values(this.state.channelsById).map(e => ({ value: e.Id, text: e.Name }))}
                                                                            allowClear={true}
                                                                            allowFilter={true}
                                                                            fieldName="channel"
                                                                        />
                                                                    )}
                                                                    <Checkbox
                                                                        label="Include unscoped steps"
                                                                        value={stepsState.filter.includeUnscoped}
                                                                        onChange={includeUnscoped => {
                                                                            stepsActions.onFilterChange(prev => ({ ...prev, includeUnscoped }));
                                                                        }}
                                                                    />
                                                                </>
                                                            ),
                                                        },
                                                    ]}
                                                    onFilterReset={filter => stepsActions.onFilterChange(prev => filter)}
                                                    defaultFilter={processContext.getEmptyFilter()}
                                                    initiallyShowFilter={processContext.isFiltering}
                                                    additionalHeaderFilters={[
                                                        <FilterSearchBox
                                                            hintText="Filter by name..."
                                                            value={stepsState.filter.filterKeyword}
                                                            onChange={filterKeyword => stepsActions.onFilterChange(prev => ({ ...prev, filterKeyword }))}
                                                            autoFocus={true}
                                                        />,
                                                    ]}
                                                    renderContent={() => (
                                                        <>
                                                            {this.getInvalidConfigurationCallouts(processContext)}
                                                            <div className={deploymentPartStyles.stepList}>
                                                                {processContext.filteredSteps.steps.length > 0 ? (
                                                                    processContext.filteredSteps.steps
                                                                        .filter(x => x.filtered)
                                                                        .map(({ step: filteredStep, index }) => {
                                                                            const step = this.findStepByName(stepsState.process, filteredStep.Name);
                                                                            if (!step) {
                                                                                Logger.log(`Failed to find step with name ${filteredStep.Name}`);
                                                                                return null;
                                                                            }
                                                                            return step.Actions.length === 1
                                                                                ? this.buildAction(processContext, this.props.scope, actionContext.state.actionTemplates, step, step.Actions[0], index)
                                                                                : this.buildParentStep(processContext, this.props.scope, actionContext.state.actionTemplates, step, index);
                                                                        })
                                                                ) : (
                                                                    <NoResults />
                                                                )}
                                                            </div>
                                                        </>
                                                    )}
                                                />
                                            </SidebarLayout>
                                        </>
                                    )}
                                </PaperLayout>
                            );
                        }}
                    </ProcessContextProvider>
                )}
            </ActionContextProvider>
        );
    }

    private findStepByName(process: IProcessResource, name: string) {
        // We lookup by .Name due to cloned steps not having an ID initially.
        return process.Steps.find(x => x.Name === name);
    }

    private buildParentStep(processContext: ProcessContextProps, scope: ActionScope, actionTemplates: ActionTemplateSearchResource[], step: DeploymentStepResource, stepIndex: number) {
        const showWindowSize = step.Properties[SpecialVariables.Action.MaxParallelism] ? step.Properties[SpecialVariables.Action.MaxParallelism].toString().length > 0 : false;
        const parentStepLabel = showWindowSize ? (
            <span>Rolling deployment</span>
        ) : (
            <span>
                Multi-step deployment across
                <br />
                deployment targets
            </span>
        );
        return (
            <div key={step.Id} className={deploymentPartStyles.group}>
                <DeploymentPartContextMenu
                    scope={this.props.scope}
                    keywordSearch={processContext.state.filter && processContext.state.filter.filterKeyword}
                    step={step}
                    stepIndex={stepIndex}
                    isParentGroup={true}
                    render={renderProps => {
                        return (
                            <DeploymentPart actionType={parentStepLabel} logoUrl={rollingStep} {...renderProps}>
                                {step.Properties[SpecialVariables.Action.MaxParallelism] ? <span>Rolling deployment</span> : <span>Multi-step deployment</span>}
                                &nbsp;across deployment targets in&nbsp;
                                <Roles rolesAsCSV={step.Properties[SpecialVariables.Action.TargetRoles] as string} />
                            </DeploymentPart>
                        );
                    }}
                />
                {step.Actions.map((action, index) => this.buildAction(processContext, scope, actionTemplates, step, action, index + 1, stepIndex))}
            </div>
        );
    }

    private buildAction(processContext: ProcessContextProps, scope: ActionScope, actionTemplates: ActionTemplateSearchResource[], step: DeploymentStepResource, action: DeploymentActionResource, actionIndex: number, stepIndex?: number) {
        const isChildAction = !!stepIndex;
        let actionTypeName = action.ActionType;
        const actionTemplate = actionTemplates.find(x => x.Type === action.ActionType);
        if (actionTemplate) {
            actionTypeName = actionTemplate.Name;
        }

        const environmentsUserCanAccess = (environments: string[]) => environments.filter(e => Object.keys(this.state.environmentsById).includes(e));

        return (
            <DeploymentPartContextMenu
                scope={this.props.scope}
                key={action.Id}
                step={step}
                action={action}
                stepIndex={stepIndex}
                actionIndex={actionIndex}
                isChildAction={isChildAction}
                keywordSearch={processContext.state.filter && processContext.state.filter.filterKeyword}
                render={renderProps => {
                    return (
                        <DeploymentPart
                            actionType={actionTypeName}
                            logoUrl={getActionLogoUrl(action)}
                            environments={environmentsUserCanAccess(action.Environments).map(id => this.state.environmentsById[id])}
                            excludedEnvironments={environmentsUserCanAccess(action.ExcludedEnvironments).map(id => this.state.environmentsById[id])}
                            channelsLookup={action.Channels.map(id => ({
                                Id: id,
                                Channel: this.state.channelsById[id],
                            }))}
                            tags={this.getTags(action)}
                            {...renderProps}
                        >
                            {pluginRegistry
                                .getAction(action.ActionType, scope)
                                .summary(action.Properties, isChildAction ? null : (step.Properties[SpecialVariables.Action.TargetRoles] as string), action.Packages, action.WorkerPoolId ? this.state.workerPoolsById[action.WorkerPoolId].Name : null)}
                        </DeploymentPart>
                    );
                }}
            />
        );
    }

    private refreshData = async () => {
        await this.doBusyTask(() => this.loadData());
    };

    private calculateDetailsUrl(processContext: ProcessContextProps, id: string): string | null {
        return routeLinks.project(processContext.state.process.ProjectId).process.step(id);
    }

    private getInvalidAutomaticReleaseCreationConfigurationCallout(processContext: ProcessContextProps) {
        if (this.state.project.AutoCreateRelease) {
            if (this.state.project.ReleaseCreationStrategy == null || this.state.project.ReleaseCreationStrategy.ReleaseCreationPackage == null) {
                return (
                    <div>
                        This project is configured to use Automatic Release Creation, but the step is missing. Please adjust the <InternalLink to={routeLinks.project(this.state.project.Slug).triggers}>Automatic Release Creation</InternalLink>{" "}
                        configuration.
                    </div>
                );
            } else {
                const action = flatten(processContext.state.process.Steps.map(step => step.Actions)).filter(a => a.Name === this.state.project.ReleaseCreationStrategy.ReleaseCreationPackage.DeploymentAction);
                if (action && action.length > 0 && action[0].IsDisabled) {
                    return (
                        <div>
                            Step <InternalLink to={this.calculateDetailsUrl(processContext, action[0].Id)}>{action[0].Name}</InternalLink> is currently used for Automatic Release Creation, but it has been disabled. Please re-enable the step, or
                            adjust the <InternalLink to={routeLinks.project(this.state.project.Slug).triggers}>Automatic Release Creation</InternalLink> configuration.
                        </div>
                    );
                }
            }
        }
        return null;
    }

    private getInvalidVersioningConfigurationCallout(processContext: ProcessContextProps) {
        if (this.state.project.VersioningStrategy.DonorPackage) {
            const action = flatten(processContext.state.process.Steps.map(step => step.Actions)).filter(a => a.Name === this.state.project.VersioningStrategy.DonorPackage.DeploymentAction);
            if (action && action.length > 0 && action[0].IsDisabled) {
                return (
                    <div>
                        Step <InternalLink to={this.calculateDetailsUrl(processContext, action[0].Id)}>{action[0].Name}</InternalLink> is currently used for release versioning, but it has been disabled.
                        <br />
                        Please re-enable the step or adjust the <InternalLink to={routeLinks.project(this.state.project.Slug).settings}>release versioning</InternalLink> configuration.
                    </div>
                );
            }
        }
        return null;
    }

    private getInvalidConfigurationCallouts(processContext: ProcessContextProps) {
        if (this.state.isLookupDataLoaded && !this.state.isSaving) {
            const arcCallout = this.getInvalidAutomaticReleaseCreationConfigurationCallout(processContext);
            const versioningCallout = this.getInvalidVersioningConfigurationCallout(processContext);
            if (arcCallout || versioningCallout) {
                return (
                    <UnstructuredFormSection stretchContent={true}>
                        <Callout type={CalloutType.Warning} title="Invalid Configuration">
                            {arcCallout}
                            {versioningCallout}
                        </Callout>
                    </UnstructuredFormSection>
                );
            }
        }
        return null;
    }

    private reorderStepsButton(processContext: ProcessContextProps, scope: ActionScope) {
        return (
            <PermissionCheck permission={processPermission(processContext.state.process)} project={this.state.project.Id} wildcard={true}>
                <OpenDialogButton label="Reorder steps" type={ActionButtonType.Secondary}>
                    <StepSorter scope={scope} title="Reorder Steps" processId={this.props.processId} saveDone={this.refreshData} onStepsUpdated={processContext.actions.onStepsChange} />
                </OpenDialogButton>
            </PermissionCheck>
        );
    }

    private addStepButton(processContext: ProcessContextProps) {
        return (
            <PermissionCheck permission={processPermission(processContext.state.process)} project={this.state.project.Id} wildcard={true}>
                <NavigationButton type={NavigationButtonType.Primary} label="Add Step" href={routeLinks.project(this.state.project).steptemplates} />
            </PermissionCheck>
        );
    }

    private getTags(action: DeploymentActionResource): string[] {
        if (this.state.project.TenantedDeploymentMode === TenantedDeploymentMode.Untenanted) {
            return [];
        }
        return action.TenantTags;
    }
}

export async function addSampleStepsToProcess(doBusyTask: DoBusyTask, process: IProcessResource): Promise<IProcessResource> {
    const learnMoreMarkdown = "[Learn more about the types of steps available in Octopus](https://g.octopushq.com/OnboardingAddStepsLearnMore)";

    if (!process.Steps) {
        process.Steps = [];
    }

    const powerShellStep = generateBasicScriptStep("Hello world (using PowerShell)", ScriptingLanguage.PowerShell, `Write-Host 'Hello world, using PowerShell'\n\n#TODO: Experiment with steps of your own :)\n\nWrite-Host '${learnMoreMarkdown}'`);
    const cSharpStep = {
        ...generateBasicScriptStep("Hello World (using C#)", ScriptingLanguage.CSharp, `Console.WriteLine("Hello world, using C#");\n\n//TODO: Experiment with steps of your own :)\n\nConsole.WriteLine("${learnMoreMarkdown}");`),
        StartTrigger: StartTrigger.StartWithPrevious,
    };
    process.Steps.push(powerShellStep);
    process.Steps.push(cSharpStep);

    let result: IProcessResource;
    await doBusyTask(async () => {
        if (isDeploymentProcessResource(process)) {
            result = await repository.DeploymentProcesses.modify(process);
        } else if (isRunbookProcessResource(process)) {
            result = await repository.RunbookProcess.modify(process);
        }
    });
    return result;
}

export async function deleteAllSteps(doBusyTask: DoBusyTask, process: IProcessResource): Promise<IProcessResource> {
    process.Steps = [];
    let result: IProcessResource;
    await doBusyTask(async () => {
        if (isDeploymentProcessResource(process)) {
            result = await repository.DeploymentProcesses.modify(process);
        } else if (isRunbookProcessResource(process)) {
            result = await repository.RunbookProcess.modify(process);
        }
    });
    return result;
}

const EnhancedDeploymentProcessOverview = withProjectContext(DeploymentProcessOverview);
export default EnhancedDeploymentProcessOverview;
