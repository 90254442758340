import * as React from "react";
import { repository } from "clientInstance";
import { DataBaseComponentState } from "components/DataBaseComponent";
import { DataBaseComponent } from "components/DataBaseComponent/DataBaseComponent";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import { required, Text, Checkbox, Note, RadioButtonGroup, RadioButton, FormSectionHeading } from "components/form";
import MarkdownEditor from "../../../../../components/form/MarkdownEditor/MarkdownEditor";
import { WorkerPoolResource, Permission } from "client/resources";
import { WorkerPoolType } from "client/resources/workerPoolsSupportedTypesResouce";
import { flatten } from "lodash";

interface AddWorkerPoolsDialogContentProps {
    throwErrors?: boolean;
    saveDone?(workerPool: WorkerPoolResource): any;
}

interface AddWorkerPoolsDialogContentState extends DataBaseComponentState {
    name: string;
    description: string;
    useGuidedFailure: boolean;
    newWorkerPoolId: string;
    workerPoolType: WorkerPoolType;
    supportedPoolTypes: WorkerPoolType[];
}

export default class AddWorkerPoolsDialogContent extends DataBaseComponent<AddWorkerPoolsDialogContentProps, AddWorkerPoolsDialogContentState> {
    constructor(props: AddWorkerPoolsDialogContentProps) {
        super(props);
        this.state = {
            name: "",
            description: "",
            useGuidedFailure: false,
            newWorkerPoolId: "",
            workerPoolType: WorkerPoolType.Static,
            supportedPoolTypes: [],
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const result = await repository.WorkerPools.getSupportedPoolTypes();
            this.setState({ supportedPoolTypes: result.SupportedPoolTypes });
        });
    }
    async save() {
        return this.doBusyTask(async () => {
            const result = await repository.WorkerPools.create(
                {
                    Id: null,
                    Name: this.state.name,
                    Description: this.state.description,
                    SpaceId: null,
                    SortOrder: -1, // API automatically assigns this value if <= 0.
                    IsDefault: false,
                    CanAddWorkers: false,
                    WorkerPoolType: this.state.workerPoolType,
                    Links: null,
                },
                {}
            );
            this.setState({
                newWorkerPoolId: result.Id,
            });
            await this.props.saveDone(result);
        });
    }

    render() {
        return (
            <SaveDialogLayout title={"Add Worker Pool"} busy={this.state.busy} errors={this.state.errors} savePermission={{ permission: Permission.WorkerEdit }} onSaveClick={() => this.save()}>
                <Text label="New worker pool name" value={this.state.name} onChange={this.handleNameChange} autoFocus={true} validate={required("Please enter an worker pool name")} />
                {this.state.supportedPoolTypes && this.state.supportedPoolTypes.length > 1 ? (
                    <div>
                        <RadioButtonGroup label="Worker Pool Type" value={this.state.workerPoolType} onChange={this.handleWorkerPoolTypeChange}>
                            {flatten(this.state.supportedPoolTypes.map(t => [<RadioButton value={t} label={this.getPoolTypeLabel(t)} key={t} />, <Note key={`${t}_note`}>{this.getWorkerPoolTypeDescription(t)}</Note>]))}
                        </RadioButtonGroup>
                    </div>
                ) : null}
            </SaveDialogLayout>
        );
    }

    private handleNameChange = (value: string) => {
        this.setState({ name: value });
    };

    private handleWorkerPoolTypeChange = (value: WorkerPoolType) => {
        this.setState({
            workerPoolType: value,
        });
    };

    private getPoolTypeLabel(t: WorkerPoolType): string {
        switch (t) {
            case WorkerPoolType.Static:
                return "Static";
            case WorkerPoolType.Dynamic:
                return "Dynamic";
        }

        return assertUnreachable(t);
    }

    private getWorkerPoolTypeDescription(t: WorkerPoolType): string {
        switch (t) {
            case WorkerPoolType.Static:
                return "A static worker pool allows you to add your own workers for executing deployments";
            case WorkerPoolType.Dynamic:
                return "The dynamic worker pool will lease a worker from the Worker service to use for a deployment";
        }

        return assertUnreachable(t);
    }
}

function assertUnreachable(x: never): never {
    throw new Error("Didn't expect to get here");
}
