import * as React from "react";
import { repository } from "clientInstance";
import { ActionButton, ActionButtonType } from "components/Button";
import EnvironmentMultiSelect from "components/MultiSelect/EnvironmentMultiSelect";
import { cloneDeep } from "lodash";
import { ProjectResource, EnvironmentResource, TenantedDeploymentMode, Permission, RunbookResource, ProgressionResource } from "client/resources";
import PermissionCheck, { isAllowed } from "components/PermissionCheck/PermissionCheck";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import SaveDialogLayout from "../../../components/DialogLayout/SaveDialogLayout";
import { TenantResource } from "../../../client/resources/tenantResource";
import { Callout, CalloutType } from "../../../components/Callout/Callout";
import Select from "components/form/Select/Select";
import routeLinks from "routeLinks";
import InternalLink from "components/Navigation/InternalLink";

interface AddProjectsToTenantDialogProps {
    existingProjectLink?: { projectId: string; environmentIds: string[] };
    excludedProjects: string[];
    tenant: TenantResource;
    onUpdated(tenant: TenantResource): void;
}

interface AddProjectsToTenantDialogState extends DataBaseComponentState {
    selectedProject?: ProjectResource;
    runbooksInSelectedProject?: RunbookResource[];
    environmentIds: string[];
    projects?: ProjectResource[];
    availableEnvironments?: EnvironmentResource[];
    isLoaded: boolean;
}

export default class AddProjectsToTenantDialog extends DataBaseComponent<AddProjectsToTenantDialogProps, AddProjectsToTenantDialogState> {
    constructor(props: AddProjectsToTenantDialogProps) {
        super(props);
        this.state = {
            environmentIds: null,
            isLoaded: false,
        };
    }

    componentWillReceiveProps(nextProps: AddProjectsToTenantDialogProps) {
        this.setExistingProject(nextProps);
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const projects = await repository.Projects.all();
            this.setState({ projects }, () => this.setExistingProject(this.props));
        });
    }

    setExistingProject(props: AddProjectsToTenantDialogProps) {
        const { projectId = null, environmentIds = [] } = props.existingProjectLink || {};
        const selectedProject = projectId && this.state.projects ? this.state.projects.find(p => p.Id === projectId) : null;
        //TODO markse: shouldn't need to chain these calls, review isLoaded
        this.setState(
            {
                environmentIds,
                selectedProject,
                availableEnvironments: [],
                isLoaded: !selectedProject,
            },
            async () => {
                if (selectedProject) {
                    await this.doBusyTask(async () => {
                        const progression = await repository.Progression.getProgression(selectedProject);
                        const runbooksInProject = await repository.Projects.getRunbooks(selectedProject, { take: repository.takeAll });
                        this.setState({
                            availableEnvironments: progression.Environments as EnvironmentResource[],
                            isLoaded: true,
                            runbooksInSelectedProject: runbooksInProject.Items,
                        });
                    });
                }
            }
        );
    }

    enableMultiTenancy = async (project: ProjectResource, runbooksInSelectedProject: RunbookResource[]) => {
        const updateProject = () => {
            project.TenantedDeploymentMode = TenantedDeploymentMode.TenantedOrUntenanted;
            return repository.Projects.modify(project);
        };

        const updateRunbooks = () =>
            runbooksInSelectedProject.map(runbook => {
                runbook.MultiTenancyMode = TenantedDeploymentMode.TenantedOrUntenanted;
                return repository.Runbooks.modify(runbook);
            });

        await this.doBusyTask(async () => {
            await Promise.all([updateProject(), updateRunbooks()]);
        });
    };

    handleProjectSelected = async (projectId: string) => {
        const selectedProject = projectId ? this.state.projects.find(p => p.Id === projectId) : null;
        this.setState({ selectedProject, environmentIds: [], availableEnvironments: [] }, async () => {
            if (selectedProject) {
                await this.doBusyTask(async () => {
                    const [progression, runbooksInProject] = await Promise.all([repository.Progression.getProgression(selectedProject), repository.Projects.getRunbooks(selectedProject, { take: repository.takeAll })]);

                    this.setState({
                        availableEnvironments: progression.Environments as EnvironmentResource[],
                        runbooksInSelectedProject: runbooksInProject.Items,
                    });
                });
            }
        });
    };

    handleEnvironmentsSelected = async (environmentIds: string[]) => {
        this.setState({ environmentIds });
    };

    renderProjectUntenanted() {
        const project = this.state.selectedProject;
        return (
            <Callout title="To connect, a project must have tenanted deployments enabled." type={CalloutType.Warning}>
                Do you want to enable tenanted deployments for <b>{project.Name}</b>?
                <PermissionCheck permission={Permission.ProjectEdit} project={project.Id} projectGroup={project.ProjectGroupId} tenant="*">
                    <div style={{ marginTop: "1rem" }}>
                        <ActionButton label={`Enable tenanted deployments`} type={ActionButtonType.Primary} onClick={() => this.enableMultiTenancy(project, this.state.runbooksInSelectedProject)} />
                    </div>
                </PermissionCheck>
            </Callout>
        );
    }

    renderEnvironmentMultiSelect() {
        return (
            this.state.isLoaded && [
                <EnvironmentMultiSelect key="select" onChange={this.handleEnvironmentsSelected} value={this.state.environmentIds ? this.state.environmentIds : []} items={this.state.availableEnvironments} autoFocus />,
                this.state.environmentIds && this.state.environmentIds.length === 0 ? (
                    <Callout key="callout" title="No Environments Selected" type={CalloutType.Information}>
                        A tenant needs to be linked to an environment of a project before deployments can take place.
                    </Callout>
                ) : null,
            ]
        );
    }

    renderExistingProject() {
        return this.state.selectedProject && this.state.runbooksInSelectedProject && (this.canProjectDeployTenanted() ? this.renderEnvironmentMultiSelect() : this.renderProjectUntenanted());
    }

    renderNewProjectLink() {
        if (!this.state.projects) {
            return;
        }

        const projects = this.state.projects.filter(p => !this.props.excludedProjects.includes(p.Id)).map(p => ({ value: p.Id, text: p.Name }));

        return (
            <div>
                {projects && projects.length === 0 ? (
                    <Callout type={CalloutType.Information} title="No Projects">
                        Create your first <InternalLink to={routeLinks.projects.root}>project</InternalLink> now.
                    </Callout>
                ) : (
                    [<Select onChange={this.handleProjectSelected} label="Select a project" value={this.state.selectedProject ? this.state.selectedProject.Id : null} items={projects} allowFilter={true} autoFocus />, this.renderExistingProject()]
                )}
            </div>
        );
    }

    canProjectDeployTenanted() {
        const deploymentsAllowsMultiTenancy = this.state.selectedProject && this.state.selectedProject.TenantedDeploymentMode !== TenantedDeploymentMode.Untenanted;
        if (deploymentsAllowsMultiTenancy) {
            return true;
        }

        return this.state.runbooksInSelectedProject && this.state.runbooksInSelectedProject.some(r => r.MultiTenancyMode !== TenantedDeploymentMode.Untenanted);
    }

    save = async () => {
        return this.doBusyTask(async () => {
            let tenant = cloneDeep(this.props.tenant);
            tenant.ProjectEnvironments[this.state.selectedProject.Id] = this.state.environmentIds ? this.state.environmentIds : [];
            tenant = await repository.Tenants.save(tenant);
            setImmediate(() => this.props.onUpdated(tenant));
            return true;
        });
    };

    render() {
        const isExistingProjectLink = !!(this.props.existingProjectLink && this.props.existingProjectLink.projectId);
        const title = isExistingProjectLink && this.state.selectedProject ? `Change Connection to ${this.state.selectedProject.Name}` : "Connect to a Project";
        const updateButton = isExistingProjectLink ? "Update Connection" : "Add Connection";

        return (
            <SaveDialogLayout
                title={title}
                busy={this.state.busy}
                errors={this.state.errors}
                onSaveClick={this.save}
                savePermission={{ permission: Permission.TenantEdit, tenant: this.props.tenant.Id }}
                saveButtonDisabled={!this.canProjectDeployTenanted()}
                saveButtonLabel={updateButton}
            >
                {isExistingProjectLink ? this.renderExistingProject() : this.renderNewProjectLink()}
            </SaveDialogLayout>
        );
    }
}
