import * as React from "react";
import { repository } from "clientInstance";
import { ActionTemplateResource, ControlType } from "client/resources";
import FormSectionHeading from "components/form/Sections/FormSectionHeading";
import { RouteComponentProps } from "react-router";
import { default as TaskResource } from "client/resources/taskResource";
import Callout, { CalloutType } from "components/Callout/Callout";
import Markdown from "components/Markdown/index";
import { Section } from "components/Section/Section";
import AdHocScript, { AdHocScriptModel, AdHocScriptState } from "components/AdHocScript/AdHocScript";
import LibraryLayout from "areas/library/components/LibraryLayout/LibraryLayout";
import ActionProperties from "client/resources/actionProperties";
import ActionTemplateParameterInputExpandableFormElement from "../../../../components/ActionTemplateParameterInput/ActionTemplateParameterInputExpandableFormElement";
import { AdHocScriptTargetArguments } from "../../../../client/repositories/taskRepository";
import { PackageReference } from "client/resources/packageReference";
import { SourceItems } from "components/ActionTemplateParameterInput/ActionTemplateParameterInput";

const styles = require("./style.less");

interface RunActionTemplateParams {
    templateId: string;
}

interface RunActionTemplateModel extends AdHocScriptModel {
    Properties: ActionProperties;
}

interface RunActionTemplateState extends AdHocScriptState<RunActionTemplateModel> {
    template?: ActionTemplateResource;
    sourceItems?: SourceItems;
}

export default class RunActionTemplate extends AdHocScript<RouteComponentProps<RunActionTemplateParams>, RunActionTemplateState, RunActionTemplateModel> {
    getCustomInputs() {
        return (
            this.state.template.Parameters &&
            this.state.template.Parameters.length > 0 &&
            this.state.sourceItems && (
                <div>
                    <FormSectionHeading title="Parameters" />
                    {this.state.isRetry && this.state.template.Parameters.find(p => p.DisplaySettings["Octopus.ControlType"] === "Sensitive") && (
                        <Callout type={CalloutType.Information} title={"Please re-enter sensitive values"}>
                            {" "}
                            Sensitive values are not remembered across runs.
                        </Callout>
                    )}
                    {this.state.template.Parameters.map(p => (
                        <ActionTemplateParameterInputExpandableFormElement
                            parameter={p}
                            key={p.Name}
                            isExpandedByDefault={true}
                            value={this.state.model.Properties[p.Name]}
                            sourceItems={this.state.sourceItems}
                            doBusyTask={this.doBusyTask}
                            onChange={value => this.handleParameterInputChanged(p.Name, value)}
                            actionType={this.state.template.ActionType}
                        />
                    ))}
                </div>
            )
        );
    }

    handleParameterInputChanged = (name: string, value: any) => {
        this.setState(state => ({
            model: {
                ...state.model,
                Properties: {
                    ...state.model.Properties,
                    [name]: value,
                },
            },
        }));
    };

    getHeading() {
        return (
            <div>
                <Section>
                    <Markdown markup={this.state.template.Description} />
                </Section>
                {(this.dependsOn(this.state.template, ControlType.StepName) || this.dependsOn(this.state.template, ControlType.Package)) && (
                    <Callout type={CalloutType.Danger} title={"This step template may not work as expected"}>
                        {this.getDependsOnWarningMessage(this.state.template)}
                    </Callout>
                )}
            </div>
        );
    }

    async getInitialPartialModel(taskToRetry?: TaskResource<any>) {
        const template = await repository.ActionTemplates.get(this.props.match.params.templateId);
        const feeds = await repository.Feeds.all();

        this.setState({
            template,
            sourceItems: {
                packages: {
                    items: template.Packages,
                    feeds,
                    onRequestRefresh: this.refreshFeeds,
                    setPackages: this.setPackages,
                },
            },
        });

        // The properties for this task can come from an existing task (Modify and Re-run option)
        // Otherwise use the default values from the template as a starting place
        return {
            Properties: this.fromExistingTaskOrDefaultValues(taskToRetry, template),
        };
    }

    getTitle() {
        return this.state.template.Name;
    }

    wrapWithLayout(content: React.ReactNode) {
        return <LibraryLayout {...this.props}>{content}</LibraryLayout>;
    }

    createAdHocScriptTask(targetTaskArguments: AdHocScriptTargetArguments): Promise<TaskResource<any>> {
        return repository.Tasks.createRunActionTemplateTask(targetTaskArguments, this.state.model.Properties, this.state.template);
    }

    private fromExistingTaskOrDefaultValues = (task: TaskResource<any>, template: ActionTemplateResource) => {
        const empty: ActionProperties = {};

        return template.Parameters.map(p => {
            return {
                name: p.Name,
                value: (task && task.Arguments && task.Arguments.Properties && task.Arguments.Properties[p.Name]) || p.DefaultValue || null,
            };
        })
            .filter(nameValue => !!nameValue.value)
            .reduce((properties, nameValue) => {
                properties[nameValue.name] = nameValue.value;
                return properties;
            }, empty);
    };

    private refreshFeeds = async () => {
        this.setState(async state => ({
            sourceItems: {
                ...state.sourceItems,
                packages: {
                    ...state.sourceItems.packages,
                    feeds: await repository.Feeds.all(),
                },
            },
        }));
    };

    private setPackages = (packages: Array<PackageReference<any>>, initialise?: boolean) => {
        this.setState(state => ({
            template: {
                ...state.template,
                Packages: packages,
            },
            sourceItems: {
                ...state.sourceItems,
                packages: {
                    ...state.sourceItems.packages,
                    items: packages,
                },
            },
        }));
    };

    private dependsOn(template: ActionTemplateResource, controlType: ControlType) {
        return template.Parameters && template.Parameters.some(p => p.DisplaySettings && p.DisplaySettings["Octopus.ControlType"] === controlType);
    }

    private getDependsOnWarningMessage(template: ActionTemplateResource) {
        const dependsOnMsg = [];
        if (this.dependsOn(template, ControlType.StepName)) {
            dependsOnMsg.push("a previous deployment step name");
        }
        if (this.dependsOn(template, ControlType.Package)) {
            dependsOnMsg.push("a package selected on a project");
        }

        return <React.Fragment>One of the parameters of this step template depends on {dependsOnMsg.join(" and ")}. As this is not available in this context, this step template may not work as expected.</React.Fragment>;
    }
}
