import { MenuNode, MenuNodeComponent } from "components/LinksMenu/MenuNode";
import { Menu } from "material-ui";
import Popover from "components/Popover";
import { ClickAwayListener } from "@material-ui/core";
import * as React from "react";
import { linkMenuClassName } from "uiTestClasses";

interface LinksMenuProps {
    items: MenuNode[];
    label?: string | JSX.Element;
    icon?: string;
    activeItemClassName?: string;
}

interface LinksMenuState {
    isOpen: boolean;
    anchor?: HTMLAnchorElement;
}

export class LinksMenu extends React.Component<LinksMenuProps, LinksMenuState> {
    constructor(props: LinksMenuProps) {
        super(props);
        this.state = { isOpen: false };
    }

    render() {
        return (
            <ClickAwayListener onClickAway={() => this.closeMenu()}>
                <a onClick={e => this.openMenu(e)} href="#" className={this.props.activeItemClassName}>
                    {this.props.icon && <em className={this.props.icon} />}
                    {!this.props.icon && <span>{this.props.label}</span>}
                    {!this.props.icon && <em className="fa fa-caret-down" />}

                    <Popover
                        className={linkMenuClassName}
                        open={this.state.isOpen}
                        anchorEl={this.state.anchor}
                        onClose={() => this.closeMenu()}
                        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                        transformOrigin={{ horizontal: "right", vertical: "top" }}
                    >
                        <Menu>
                            {this.props.items.map((item, i) => (
                                <MenuNodeComponent node={item} key={i} onClick={this.closeMenu} />
                            ))}
                        </Menu>
                    </Popover>
                </a>
            </ClickAwayListener>
        );
    }

    private closeMenu = () => {
        this.setState({ isOpen: false, anchor: null });
    };

    private openMenu(event: React.MouseEvent<HTMLAnchorElement>) {
        if (event.currentTarget.contains(event.target as HTMLElement)) {
            event.preventDefault();
            const anchor = event.currentTarget;
            this.setState({ isOpen: true, anchor });
        }
    }
}
