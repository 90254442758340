import * as React from "react";
import { TaskStatusIcon } from "areas/projects/components/TaskStatusIcon/TaskStatusIcon";
import DateFormatter from "utils/DateFormatter";
import cn from "classnames";
const styles = require("./style.less");
import { DeploymentResource } from "../../../../../client/resources/deploymentResource";
import { TaskResource } from "../../../../../client/resources/taskResource";

interface DeploymentDetailsIconProps {
    task: TaskResource<{ DeploymentId: string }>;
    deployment: DeploymentResource;
    stripTopBottomPadding?: boolean;
}

const DeploymentDetailsIcon: React.StatelessComponent<DeploymentDetailsIconProps> = (props: DeploymentDetailsIconProps) => {
    const task = props.task;
    const deployment = props.deployment;

    return (
        <div className={cn(styles.taskDetailsContainer, { [styles.stripTopBottomPadding]: props.stripTopBottomPadding })}>
            <TaskStatusIcon item={task} smallIcon={false} />
            <div className={styles.details}>
                <span className={styles.description}>{deployment.Name}</span>
                <span title={DateFormatter.dateToLongFormat(task.StartTime || task.QueueTime)} className={styles.date}>
                    {DateFormatter.dateToCustomFormat(task.StartTime || task.QueueTime, "MMMM Do YYYY")}
                </span>
            </div>
        </div>
    );
};

DeploymentDetailsIcon.displayName = "DeploymentDetailsIcon";

export default DeploymentDetailsIcon;
