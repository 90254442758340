import { PackageReference, PackageSelectionMode } from "client/resources/packageReference";

export function getChangesToPackageReference(newPackageSelectionMode: PackageSelectionMode): Partial<PackageReference> {
    switch (newPackageSelectionMode) {
        case PackageSelectionMode.Immediate:
            return { Properties: { PackageParameterName: null, SelectionMode: newPackageSelectionMode } };
        case PackageSelectionMode.Deferred:
            return { PackageId: null, FeedId: null, Properties: { SelectionMode: newPackageSelectionMode } };
    }
}
