import ParseHelper from "utils/ParseHelper";
import * as React from "react";
import { Switch, RouteComponentProps, withRouter } from "react-router-dom";
import DeploymentProcessOverview from "./DeploymentProcessOverview";
import StepDetailsLoader from "./StepDetailsLoader";
import ActionTemplateSelector from "areas/projects/components/ActionTemplateSelector/ActionTemplateSelector";
import ReloadableRoute from "components/ReloadableRoute/ReloadableRoute";
import pageIds from "pageIds";
import { withPage } from "components/Page/Page";
import { withProjectDeploymentProcessId, WithProjectDeploymentProcessInjectedProps } from "./withProjectDeploymentProcess";
import { WithActionScopeInjectedProps } from "components/Actions/withActionScope";
import { ActionScope } from "components/Actions/pluginRegistry";
import { Channels } from "../Channels/Channels";

const specificProjectPage = pageIds.project();

// Preparation for supporting multiple processes: We currently don't have routes setup for this and injecting the scope and process id
// from the context avoids having to change the routes.
const withProjectDeploymentProcess = <T extends unknown>(Component: React.ComponentType<T & WithActionScopeInjectedProps & WithProjectDeploymentProcessInjectedProps>) => {
    return withProjectDeploymentProcessId(Component);
};

export const CreateStepDetailsPage = withPage({ page: specificProjectPage.process.stepNew })(withProjectDeploymentProcess(StepDetailsLoader));
export const StepDetailsPage = withPage({ page: specificProjectPage.process.step })(withProjectDeploymentProcess(StepDetailsLoader));
export const ActionTemplateSelectionPage = withPage({ page: specificProjectPage.process.stepTemplates })(withProjectDeploymentProcess(ActionTemplateSelector));
export const DeploymentProcessOverviewPage = withPage({ page: specificProjectPage.process.root })(withProjectDeploymentProcess(DeploymentProcessOverview));

export const ChannelsPage = withPage({ page: pageIds.project().channel })(Channels);

type ProcessRouteProps = {
    path: string;
    scope: ActionScope;
} & WithActionScopeInjectedProps;

type Props = ProcessRouteProps & RouteComponentProps<any>;

export function nextStepReloadPath(currentPath: string, currentKey: string): string {
    let path = currentPath;
    if (currentKey) {
        const keyNum = ParseHelper.safeParseInt(currentKey, 0) + 1;
        path = path.slice(0, -currentKey.length) + keyNum.toString();
    } else {
        path = path + "/1";
    }
    return path;
}

class ProcessRoute extends React.Component<Props, any> {
    render() {
        const match = this.props.match;
        return (
            <Switch>
                <ReloadableRoute path={`${this.props.path}/step/new/:actionType/:template?`} render={props => <CreateStepDetailsPage scope={this.props.scope} {...props} />} />
                <ReloadableRoute path={`${this.props.path}/step/:stepId/new/:actionType/:template?`} render={props => <CreateStepDetailsPage scope={this.props.scope} {...props} />} />
                <ReloadableRoute path={`${this.props.path}/step/:stepId/:reloadKey?`} render={props => <StepDetailsPage scope={this.props.scope} {...props} />} />
                <ReloadableRoute path={`${this.props.path}/steptemplates`} render={props => <ActionTemplateSelectionPage scope={this.props.scope} {...props} />} />
                <ReloadableRoute path={`${this.props.path}/childsteptemplates/:parentStepId`} render={props => <ActionTemplateSelectionPage scope={this.props.scope} {...props} />} />
                <ReloadableRoute path={`${this.props.path}`} render={props => <DeploymentProcessOverviewPage scope={this.props.scope} {...props} />} />
                <ReloadableRoute path={`${match.path}`} render={props => <DeploymentProcessOverviewPage scope={this.props.scope} {...props} />} />
            </Switch>
        );
    }
}

const EnhancedProcessRoute = withRouter(ProcessRoute);

export default EnhancedProcessRoute;
