import { Client, Repository } from "./client";
import URI from "urijs";
import Session from "session";
import { ClientConfiguration } from "client/client";

const browserURI = URI(window.location);
const configuration: ClientConfiguration = {
    serverEndpoint: null,
};

browserURI.hasQuery("octopus.server", (octopusServerURI: any) => {
    configuration.serverEndpoint = octopusServerURI;
});

const client = Client.Create(configuration);
const repository = new Repository(client);
const session = new Session();

export { client, repository, session };
