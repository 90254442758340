import * as React from "react";
import { repository, session } from "../../clientInstance";
import { Permission } from "../../client/resources";

export interface PermissionCheckProps {
    permission: Permission | Permission[];
    children?: any;
    alternate?: any;
    project?: string;
    tenant?: string;
    environment?: string;
    projectGroup?: string;
    wildcard?: boolean;
    render?: () => JSX.Element;
}

const PermissionCheck: React.SFC<PermissionCheckProps> = ({ render, ...props }) => {
    const allowed = isAllowed(props);
    const alternate = props.alternate || null;
    const children = props.children || null;

    if (render) {
        return allowed ? render() : alternate;
    }

    const renderChildren = () => (children && typeof children === "function" ? children() : children);

    return <>{allowed ? renderChildren() : alternate}</>;
};

export function hasPermission(permission: Permission) {
    return isAllowed({ permission, wildcard: true });
}

export function isAllowed(props: PermissionCheckProps) {
    return isAllowedForSpace(repository.spaceId, props);
}

export function isAllowedForSpace(spaceId: string, props: PermissionCheckProps) {
    const pid: string = props.project || (props.wildcard ? "*" : null);
    const eid: string = props.environment || (props.wildcard ? "*" : null);
    const gid: string = props.projectGroup || (props.wildcard ? "*" : null);
    const tid: string = props.tenant || (props.wildcard ? "*" : null);

    const permissions = props.permission instanceof Array ? props.permission : [props.permission];

    const checks: boolean[] = permissions.map(p => {
        return session.currentPermissions.scopeToSpaceAndSystem(spaceId).isAuthorized({
            permission: p,
            projectId: pid,
            environmentId: eid,
            projectGroupId: gid,
            tenantId: tid,
        });
    });

    return checks.some(x => x);
}

export function firstAuthorized(permissions: Permission[]): Permission {
    return session.currentPermissions.scopeToSpaceAndSystem(repository.spaceId).firstAuthorized(permissions);
}

export default PermissionCheck;
