import * as React from "react";
import { DataTable, DataTableHeader, DataTableHeaderColumn, DataTableBody, DataTableToolsRow, DataTableRow, DataTableRowColumn } from "../DataTable";
import BaseComponent from "../BaseComponent";
const styles = require("./style.less");
import cn from "classnames";
import { getNavigationUrl } from "../PagingBaseComponent/PagingBaseComponent";
import InternalRedirect from "../Navigation/InternalRedirect/InternalRedirect";
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";

interface SimpleDataTableProps<TData> {
    onToolsSection?: any;
    headerColumns: any[];
    headerColumnClassNames?: string[];
    rowColumnClassName?: string;
    tableClassName?: string;
    data: TData[];
    onEmpty?: React.ReactNode;
    match?: any;
    onRow(item: TData): React.ReactNode[];
    onRowRedirectUrl?(item: TData): string;
}

interface SimpleDataTableState {
    redirectTo?: string;
}

export default abstract class SimpleDataTable<TData> extends BaseComponent<SimpleDataTableProps<TData>, SimpleDataTableState> {
    constructor(props: SimpleDataTableProps<TData>) {
        super(props);
        this.state = {};
    }

    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} push={true} />;
        }

        const { onToolsSection, data, headerColumns, headerColumnClassNames, rowColumnClassName, onRow, onEmpty } = this.props;

        if (!data) {
            return null;
        }

        return (
            <TransitionAnimation>
                <DataTable>
                    <DataTableHeader>
                        {onToolsSection ? (
                            <DataTableToolsRow>
                                <DataTableHeaderColumn colSpan={headerColumns.length}>{onToolsSection()}</DataTableHeaderColumn>
                            </DataTableToolsRow>
                        ) : null}
                        {data.length > 0 ? this.buildTableHeader(headerColumns, headerColumnClassNames) : null}
                    </DataTableHeader>
                    <DataTableBody>
                        {data.length > 0 ? (
                            data.map((item, index) => {
                                return this.buildTableRow(item, index, onRow(item), rowColumnClassName);
                            })
                        ) : (
                            <DataTableRow displayNoBorder={true}>
                                <DataTableRowColumn>{onEmpty}</DataTableRowColumn>
                            </DataTableRow>
                        )}
                    </DataTableBody>
                </DataTable>
            </TransitionAnimation>
        );
    }

    private buildTableHeader(headerColumns: any[], columnClassNames: string[]) {
        return (
            <DataTableRow>
                {headerColumns.map((col, index) => {
                    const props: any = {
                        key: index,
                    };

                    if (columnClassNames) {
                        const className = columnClassNames[index];
                        props["className"] = className;
                    }

                    return <DataTableHeaderColumn {...props}>{col}</DataTableHeaderColumn>;
                })}
            </DataTableRow>
        );
    }

    private buildTableRow(item: TData, index: number, rowColumns: any, className: string) {
        // Only some tables include a redirect, so only show cursor if necessary.
        const redirectUrl = getNavigationUrl(this.props, item);
        const linkClassName = redirectUrl ? styles.linkableItem : null;
        return (
            <DataTableRow key={index} onClick={(e: any) => this.navigate(item)}>
                {rowColumns.map((col: any, idx: number) => {
                    const props: any = {
                        key: idx,
                    };
                    if (className) {
                        props["className"] = cn(className, props["className"]);
                    }
                    if (linkClassName) {
                        props["className"] = cn(linkClassName, props["className"]);
                    }
                    return <DataTableRowColumn {...props}>{col}</DataTableRowColumn>;
                })}
            </DataTableRow>
        );
    }

    private navigate(item: TData) {
        const redirectTo = getNavigationUrl(this.props, item);
        if (!redirectTo) {
            return;
        }
        this.setState({ redirectTo });
    }
}
