import { BaseComponent } from "components/BaseComponent/BaseComponent";
import pluginRegistry, { ActionEditProps } from "components/Actions/pluginRegistry";
import { KubernetesRawYamlProperties } from "components/Actions/kubernetes/kubernetesProperties";
import * as React from "react";
import { ActionExecutionLocation, GetPrimaryPackageReference, InitialisePrimaryPackageReference, RemovePrimaryPackageReference, SetPrimaryPackageReference } from "client/resources";
import { TargetRoles } from "areas/projects/components/DeploymentProcess/ActionDetails";
import { ActionSummaryProps } from "components/Actions/actionSummaryProps";
import { ScriptPackageProperties } from "components/Actions/script/ScriptPackageReferenceDialog";
import { default as CodeEditor, TextFormat } from "../../CodeEditor/CodeEditor";
import { Note, Summary } from "../../form";
import ExpandableFormSection, { CardFill } from "../../form/Sections/ExpandableFormSection";
import FeedResource from "../../../client/resources/feedResource";
import PackageSelector from "components/PackageSelector/PackageSelector";
import RadioButton from "../../form/RadioButton/RadioButton";
import { repository } from "../../../clientInstance";
import RadioButtonGroup from "../../form/RadioButton/RadioButtonGroup";
import OpenDialogButton from "../../Dialog/OpenDialogButton";
import SourceCodeDialog from "../../SourceCodeDialog/sourceCodeDialog";
import CommonSummaryHelper from "../../../utils/CommonSummaryHelper";
import { VariableLookupText } from "../../form/VariableLookupText";
import KubernetesNamespaceFormSection from "./kubernetesNamespaceFormSection";

interface KubernetesDeployRawYamlActionEditState {
    feeds: FeedResource[];
}

class KubernetesDeployRawYamlActionSummary extends BaseComponent<ActionSummaryProps, never> {
    constructor(props: any) {
        super(props);
    }

    render() {
        return <div>Deploy raw YAML resources to Kubernetes</div>;
    }
}

class KubernetesDeployRawYamlActionEdit extends BaseComponent<ActionEditProps<KubernetesRawYamlProperties, ScriptPackageProperties>, KubernetesDeployRawYamlActionEditState> {
    constructor(props: any) {
        super(props);

        this.state = {
            feeds: [],
        };
    }

    async componentDidMount() {
        await this.props.doBusyTask(async () => {
            this.setState({ feeds: await repository.Feeds.all() });

            if (!this.props.properties["Octopus.Action.Script.ScriptSource"]) {
                this.props.setProperties({ ["Octopus.Action.Script.ScriptSource"]: "Inline" }, true);
            }
        });
    }

    render() {
        const pkg = GetPrimaryPackageReference(this.props.packages);

        return (
            <div>
                <ExpandableFormSection
                    errorKey="Octopus.Action.Script.ScriptSource|Octopus.Action.KubernetesContainers.CustomResourceYaml"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="YAML Source"
                    fillCardWidth={CardFill.FillRight}
                    summary={this.summaryRawYaml()}
                    help={"Select the source of the Kubernetes resources."}
                >
                    <Note>Kubernetes resources can be entered as source-code, or contained in a package.</Note>
                    <RadioButtonGroup value={this.props.properties["Octopus.Action.Script.ScriptSource"]} onChange={(val: string) => this.onChangeTemplateSource(val)} error={this.props.getFieldError("Octopus.Action.Script.ScriptSource")}>
                        <RadioButton value={"Inline"} label="Source code" />
                        <RadioButton value={"Package"} label="File inside a package" />
                    </RadioButtonGroup>
                    {this.props.properties["Octopus.Action.Script.ScriptSource"] === "Inline" && (
                        <div>
                            <br />
                            {this.props.properties["Octopus.Action.KubernetesContainers.CustomResourceYaml"] && (
                                <CodeEditor value={this.props.properties["Octopus.Action.KubernetesContainers.CustomResourceYaml"]} language={TextFormat.YAML} onChange={null} allowFullScreen={false} readOnly={true} />
                            )}
                            <div>
                                <OpenDialogButton
                                    label={this.props.properties["Octopus.Action.KubernetesContainers.CustomResourceYaml"] ? "Edit Source Code" : "Add Source Code"}
                                    wideDialog={true}
                                    renderDialog={openProps => (
                                        <SourceCodeDialog
                                            open={openProps.open}
                                            close={openProps.closeDialog}
                                            template={this.props.properties["Octopus.Action.KubernetesContainers.CustomResourceYaml"]}
                                            doBusyTask={this.props.doBusyTask}
                                            saveDone={template => {
                                                this.props.setProperties({ ["Octopus.Action.KubernetesContainers.CustomResourceYaml"]: template });
                                            }}
                                            textFormat={TextFormat.YAML}
                                            metadata={null}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    )}
                </ExpandableFormSection>

                {this.props.properties["Octopus.Action.Script.ScriptSource"] === "Package" && (
                    <div>
                        <ExpandableFormSection
                            errorKey="Octopus.Action.Package.FeedId|Octopus.Action.Package.PackageId"
                            isExpandedByDefault={this.props.expandedByDefault}
                            title="Package"
                            summary={CommonSummaryHelper.packageSummary(pkg, this.state.feeds)}
                            help={"Choose the package that contains the Kubernetes resource YAML."}
                        >
                            <PackageSelector
                                packageId={pkg.PackageId}
                                feedId={pkg.FeedId}
                                onPackageIdChange={packageId => this.props.setPackages(SetPrimaryPackageReference({ PackageId: packageId }, this.props.packages))}
                                onFeedIdChange={feedId => this.props.setPackages(SetPrimaryPackageReference({ FeedId: feedId }, this.props.packages))}
                                packageIdError={this.props.getFieldError("Octopus.Action.Package.PackageId")}
                                feedIdError={this.props.getFieldError("Octopus.Action.Package.FeedId")}
                                projectId={this.props.projectId}
                                feeds={this.state.feeds}
                                localNames={this.props.localNames}
                                refreshFeeds={this.loadFeeds}
                            />
                            <VariableLookupText
                                localNames={this.props.localNames}
                                projectId={this.props.projectId}
                                value={this.props.properties["Octopus.Action.KubernetesContainers.CustomResourceYamlFileName"]}
                                onChange={x => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.CustomResourceYamlFileName"]: x })}
                                label="Kubernetes YAML file name"
                                error={this.props.getFieldError("Octopus.Action.KubernetesContainers.CustomResourceYamlFileName")}
                            />
                            <Note>
                                The relative path to the YAML file that contains the Kubernetes resources.
                                <br />
                                e.g. <code>MyPod.yaml</code> or <code>Resources\MyPod.yaml</code>
                            </Note>
                        </ExpandableFormSection>
                    </div>
                )}
                <KubernetesNamespaceFormSection namespace={this.props.properties["Octopus.Action.KubernetesContainers.Namespace"]} onChange={ns => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.Namespace"]: ns })} />
            </div>
        );
    }

    private summaryRawYaml = () => {
        const source = this.props.properties["Octopus.Action.Script.ScriptSource"];
        if (source === "Inline") {
            return Summary.summary("Source code");
        }
        if (source === "Package") {
            return Summary.summary("File inside a package");
        }
        return Summary.placeholder("Resource source not specified");
    };

    private onChangeTemplateSource(value: any) {
        this.props.setProperties({
            ["Octopus.Action.Script.ScriptSource"]: value,
            ["Octopus.Action.KubernetesContainers.CustomResourceYaml"]: "",
        });

        // If the inline option is selected, we clear out the package selection
        if (value === "Inline") {
            this.props.setPackages(RemovePrimaryPackageReference(this.props.packages));
        } else {
            this.props.setPackages(InitialisePrimaryPackageReference(this.props.packages, this.state.feeds));
        }
    }

    private loadFeeds = (callback?: (feeds: FeedResource[]) => void) => {
        return this.props.doBusyTask(async () => {
            this.setState({ feeds: await repository.Feeds.all() }, () => callback && callback(this.state.feeds));
        });
    };
}

pluginRegistry.registerActionForAllScopes({
    executionLocation: ActionExecutionLocation.AlwaysOnServer,
    actionType: "Octopus.KubernetesDeployRawYaml",
    summary: (properties, targetRolesAsCSV) => <KubernetesDeployRawYamlActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV} />,
    edit: KubernetesDeployRawYamlActionEdit,
    canHaveChildren: step => true,
    canBeChild: true,
    targetRoleOption: action => TargetRoles.Required,
    hasPackages: action => false,
});
