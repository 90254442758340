import releaseRouteLinks from "../Releases/ReleasesRoutes/releaseRouteLinks";
import { ProjectResource, ProjectBaseResource } from "../../../../client/resources/projectResource";
import { DashboardProjectResource } from "../../../../client/resources/dashboardProjectResource";
import { channel, trigger, scheduledTrigger, runbook } from "../routeLinkHelpers";
import { deploymentLinks } from "../DeploymentsRoute";
import { opsRouteLinks } from "../OperationsRoute";

export default (root: string) => ({
    v3projectRoutes: {
        newProject: `${root}/projects/new`,
    },
    projects: {
        root: `${root}/projects`,
        filteredByGroup: (projectGroupId: string) => `${root}/projects?projectGroupId=${projectGroupId}`,
    },
    trigger: trigger(root),
    channel: channel(root),
    release: (releaseId: string) => `${root}/releases/${releaseId}`,
    runbookSnapshot: (runbookSnapshotId: string) => `${root}/snapshots/${runbookSnapshotId}`, // Needed for audit-screen document redirection.
    runbook: runbook(root), // Needed for audit-screen document redirection.
    project: (projectSlug: string | ProjectBaseResource | DashboardProjectResource) => {
        if (!!projectSlug && typeof projectSlug !== "string") {
            projectSlug = projectSlug.Slug;
        }
        const specificRoot = `${root}/projects/${projectSlug}`;
        return {
            root: specificRoot,
            ...releaseRouteLinks(`${specificRoot}/releases`),
            overview: `${specificRoot}/overview`,
            settings: `${specificRoot}/settings`,
            tasks: `${specificRoot}/tasks`,
            channels: `${specificRoot}/channels`,
            channel: channel(`${specificRoot}/channels`),
            operations: opsRouteLinks(`${specificRoot}/operations`),
            triggers: `${specificRoot}/triggers`,
            trigger: trigger(`${specificRoot}/triggers`),
            scheduledTrigger: scheduledTrigger(`${specificRoot}/triggers`),
            scheduledTriggers: {
                root: `${specificRoot}/triggers`,
                new: `${specificRoot}/triggers/scheduled/create`,
            },
            variables: {
                root: `${specificRoot}/variables`,
                projectTemplates: `${specificRoot}/variables/projectvariabletemplates`,
                commonTemplates: `${specificRoot}/variables/commonvariabletemplates`,
                library: `${specificRoot}/variables/library`,
                preview: `${specificRoot}/variables/preview`,
                all: `${specificRoot}/variables/all`,
            },
            deployments: deploymentLinks(`${specificRoot}/deployments`),
            process: {
                root: `${specificRoot}/process`,
                stepTemplates: `${specificRoot}/process/steptemplates`,
                childStepTemplates: (parentStepId: string) => ({
                    root: `${specificRoot}/process/childsteptemplates/${parentStepId}`,
                }),
                stepNew: (templateType: string, parentStepId?: string, templateId?: string) => {
                    let base = `${specificRoot}/process/step`;
                    if (parentStepId) {
                        base = `${base}/${parentStepId}`;
                    }
                    base = `${base}/new/${templateType}`;
                    if (templateId) {
                        base = `${base}/${templateId}`;
                    }
                    return base;
                },
                step: (actionId: string) => `${specificRoot}/process/step/${actionId}`,
            },
            childStepTemplates: (parentStepId: string) => ({
                root: `${specificRoot}/childsteptemplates/${parentStepId}`,
            }),
            steptemplates: `${specificRoot}/steptemplates`,
        };
    },
});
