import * as React from "react";
import { Switch, RouteComponentProps, withRouter } from "react-router-dom";
import RunbookRoute, { OperationsRunbooksPage, OperationsOverviewPage } from "../Runbooks/RunbookRoute";
import ReloadableRoute from "components/ReloadableRoute/ReloadableRoute";
import { opsRouteLinks } from "./opsRouteLinks";
import { RedirectAs404 } from "components/NotFound/NotFound";
import InternalRedirect from "components/Navigation/InternalRedirect/InternalRedirect";
import { generatePath } from "react-router";
import { TriggersRoute } from "../Runbooks/Triggers/TriggersRoute";

type OperationsRouteProps = { path: string } & RouteComponentProps<any>;

class OperationsRoute extends React.Component<OperationsRouteProps, any> {
    render() {
        const links = opsRouteLinks(this.props.path);

        return (
            <ReloadableRoute path={links.root}>
                <Switch>
                    <ReloadableRoute path={links.root} exact={true} component={OperationsOverviewPage} />
                    <ReloadableRoute path={links.runbooks} exact={true} component={OperationsRunbooksPage} />
                    <ReloadableRoute path={links.triggers} component={TriggersRoute} />
                    <RunbookRoute path={links.runbook(":runbookId").root} />
                    <ReloadableRoute path={links.runbooks} render={(props: RouteComponentProps<any>) => <InternalRedirect to={generatePath(links.runbooks, props.match.params)} />} />
                    <RedirectAs404 />
                </Switch>
            </ReloadableRoute>
        );
    }
}

const EnhancedOperationsRoute = withRouter(OperationsRoute);

export default EnhancedOperationsRoute;
