import * as React from "react";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import Popover from "components/Popover/Popover";
import FilterSearchBox from "components/FilterSearchBox";
import { repository } from "clientInstance";
import { keyBy, sortBy, difference } from "lodash";
const styles = require("./projectSwitcher.less");
import { Section } from "components/Section/Section";
import { ProjectGroupResource, Permission, ProjectResource } from "client/resources";
import { isAllowed } from "components/PermissionCheck/PermissionCheck";
import VirtualListWithKeyboard from "components/VirtualListWithKeyboard/VirtualListWithKeyboard";
import { FocusableComponent } from "../VirtualListWithKeyboard/FocusableComponent";
import Loading from "components/Loading/Loading";
import BusyIndicator from "components/BusyIndicator";
import DebounceValue from "components/DebounceValue/DebounceValue";
import SidebarLayout, { SidebarSide } from "components/SidebarLayout/SidebarLayout";
import { FormSectionHeading } from "components/form";
import IconButton from "components/IconButton";
import { Icon } from "components/IconButton/IconButton";
import _ = require("lodash");
import { RecentProjects } from "utils/RecentProjects/RecentProjects";
import { IRecentSpaceProjects, IRecentSpaceProjectsProject } from "utils/RecentProjects/IRecentProjects";
import moment = require("moment");

enum RefreshType {
    All = "All",
    RecentList = "RecentList",
    ProjectList = "ProjectList",
}

interface Project {
    Id: string;
    Name: string;
    Slug: string;
    Group: string;
}

interface ProjectSwitcherProps {
    open: boolean;
    onRequestClose: (projectId?: string, event?: React.MouseEvent<{}, MouseEvent>) => void;
    anchorEl: HTMLElement;
    filter: string;
    onFilterChange: (value: string) => void;
}

interface ProjectSwitcherState extends DataBaseComponentState {
    isLoadingProjectList: boolean;
    isLoadingRecentList: boolean;
    localStorageRecentProjects: string[];
    recentProjects: Project[];
    placeholderProjects: Project[];
    projects: Project[];
    totalProjects: number;
}

const VirtualList = VirtualListWithKeyboard<Project>();
const DebounceFilterSearchBox = DebounceValue(FilterSearchBox);

/**
 * Builds the list of recent projects. Sorts the ouput according to the
 * ordering of the recentProjectIds parameter. Handles the case where
 * an id exists in recentProjectIds but not the allProjectsInSpace (e.g.
 * when a user visited a project recently but no longer has access to it).
 * @param recentProjectIds
 * @param allProjectsInSpace
 */
function buildRecentProjectsList(recentProjectIds: string[], allProjectsInSpace: ProjectResource[]): ProjectResource[] {
    return _.compact(recentProjectIds.map(projectId => allProjectsInSpace.find(p => p.Id === projectId)));
}

export class ProjectSwitcher extends DataBaseComponent<ProjectSwitcherProps, ProjectSwitcherState> {
    private searchRef: any;
    private virtualList: FocusableComponent;
    private updatePopoverPosition: () => void;

    constructor(props: ProjectSwitcherProps) {
        super(props);

        this.state = {
            isLoadingProjectList: false,
            isLoadingRecentList: false,
            localStorageRecentProjects: [],
            recentProjects: [],
            placeholderProjects: [],
            projects: [],
            totalProjects: 0,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(() => this.refreshDefaultProjects());
    }

    async componentDidUpdate(prevProps: ProjectSwitcherProps) {
        if (prevProps.filter !== this.props.filter) {
            if (this.props.filter === "") {
                const result = await repository.Projects.list({ take: 0 });
                this.setState({ totalProjects: result.TotalResults });
            } else {
                await this.searchForProjects(this.props.filter);
            }
        }

        if (prevProps.open !== this.props.open && this.props.open === true && !this.state.isLoadingRecentList) {
            await this.doBusyTask(() => this.refreshDefaultProjects(RefreshType.RecentList));
        }
    }

    render() {
        const defaultProjects = this.state.placeholderProjects;
        const projects = this.props.filter === "" ? defaultProjects : this.state.projects;

        return (
            <Popover
                getUpdatePosition={update => (this.updatePopoverPosition = update)}
                style={{ overflowY: "hidden" }}
                open={this.props.open}
                anchorEl={this.props.anchorEl}
                onClose={this.onRequestClose}
                anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                transformOrigin={{ horizontal: "left", vertical: "top" }}
            >
                <div className={styles.container} onKeyDown={this.onKeyEsc}>
                    <SidebarLayout
                        side={SidebarSide.Left}
                        extendSidebarToEdges={true}
                        sideBar={
                            (this.state.isLoadingRecentList && this.state.recentProjects.length === 0) || this.state.recentProjects.length === 0 ? null : (
                                <div className={styles.recentlyViewedSection}>
                                    <FormSectionHeading title={"Recently Viewed"} />
                                    {this.state.isLoadingRecentList ? (
                                        <>
                                            <BusyIndicator show={true} />
                                            <Loading title={null} busy={this.state.busy} errors={this.state.errors} flatStyle={true} />
                                        </>
                                    ) : (
                                        <VirtualList
                                            items={this.state.recentProjects}
                                            //empty={<div className={styles.empty}>{`${this.props.filter === "" ? "There are no projects yet!" : `Cannot find project/s matching "${this.props.filter}"`}`}</div>}
                                            renderItem={item => ({
                                                primaryText: item.Name,
                                                secondaryText: item.Group,
                                            })}
                                            onSelected={this.props.onRequestClose}
                                            onResized={() => {
                                                // When the content's size changes, we re-render so that the
                                                // popover can re-position itself based on the new `VirtualList` size
                                                if (this.updatePopoverPosition) {
                                                    this.updatePopoverPosition();
                                                }
                                            }}
                                            onBlur={() => this.searchRef.focus()}
                                        />
                                    )}
                                </div>
                            )
                        }
                    >
                        <div className={styles.allProjectsList}>
                            <Section bodyClassName={styles.filterContainer}>
                                <DebounceFilterSearchBox
                                    debounceDelay={1000}
                                    innerRef={this.setSearchRef}
                                    autoFocus={true}
                                    value={this.props.filter}
                                    hintText="Search for project..."
                                    onChange={this.props.onFilterChange}
                                    fullWidth={true}
                                    onKeyDown={this.onArrowDown}
                                    containerClassName={styles.filterFieldContainer}
                                />
                                <IconButton
                                    onClick={async () => {
                                        await this.doBusyTask(() => this.refreshDefaultProjects(RefreshType.All));
                                    }}
                                    toolTipContent="Refresh"
                                    icon={Icon.Refresh}
                                />
                            </Section>

                            {this.state.isLoadingProjectList ? (
                                <>
                                    <BusyIndicator show={true} />
                                    <Loading title={`${this.props.filter === "" && defaultProjects.length === 0 ? "Loading" : "Searching for"} projects...`} busy={this.state.busy} errors={this.state.errors} flatStyle={true} />
                                </>
                            ) : (
                                <>
                                    {projects.length > 0 ? (
                                        <div className={styles.warning}>
                                            Showing {projects.length} out of {this.state.totalProjects} project{projects.length === 1 ? "" : "s"}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    <div className={styles.menuContainer}>
                                        <VirtualList
                                            multiSelectRef={el => (this.virtualList = el)}
                                            items={projects}
                                            empty={<div className={styles.empty}>{`${this.props.filter === "" ? "There are no projects yet!" : `Cannot find project/s matching "${this.props.filter}"`}`}</div>}
                                            renderItem={item => ({
                                                primaryText: item.Name,
                                                secondaryText: item.Group,
                                            })}
                                            onSelected={this.props.onRequestClose}
                                            onResized={() => {
                                                // When the content's size changes, we re-render so that the
                                                // popover can re-position itself based on the new `VirtualList` size
                                                if (this.updatePopoverPosition) {
                                                    this.updatePopoverPosition();
                                                }
                                            }}
                                            onBlur={() => this.searchRef.focus()}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    </SidebarLayout>
                </div>
            </Popover>
        );
    }

    private refreshDefaultProjects = async (refreshType: RefreshType = RefreshType.All) => {
        let isLoadingRecentList = false;
        let isLoadingProjectList = false;
        switch (refreshType) {
            case RefreshType.RecentList:
                isLoadingRecentList = true;
                break;
            case RefreshType.ProjectList:
                isLoadingProjectList = true;
                break;
            case RefreshType.All:
                isLoadingRecentList = true;
                isLoadingProjectList = true;
                break;
            default:
                throw new Error(`Unsupported RefreshType ${refreshType}`);
        }

        this.setState({ isLoadingProjectList, isLoadingRecentList }, async () => {
            let newTotalProjects = this.state.totalProjects;
            let recentlyViewedProjects: Project[] = this.state.recentProjects;
            let placeholderProjects = this.state.placeholderProjects;

            if (refreshType === RefreshType.RecentList || refreshType === RefreshType.All) {
                recentlyViewedProjects = await this.fetchRecentProjectsFromLocalStorage();
            }

            if (refreshType === RefreshType.ProjectList || refreshType === RefreshType.All) {
                const { projects, totalProjects } = await this.fetchProjects({ take: 10 });
                newTotalProjects = totalProjects;
                placeholderProjects = projects;
            }

            this.setState({ recentProjects: recentlyViewedProjects, placeholderProjects, isLoadingProjectList: false, isLoadingRecentList: false, ...(newTotalProjects !== this.state.totalProjects ? { totalProjects: newTotalProjects } : null) });
        });
    };

    // based on filter and take, fetches projects and sets them to state
    private searchForProjects = async (filter?: string, take?: number) => {
        this.setState({ isLoadingProjectList: true }, async () => {
            const { projects, totalProjects } = await this.fetchProjects({ filter, take });
            this.setState({ projects, totalProjects, isLoadingProjectList: false });
        });
    };

    private async fetchRecentProjectsFromLocalStorage(): Promise<Project[]> {
        const recentProjects = RecentProjects.getInstance().GetRecentProjectListInSpace();

        const recentProjectIds: string[] = recentProjects.Projects.map(x => {
            return x.ProjectId;
        });

        if (recentProjectIds.length === 0) {
            //Don't bother doing API requests ect. if there are none, just return empty
            return [];
        }

        let projects: Project[] = [];
        if (difference(recentProjectIds, this.state.localStorageRecentProjects).length > 0) {
            let args = recentProjectIds.length > 0 ? { ids: recentProjectIds } : undefined;
            const projectResources = await repository.Projects.all(args);

            const projectGroupIds: string[] = projectResources.map(x => {
                return x.ProjectGroupId;
            });

            args = projectGroupIds.length > 0 ? { ids: projectGroupIds } : undefined;
            const projectGroups = await repository.ProjectGroups.all(args);

            projects = recentProjects.Projects.map(recentProject => {
                const projectResource = projectResources.find(x => x.Id === recentProject.ProjectId);

                const project: Project = {
                    Group: projectGroups.find(projectGroup => projectGroup.Id === projectResource.ProjectGroupId).Name,
                    Id: projectResource.Id,
                    Name: projectResource.Name,
                    Slug: projectResource.Slug,
                };

                return project;
            });

            this.setState({
                localStorageRecentProjects: recentProjectIds,
                recentProjects: projects,
            });
        } else {
            projects = this.state.recentProjects;
        }

        projects
            .sort((a, b) => {
                const recentProjectA = recentProjects.Projects.find(x => x.ProjectId === a.Id);
                const recentProjectB = recentProjects.Projects.find(x => x.ProjectId === b.Id);
                return RecentProjects.SortByScoreThenTime(recentProjectA.Score, recentProjectB.Score, recentProjectA.Timestamps, recentProjectB.Timestamps);
            })
            .reverse();

        return projects;
    }

    // fetch projects based on filter and take, returns an object with projects and total number of projects available
    private fetchProjects = async ({ filter, take }: { filter?: string; take?: number }): Promise<{ projects: Project[]; totalProjects: number }> => {
        const matchedProjects = await repository.Projects.list({ take: take || 10, ...(filter ? { partialName: filter } : {}) });

        const projectResources = matchedProjects.Items;
        const sortedProjects = sortBy(projectResources, p => p.Name.toLowerCase());

        const projectGroupMap = await this.getProjectGroup();

        const projects = sortedProjects.map(p => ({
            Id: p.Id,
            Name: p.Name,
            Slug: p.Slug,
            Group: projectGroupMap ? this.getProjectGroupName(p.ProjectGroupId, projectGroupMap) : null,
        }));

        return { projects, totalProjects: matchedProjects.TotalResults };
    };

    // Gets all of the project groups
    private getProjectGroup = async () => {
        let projectGroupMap: { [id: string]: ProjectGroupResource } = null;
        if (isAllowed({ permission: Permission.ProjectGroupView, projectGroup: "*" })) {
            const groups = await repository.ProjectGroups.all();

            if (groups.length > 1) {
                projectGroupMap = keyBy(groups, "Id");
            }
        }
        return projectGroupMap;
    };

    private getProjectGroupName = (gId: string, projectGroupMap: { [id: string]: ProjectGroupResource }) => {
        return projectGroupMap.hasOwnProperty(gId) ? projectGroupMap[gId].Name : null;
    };

    private setSearchRef = (el: any) => {
        this.searchRef = el;
    };

    private onRequestClose = () => {
        this.props.onRequestClose();
    };

    private filterList(filter: string): Project[] {
        const matchesFilter = (n: string) => n.toLowerCase().includes(filter.toLowerCase());

        return filter.length > 0 ? this.state.projects.filter(p => matchesFilter(p.Name) || (p.Group ? matchesFilter(p.Group) : false)) : this.state.projects;
    }

    private onKeyEsc = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "Escape") {
            this.props.onRequestClose();
        }
    };

    private onArrowDown = (event: KeyboardEvent) => {
        if (event.key === "ArrowDown" || event.key === "Tab") {
            if (this.filterList(this.props.filter).length === 0) {
                return;
            }

            this.virtualList.focus();
            event.preventDefault();
        }
    };
}

export default ProjectSwitcher;
export { buildRecentProjectsList };
