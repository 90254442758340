import * as React from "react";
import { EnvironmentResource, VariablesScopedToEnvironmentResponse } from "client/resources";
import { repository } from "clientInstance";
import { RouteComponentProps } from "react-router";
import FormPaperLayout from "components/FormPaperLayout/FormPaperLayout";
import FormBaseComponent, { OptionalFormBaseComponentState } from "components/FormBaseComponent";
import { cloneDeep, isEmpty, flatten, map, reduce, slice, take, Dictionary } from "lodash";
import Markdown from "components/Markdown";
import { Text, ExpandableFormSection, Summary, required, Checkbox, FormSectionHeading } from "components/form";
import MarkdownEditor from "components/form/MarkdownEditor/MarkdownEditor";
import OverflowMenu, { OverflowMenuItems } from "components/Menu/OverflowMenu";
import InfrastructureLayout from "../InfrastructureLayout";
import { Callout, CalloutType } from "components/Callout/Callout";
import Permission from "client/resources/permission";
import StringHelper from "utils/StringHelper";
import routeLinks from "../../../../routeLinks";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import { PermissionCheck } from "components/PermissionCheck";
import { NavigationButton } from "components/Button";
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";
import DynamicForm from "components/DynamicForm/DynamicForm";
import { EnvironmentSettingsMetadata, ExtensionSettingsValues } from "client/resources/extensionSettingsValues";

interface EnvironmentRouteParams {
    environmentId: string;
}

interface EnvironmentProps extends RouteComponentProps<EnvironmentRouteParams> {
    create?: boolean;
}

interface EnvironmentState extends OptionalFormBaseComponentState<EnvironmentResource> {
    deleted: boolean;
    newId: string;
    variablesScopedToThisEnvironment?: VariablesScopedToEnvironmentResponse;
    metadata: EnvironmentSettingsMetadata[];
}

class EnvironmentLayout extends FormBaseComponent<EnvironmentProps, EnvironmentState, EnvironmentResource> {
    constructor(props: EnvironmentProps) {
        super(props);
        this.state = {
            deleted: false,
            newId: null,
            metadata: null,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const environmentPromise = repository.Environments.get(this.props.match.params.environmentId);
            const environment = await environmentPromise;
            const metadataPromise: Promise<EnvironmentSettingsMetadata[]> = repository.Environments.getMetadata(environment);

            this.setState({
                model: environment,
                cleanModel: cloneDeep(environment),
                variablesScopedToThisEnvironment: !this.props.create ? await repository.Environments.variablesScopedOnlyToThisEnvironment(environment) : null,
                metadata: await metadataPromise,
            });
        });
    }

    descriptionSummary() {
        return this.state.model.Description ? Summary.summary(<Markdown markup={this.state.model.Description} />) : Summary.placeholder("No description provided");
    }

    render() {
        const title = this.props.create ? "Create environment" : this.state.model ? this.state.model.Name : StringHelper.ellipsis;

        const overFlowActions = [];
        if (!this.props.create && !!this.state.model) {
            // Check if we need to provide additional warnings about variables scoped to this environment.
            let warningMessage = null;
            let errorMessage = null;
            const variablesResponse = this.state.variablesScopedToThisEnvironment;
            if (variablesResponse) {
                if (isEmpty(variablesResponse.VariableMap) && !variablesResponse.HasUnauthorizedProjectVariables && !variablesResponse.HasUnauthorizedLibraryVariableSetVariables) {
                    warningMessage = null;
                } else {
                    const allWarnings = [];
                    let additionalWarnings = [];
                    const allWarningValues = map(variablesResponse.VariableMap, (dic, key) => {
                        const area = key; // eg. Project
                        const mapDict = dic as Dictionary<number>;
                        return Object.keys(mapDict).map(mapDictKey => {
                            const value = mapDict[mapDictKey];
                            const variableSuffix = value > 1 ? "s" : "";
                            return (
                                <div key={mapDictKey}>
                                    {value} variable{variableSuffix} in the{" "}
                                    <strong>
                                        <em>{mapDictKey}</em>
                                    </strong>{" "}
                                    {area.toLowerCase()}
                                </div>
                            );
                        });
                    });
                    allWarnings.push(flatten(allWarningValues));

                    const allWarningLines = flatten(reduce(allWarnings, (memo, warning) => warning, []));
                    const shownWarnings = take(allWarningLines, 5);
                    if (allWarnings[0].length > 5) {
                        additionalWarnings = slice(allWarningLines, 5, allWarningLines.length - 5);
                    }
                    warningMessage =
                        shownWarnings.length > 0 ? (
                            <Callout type={CalloutType.Warning} title="Warning">
                                <p>
                                    Deleting this environment will also delete <strong>ALL</strong> the variables that are scoped only to this environment in the following locations:
                                </p>
                                <ul>
                                    {shownWarnings.map((x, i) => (
                                        <li key={i}>{x}</li>
                                    ))}
                                </ul>
                            </Callout>
                        ) : null;

                    const errors: string[] = [];
                    if (variablesResponse.HasUnauthorizedProjectVariables || variablesResponse.HasUnauthorizedLibraryVariableSetVariables) {
                        if (variablesResponse.HasUnauthorizedProjectVariables) {
                            errors.push("Variables exist in projects for which you do not have edit permissions");
                        }
                        if (variablesResponse.HasUnauthorizedLibraryVariableSetVariables) {
                            errors.push("Variables exist in library variable sets for which you do not have edit permissions");
                        }
                    }
                    errorMessage =
                        errors.length > 0 ? (
                            <Callout type={CalloutType.Danger} title="Errors">
                                <ul>
                                    {errors.map((x, i) => (
                                        <li key={i}>{x}</li>
                                    ))}
                                </ul>
                            </Callout>
                        ) : null;
                }
            }

            overFlowActions.push(
                OverflowMenuItems.deleteItem(
                    "Delete",
                    "Are you sure you want to delete this environment?",
                    this.handleDeleteConfirm,
                    <div>
                        {warningMessage}
                        {errorMessage}
                        <p>Deleting this environment is permanent, there is no going back.</p>
                        <p>Do you wish to continue?</p>
                    </div>,
                    { permission: Permission.EnvironmentDelete, environment: "*" }
                ),
                [
                    OverflowMenuItems.navItem("Audit Trail", routeLinks.configuration.eventsForEnvironment(this.state.model.Id), null, {
                        permission: Permission.EventView,
                        wildcard: true,
                    }),
                ]
            );
        }

        const saveText: string = this.state.newId ? "Environment created" : "Environment details updated";

        const extensionSettings =
            this.state.model &&
            this.state.metadata &&
            this.state.metadata.length > 0 &&
            this.state.metadata.map(m => {
                let valuesForExtension = this.state.model.ExtensionSettings.find(e => e.ExtensionId === m.ExtensionId);
                if (!valuesForExtension || !valuesForExtension.Values) {
                    valuesForExtension = {
                        ExtensionId: m.ExtensionId,
                        Values: {},
                    };

                    this.state.model.ExtensionSettings.push(valuesForExtension);
                }

                return (
                    <div>
                        <FormSectionHeading title={m.Metadata.Description} />
                        <DynamicForm
                            types={m.Metadata.Types}
                            values={valuesForExtension.Values}
                            onChange={c => {
                                this.setState({
                                    model: this.state.model,
                                });
                            }}
                        />
                    </div>
                );
            });

        return (
            <InfrastructureLayout {...this.props}>
                <FormPaperLayout
                    title={title}
                    breadcrumbTitle={"Environments"}
                    breadcrumbPath={routeLinks.infrastructure.environments.root}
                    busy={this.state.busy}
                    errors={this.state.errors}
                    model={this.state.model}
                    cleanModel={this.state.cleanModel}
                    savePermission={{ permission: this.props.create ? Permission.EnvironmentCreate : Permission.EnvironmentEdit, environment: "*" }}
                    onSaveClick={this.handleSaveClick}
                    saveText={saveText}
                    expandAllOnMount={this.props.create}
                    overFlowActions={overFlowActions}
                    secondaryAction={this.addEnvironmentButton()}
                >
                    {this.state.deleted && <InternalRedirect to={routeLinks.infrastructure.environments.root} />}
                    {this.state.newId && <InternalRedirect to={routeLinks.infrastructure.environment(this.state.newId)} />}
                    {this.state.model && (
                        <TransitionAnimation>
                            <ExpandableFormSection
                                errorKey="Name"
                                title="Name"
                                focusOnExpandAll
                                summary={this.state.model.Name ? Summary.summary(this.state.model.Name) : Summary.placeholder("Please enter a name for your environment")}
                                help="A short, memorable, unique name for this environment. Example: Development."
                            >
                                <Text value={this.state.model.Name} onChange={Name => this.setModelState({ Name })} label="Name" validate={required("Please enter a environment name")} autoFocus={true} />
                            </ExpandableFormSection>

                            <ExpandableFormSection errorKey="description" title="Description" summary={this.descriptionSummary()} help="Enter a description for your environment.">
                                <MarkdownEditor value={this.state.model.Description} label="Environment description" onChange={Description => this.setModelState({ Description })} />
                            </ExpandableFormSection>

                            <ExpandableFormSection
                                errorKey="useGuidedFailure"
                                title="Default Guided Failure Mode"
                                summary={this.state.model.UseGuidedFailure ? Summary.summary("Yes") : Summary.default("No")}
                                help="Select whether guided failure mode if on by default or not."
                            >
                                <div>
                                    <Checkbox
                                        value={this.state.model.UseGuidedFailure}
                                        label="Use guided failure mode by default"
                                        onChange={UseGuidedFailure => this.setModelState({ UseGuidedFailure })}
                                        note={<span>If guided failure is enabled for an environment, Octopus Deploy will prompt for user intervention if a deployment fails in the environment.</span>}
                                    />
                                </div>
                            </ExpandableFormSection>
                            <ExpandableFormSection
                                errorKey="dynamicInfrastructure"
                                title="Dynamic Infrastructure"
                                summary={this.state.model.AllowDynamicInfrastructure ? Summary.summary("Yes") : Summary.default("No")}
                                help="Select whether dynamic infrastructure is allowed in this environment"
                            >
                                <div>
                                    <Checkbox
                                        value={this.state.model.AllowDynamicInfrastructure}
                                        label="Allow managing dynamic infrastructure"
                                        onChange={AllowDynamicInfrastructure => this.setModelState({ AllowDynamicInfrastructure })}
                                        note={<span>If dynamic infrastructure is enabled for an environment, deployments to this environment are allowed to create infrastructure, such as targets and accounts.</span>}
                                    />
                                </div>
                            </ExpandableFormSection>
                            {extensionSettings}
                        </TransitionAnimation>
                    )}
                </FormPaperLayout>
            </InfrastructureLayout>
        );
    }

    private handleSaveClick = async () => {
        await this.doBusyTask(async () => {
            const isNew = this.state.model.Id == null;
            const result = await repository.Environments.save(this.state.model);
            this.setState({
                model: result,
                cleanModel: cloneDeep(result),
                newId: isNew ? result.Id : null,
            });
        });
    };

    private handleDeleteConfirm = async () => {
        const result = await repository.Environments.del(this.state.model);
        this.setState(state => {
            return {
                model: null,
                cleanModel: null, //reset model so that dirty state doesn't prevent navigation
                deleted: true,
            };
        });
        return true;
    };

    private addEnvironmentButton() {
        return (
            <PermissionCheck permission={Permission.MachineCreate} environment="*" tenant="*">
                <NavigationButton href={routeLinks.infrastructure.machines.new(this.state.model ? this.state.model.Id : null)} label="Add deployment target" />
            </PermissionCheck>
        );
    }
}

export default EnvironmentLayout;
