import ResourceWithId from "./resource";
import { ReleasePackageVersionBuildInformationResource } from "./packageResource";
import { RunbookSnapshotResource } from "./runbookSnapshotResource";
import { typeSafeHasOwnProperty } from "../utils";

export interface SelectedPackage {
    ActionName: string;
    PackageReferenceName?: string;
    Version: string;
}

export interface ReleaseResource extends ISnapshotResource {
    ChannelId: string;
    Version: string;
    ReleaseNotes: string;
    IgnoreChannelRules: boolean;
    ProjectDeploymentProcessSnapshotId: string;
    BuildInformation: ReleasePackageVersionBuildInformationResource[];
}

export interface ISnapshotResource extends ResourceWithId {
    Assembled: string;
    ProjectId: string;
    ProjectVariableSetSnapshotId: string;
    LibraryVariableSetSnapshotIds: string[];
    SelectedPackages: SelectedPackage[];
}

export function isReleaseResource(resource: ISnapshotResource): resource is NonNullable<ReleaseResource> {
    if (resource === undefined || resource === null) {
        return false;
    }

    const converted = resource as ReleaseResource;
    return converted.Version !== undefined && typeSafeHasOwnProperty(converted, "Version");
}

export function isRunbookSnapshotResource(resource: ISnapshotResource): resource is NonNullable<RunbookSnapshotResource> {
    if (resource === undefined || resource === null) {
        return false;
    }

    const converted = resource as RunbookSnapshotResource;
    return converted.Name !== undefined && typeSafeHasOwnProperty(converted, "Name");
}

export default ReleaseResource;
