import * as React from "react";
import * as _ from "lodash";
import { repository } from "clientInstance";
import { ProjectResource, RunbookSnapshotResource, ResourceCollection, RunbookResource } from "client/resources";
import List from "components/List";
import PaperLayout from "components/PaperLayout/index";
import { RouteComponentProps } from "react-router";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import { ProjectRouteParams } from "../../ProjectLayout";
import ListTitle from "components/ListTitle";
import routeLinks from "routeLinks";
import { WithProjectContextInjectedProps, withProjectContext } from "areas/projects/context";
import { withRunbookContext, WithRunbookContextInjectedProps } from "../RunbookContext";
import { isEqual } from "lodash";
import { RunbookSnapshotPublishedChip } from "components/Chips";

class RunbookSnapshotsList extends List<RunbookSnapshotResource> {}

export interface RunbookRouteProps {
    runbookId: string;
}

interface RunbookSnapshotsState extends DataBaseComponentState {
    runbookSnapshotsResponse: ResourceCollection<RunbookSnapshotResource>;
}

type RunbookSnapshotsProps = RouteComponentProps<ProjectRouteParams & RunbookRouteProps> & WithRunbookContextInjectedProps & WithProjectContextInjectedProps;

class RunbookSnapshotsInternal extends DataBaseComponent<RunbookSnapshotsProps, RunbookSnapshotsState> {
    private match: any = null;

    constructor(props: RunbookSnapshotsProps) {
        super(props);
        this.match = this.props.match;
        this.state = {
            runbookSnapshotsResponse: null,
        };
    }

    async componentDidMount() {
        await this.reload();
    }

    async componentDidUpdate(nextProps: RunbookSnapshotsProps) {
        const currentRunbook = this.props.runbookContext.state && this.props.runbookContext.state.runbook;
        const nextRunbook = nextProps.runbookContext.state && nextProps.runbookContext.state.runbook;
        if (!isEqual(currentRunbook, nextRunbook)) {
            await this.reload();
        }
    }

    async reload() {
        const runbook = this.props.runbookContext.state && this.props.runbookContext.state.runbook;
        if (!runbook) {
            return;
        }

        await this.doBusyTask(async () => {
            const runbookSnapshotsResponse = await repository.Runbooks.getRunbookSnapshots(runbook, { skip: 0, take: 30 });

            this.setState({
                runbookSnapshotsResponse,
            });
        });
    }

    render() {
        const project = this.props.projectContext.state && this.props.projectContext.state.model;
        const runbook = this.props.runbookContext.state && this.props.runbookContext.state.runbook;

        if (!project || !runbook) {
            return <PaperLayout busy={true} errors={this.state.errors} />;
        }

        return (
            <PaperLayout
                busy={this.state.busy}
                errors={this.state.errors}
                title="Snapshots"
                breadcrumbTitle={runbook && runbook.Name}
                breadcrumbPath={runbook && routeLinks.project(this.props.match.params.projectSlug).operations.runbook(runbook.Id).root}
            >
                {this.state.runbookSnapshotsResponse && (
                    <RunbookSnapshotsList
                        initialData={this.state.runbookSnapshotsResponse}
                        onRow={(item: any) => this.buildRow(runbook, item)}
                        match={this.match}
                        onRowRedirectUrl={(runbookSnapshot: RunbookSnapshotResource) => `${this.match.url}/${runbookSnapshot.Id}`}
                        onFilter={this.filter}
                        filterSearchEnabled={true}
                        apiSearchParams={["partialName"]}
                        filterHintText="Filter by name..."
                    />
                )}
            </PaperLayout>
        );
    }

    private filter(filter: string, resource: RunbookSnapshotResource) {
        return !filter || filter.length === 0 || !resource || resource.Name.toLowerCase().includes(filter.toLowerCase());
    }

    private buildRow(runbook: RunbookResource, runbookSnapshot: RunbookSnapshotResource) {
        return (
            <ListTitle>
                {runbookSnapshot.Name}
                {runbook && runbook.PublishedRunbookSnapshotId === runbookSnapshot.Id && (
                    <>
                        &nbsp;
                        <RunbookSnapshotPublishedChip />
                    </>
                )}
            </ListTitle>
        );
    }
}

export default withRunbookContext(withProjectContext(RunbookSnapshotsInternal));
