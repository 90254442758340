import * as React from "react";
import { RouteComponentProps, match } from "react-router";
import {
    ActionExecutionLocation,
    ChannelResource,
    DeploymentActionResource,
    IProcessResource,
    DeploymentStepResource,
    EnvironmentResource,
    WorkerPoolResource,
    ProjectResource,
    ResourceCollection,
    RunCondition,
    WorkerPoolsSummaryResource,
} from "client/resources";
import { default as pluginRegistry, ActionPlugin, ActionScope } from "components/Actions/pluginRegistry";
import { find, clone, isEqual } from "lodash";
import { repository } from "clientInstance";
import FeedResource from "client/resources/feedResource";
import { ProjectRouteParams } from "../ProjectLayout/ProjectLayout";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import { Permission } from "client/resources/permission";
import { isAllowed } from "components/PermissionCheck/PermissionCheck";
import * as tenantTagsets from "components/tenantTagsets";
import { TagIndex } from "components/tenantTagsets";
import ActionDetails from "./ActionDetails";
import ParentStepDetails from "./ParentStepDetails";
import RequestRaceConditioner from "utils/RequestRaceConditioner";
import WorkerPoolSummaryResource from "client/resources/workerpoolSummaryResource";
import PageTitleHelper from "utils/PageTitleHelper";
import ActionTemplateSearchResource from "client/resources/actionTemplateSearchResource";
import { DoBusyTask } from "components/DataBaseComponent/DataBaseComponent";
import DeploymentProcessEditorLayout from "./DeploymentProcessEditorLayout";
import BusyIndicator from "components/BusyIndicator";
import { WithActionScopeInjectedProps } from "components/Actions/withActionScope";

export interface StepDetailsParams extends ProjectRouteParams {
    stepId: string;
    actionType: string;
    template: string;
    reloadKey: string;
}

export type StepDetailsRouteProps = RouteComponentProps<StepDetailsParams>;

export interface StepDetailsLoaderState extends DataBaseComponentState {
    step: DeploymentStepResource;
    stepNumber: string;
    action: DeploymentActionResource;
    pageTitle: string;
    project: ProjectResource;
    environments: EnvironmentResource[];
    feeds: FeedResource[];
    availableRoles: any[];
    isBuiltInWorkerEnabled: boolean;
    availableWorkerPools: WorkerPoolResource[];
    isFirstStep: boolean;
    process: IProcessResource;
    roles: any[];
    hasFeatures: boolean;
    channels: ChannelResource[];
    isNew: boolean;
    actionTemplate: any;
    plugin: ActionPlugin;
    canRunBeforeAcquisition: boolean;
    tagIndex: TagIndex;
    isChildStep: boolean;
    templates: ActionTemplateSearchResource[];
    actionTypeName: string;
}

type StepDetailsLoaderProps = StepDetailsRouteProps & WithActionScopeInjectedProps & { processId: string };

class StepDetailsLoader extends DataBaseComponent<StepDetailsLoaderProps, StepDetailsLoaderState> {
    private requestRaceConditioner = new RequestRaceConditioner();

    constructor(props: StepDetailsLoaderProps) {
        super(props);
        this.state = ({} as any) as StepDetailsLoaderState;
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            await this.load();
        });
    }

    async componentDidUpdate(nextProps: StepDetailsLoaderProps) {
        if (nextProps.processId !== this.props.processId) {
            await this.doBusyTask(async () => {
                await this.load();
            });
        }
    }

    render() {
        if (this.props.processId === null) {
            return null;
        }

        if (this.state && this.state.action) {
            return withDeploymentProcessEditor(
                this.props.scope,
                this.props.processId,
                <ActionDetails scope={this.props.scope} {...this.props} {...this.state} />,
                this.doBusyTask,
                this.props.history,
                this.props.location,
                this.props.match,
                !this.state.action.Id
            );
        }

        if (this.state && this.state.step) {
            return withDeploymentProcessEditor(
                this.props.scope,
                this.props.processId,
                <ParentStepDetails {...this.props} {...this.state} step={this.state.step} />,
                this.doBusyTask,
                this.props.history,
                this.props.location,
                this.props.match,
                !this.state.step.Id
            );
        }

        return withDeploymentProcessEditor(this.props.scope, this.props.processId, <BusyIndicator show={true} />, this.doBusyTask, this.props.history, this.props.location, this.props.match, true);
    }

    private async loadAvailableWorkerPools(): Promise<WorkerPoolResource[]> {
        const availableWorkerPools: WorkerPoolResource[] = [];
        await this.requestRaceConditioner.avoidStaleResponsesForRequest(repository.WorkerPools.summary(), response => {
            const workerPoolsSummaries = response as WorkerPoolsSummaryResource;
            if (workerPoolsSummaries.WorkerPoolSummaries.length === 1) {
                if (workerPoolsSummaries.WorkerPoolSummaries[0].WorkerPool.IsDefault && workerPoolsSummaries.WorkerPoolSummaries[0].TotalMachines === 0 && workerPoolsSummaries.WorkerPoolSummaries[0].WorkerPool.CanAddWorkers) {
                    return;
                }
            }
            workerPoolsSummaries.WorkerPoolSummaries.forEach((workerPoolSummary: WorkerPoolSummaryResource) => {
                availableWorkerPools.push(workerPoolSummary.WorkerPool);
            });
        });
        return availableWorkerPools;
    }

    private load = async () => {
        if (!this.props.processId) {
            return;
        }

        let found: any;
        let action: any;
        let step: any;
        let actionTypeName: string;

        const project = await repository.Projects.get(this.props.match.params.projectSlug);

        const [environments, machineRoles, feeds, tagIndex, templates] = await Promise.all([
            repository.Environments.all(),
            repository.MachineRoles.all(),
            isAllowed({ permission: Permission.FeedView, project: project.Id, wildcard: true }) ? repository.Feeds.all() : [],
            tenantTagsets.getTagIndex(),
            repository.ActionTemplates.search(),
        ]);

        const availableWorkerPools = await this.loadAvailableWorkerPools();

        const lookups: Partial<StepDetailsLoaderState> = {
            environments,
            availableRoles: machineRoles,
            availableWorkerPools,
            feeds,
            project,
            tagIndex,
            templates,
        };
        lookups.process = await (this.props.scope === ActionScope.Deployments ? repository.DeploymentProcesses.get(this.props.processId) : repository.RunbookProcess.get(this.props.processId));
        if (!this.props.match.params.actionType) {
            //Probably not new?
            found = this.findAction(lookups.process, this.props.match.params.stepId);
        }

        let getTemplate;
        getTemplate = this.props.match.params.template
            ? repository.ActionTemplates.get(this.props.match.params.template)
            : found && found.action.Properties["Octopus.Action.Template.Id"]
            ? repository.ActionTemplates.get((found.action.Properties as any)["Octopus.Action.Template.Id"])
            : Promise.resolve(null);

        const [channels, actionTemplate] = await Promise.all<ResourceCollection<ChannelResource>, any>([
            isAllowed({
                permission: Permission.ProcessView,
                project: project.Id,
                tenant: "*",
            })
                ? repository.Projects.getChannels(project)
                : Promise.resolve(null),
            getTemplate,
        ]);

        lookups.channels = channels ? channels.Items : [];
        lookups.actionTemplate = actionTemplate;

        if (!this.props.match.params.actionType) {
            if (found) {
                step = found.step;
                action = found.action;
                actionTypeName = action ? templates.find(x => x.Type === action.ActionType).Name : null;
                PageTitleHelper.setPageTitle(found.step.Name);
            } else {
                step = find(lookups.process.Steps, s => s.Id === this.props.match.params.stepId);
                action = null;
                actionTypeName = null;
                if (step) {
                    PageTitleHelper.setPageTitle(step.Name);
                }
            }
        } else {
            actionTypeName = templates.find(x => x.Type === this.props.match.params.actionType).Name;
            if (this.props.match.params.stepId) {
                step = find(lookups.process.Steps, s => {
                    return s.Id === this.props.match.params.stepId;
                });
                if (step) {
                    action = {
                        Id: null,
                        ActionType: this.props.match.params.actionType,
                        Name: "",
                        Environments: [],
                        ExcludedEnvironments: [],
                        Channels: [],
                        TenantTags: [],
                        Properties: {},
                        Packages: [],
                        IsDisabled: false,
                        Links: null,
                    };
                    PageTitleHelper.setPageTitle("New child step");
                }
            }

            if (!step) {
                step = {
                    Id: null,
                    Name: "",
                    Properties: {},
                    Condition: "Success",
                    StartTrigger: "StartAfterPrevious",
                    PackageRequirement: "LetOctopusDecide",
                    Actions: [
                        {
                            Id: null,
                            ActionType: this.props.match.params.actionType,
                            Name: "",
                            Environments: [],
                            ExcludedEnvironments: [],
                            Channels: [],
                            TenantTags: [],
                            Properties: {},
                            Packages: [],
                            IsDisabled: false,
                            Links: null,
                        },
                    ],
                };
                action = step.Actions[0];
                PageTitleHelper.setPageTitle("New step");
            }

            if (lookups.actionTemplate) {
                action.Name = step.Name = lookups.actionTemplate.Name;
                action.Packages = clone(lookups.actionTemplate.Packages);
                action.Properties = clone(lookups.actionTemplate.Properties);
                action.Properties["Octopus.Action.Template.Id"] = lookups.actionTemplate.Id;
                action.Properties["Octopus.Action.Template.Version"] = lookups.actionTemplate.Version;
                lookups.actionTemplate.Parameters.forEach((parameter: any) => {
                    if (typeof parameter.DefaultValue !== "undefined" && parameter.DefaultValue !== null && parameter.DefaultValue !== "") {
                        if (parameter.DisplaySettings["Octopus.ControlType"] === "Sensitive") {
                            if (typeof parameter.DefaultValue === "object") {
                                action.Properties[parameter.Name] = {
                                    HasValue: true,
                                    NewValue: null,
                                    IsSensitive: true,
                                };
                            } else {
                                action.Properties[parameter.Name] = {
                                    HasValue: true,
                                    NewValue: parameter.DefaultValue,
                                    IsSensitive: true,
                                };
                            }
                            return;
                        }

                        action.Properties[parameter.Name] = parameter.DefaultValue;
                    }
                });
            }
        }

        if (!!action) {
            // Provide default fallback for script templates.
            if (action.ActionType === "Octopus.Script" && action.Properties && action.Properties["Octopus.Action.RunOnServer"] == null) {
                action.Properties["Octopus.Action.RunOnServer"] = "false";
            }

            lookups.isNew = !action.Name;
            lookups.plugin = pluginRegistry.getAction(action.ActionType, this.props.scope);
            if (lookups.isNew && this.pluginHasPackages(lookups.plugin, action) && lookups.feeds.length === 0) {
                throw new Error("A NuGet package feed is required before that step can be configured. Please add a feed in the Configuration area and try again.");
            }

            // If the execution location is optional, default it to run on the target
            if (lookups.isNew && lookups.plugin.executionLocation === ActionExecutionLocation.TargetOrServer) {
                action.Properties["Octopus.Action.RunOnServer"] = "false";
            }

            // If the execution location is locked, clean up the mess we've made by accidentally setting "Octopus.Action.RunOnServer" for all actions
            if (lookups.plugin.executionLocation !== ActionExecutionLocation.TargetOrServer) {
                delete action.Properties["Octopus.Action.RunOnServer"];
            }

            lookups.hasFeatures = pluginRegistry.hasFeaturesForAction(action.ActionType, this.props.scope);
            const isNewChildStep = lookups.isNew && !!this.props.match.params.stepId;
            lookups.isChildStep = step.Actions.length > 1 || isNewChildStep;
        }

        const stepIndex = lookups.process.Steps.indexOf(step);
        lookups.stepNumber = `${(stepIndex === -1 ? lookups.process.Steps.length : stepIndex) + 1}`;
        if (action && step.Actions.length > 1) {
            const actionIndex = step.Actions.indexOf(action);
            lookups.stepNumber += "." + ((actionIndex === -1 ? step.Actions.length : actionIndex) + 1);
        }

        const isNotFirstStep = stepIndex > 0 || (stepIndex < 0 && lookups.process.Steps.length > 0);
        lookups.isFirstStep = !isNotFirstStep;

        // If we DON'T have an action, or the action does NOT have packages, we canRunBeforeAcquisition
        lookups.canRunBeforeAcquisition = !action || !this.pluginHasPackages(pluginRegistry.getAction(action.ActionType, this.props.scope), action);

        lookups.process.Steps.forEach((s, i) => {
            if (!lookups.canRunBeforeAcquisition) {
                return;
            }

            if (i <= stepIndex || stepIndex < 0) {
                const hasPackages = s.Actions.some(a => {
                    const actionDefinition = pluginRegistry.getAction(a.ActionType, this.props.scope);
                    return actionDefinition && this.pluginHasPackages(actionDefinition, a);
                });

                if (hasPackages) {
                    lookups.canRunBeforeAcquisition = false;
                }
            }
        });

        if (step.Condition === null || step.Condition === "") {
            step.Condition = RunCondition.Success;
        }

        this.setState({
            ...(lookups as StepDetailsLoaderState),
            action,
            step,
            actionTypeName,
        });
    };

    private findAction = (deploymentProcess: IProcessResource, id: string) => {
        for (let i = 0; i < deploymentProcess.Steps.length; i++) {
            const step = deploymentProcess.Steps[i];
            for (let j = 0; j < step.Actions.length; j++) {
                const action = step.Actions[j];
                if (action.Id === id) {
                    return { action, step, actionIndex: j, stepIndex: i };
                }
            }
        }
        return null;
    };

    private pluginHasPackages(actionDefinition: any, action: any) {
        return actionDefinition.hasPackages ? actionDefinition.hasPackages(action) : false;
    }
}

function withDeploymentProcessEditor(scope: ActionScope, processId: string, layout: React.ReactNode, doBusyTask: DoBusyTask, history: any, location: any, matchProp: any, isAddStepDisabled: boolean) {
    return (
        <DeploymentProcessEditorLayout
            scope={scope}
            id={processId}
            doBusyTask={doBusyTask}
            isAddStepDisabled={isAddStepDisabled}
            render={() => {
                return layout;
            }}
            history={history}
            location={location}
            match={matchProp}
        />
    );
}

export default StepDetailsLoader;
