import * as React from "react";
import SvgIconMaterial from "material-ui/SvgIcon";
import SvgIcon from "@material-ui/core/SvgIcon";
import { white } from "theme/colors";

// We are using SVG content directly because SvgIconMaterial doesn't allow us to reference svg files directly.

const EnvironmentIcon = (props: any) => (
    <SvgIconMaterial {...props} viewBox="0 0 200 200">
        {/* tslint:disable-next-line */}
    <path d="M14 30v35h172V30zm26 26H23V39h17zM14 83v35h95V83zm26 26H23V92h17zM14 134v35h172v-35zm26 26H23v-17h17z"/>
    </SvgIconMaterial>
);

const ExcludedEnvironmentIcon = (props: any) => (
    <SvgIconMaterial {...props} viewBox="0 0 200 200">
        {/* tslint:disable-next-line */}
    <path d="M6.713 20.367l7.784-8.206 176.512 167.446-7.783 8.205z"/>
        {/* tslint:disable-next-line */}
    <path d="M88.13 83H14v35h95v-15.2zM40 109H23V92h17zM141.89 134H14v35h164.78zM23 160v-17h17v17zM186 160.27V134h-27.69L186 160.27zM85.58 65H186V30H48.69l36.89 35zM32.27 30H14v35h55.16zM23 56V39h17v17z"/>
    </SvgIconMaterial>
);

const ProjectIcon = (props: any) => (
    <SvgIconMaterial {...props} viewBox="0 0 200 200">
        {/* tslint:disable-next-line */}
    <path d="M174.33 26H25.67A14.67 14.67 0 0 0 11 40.7v14.36h178V40.7A14.67 14.67 0 0 0 174.33 26zM11 63.09v96.21A14.72 14.72 0 0 0 25.67 174h148.66A14.72 14.72 0 0 0 189 159.3V63.09zm59.12 58.42h-37v-37h37zm93.63 17H87.11a4.06 4.06 0 1 1 0-8.11h76.64a4.06 4.06 0 1 1 0 8.11zM83 111.52a4.06 4.06 0 0 1 4.06-4.06h59.31a4.06 4.06 0 1 1 0 8.11H87.11a4.06 4.06 0 0 1-4.11-4.05zm81.61-18.83h-77.5a4.06 4.06 0 0 1 0-8.11h77.56a4.06 4.06 0 1 1 0 8.11z"/>
    </SvgIconMaterial>
);

const MachineIcon = (props: any) => (
    <SvgIconMaterial {...props} viewBox="0 0 200 200">
        {/* tslint:disable-next-line */}
    <path d="M119.19 70.52h6.54a27 27 0 0 0-18-18V59a3.87 3.87 0 0 1-3.84 3.84h-7.73A3.87 3.87 0 0 1 92.32 59v-6.53a27 27 0 0 0-18 18h6.54a3.86 3.86 0 0 1 3.83 3.84V82a3.86 3.86 0 0 1-3.83 3.84h-6.59a27 27 0 0 0 18 18.05v-6.5a3.87 3.87 0 0 1 3.84-3.84h7.68a3.87 3.87 0 0 1 3.84 3.84v6.54a27 27 0 0 0 18-18.05h-6.54a3.86 3.86 0 0 1-3.73-3.88v-7.64a3.86 3.86 0 0 1 3.83-3.84z"/>
        {/* tslint:disable-next-line */}
    <path d="M170.17 16.15H29.83A3.82 3.82 0 0 0 26 20v116.71a3.82 3.82 0 0 0 3.83 3.82h65.34v15.74a14.92 14.92 0 0 0-9.3 9.3H42a4.83 4.83 0 1 0 0 9.66h43.87a14.92 14.92 0 0 0 28.26 0H158a4.83 4.83 0 1 0 0-9.66h-43.87a14.92 14.92 0 0 0-9.3-9.3v-15.74h65.34a3.82 3.82 0 0 0 3.83-3.82V20a3.82 3.82 0 0 0-3.83-3.85zM146.07 82a3.87 3.87 0 0 1-3.84 3.84h-8.58a34.59 34.59 0 0 1-26 26v8.58a3.87 3.87 0 0 1-3.84 3.84h-7.65a3.87 3.87 0 0 1-3.84-3.84v-8.58a34.59 34.59 0 0 1-26-26h-8.55A3.87 3.87 0 0 1 53.93 82v-7.64a3.87 3.87 0 0 1 3.84-3.84h8.58a34.59 34.59 0 0 1 26-26V36a3.87 3.87 0 0 1 3.84-3.84h7.68a3.87 3.87 0 0 1 3.81 3.84v8.58a34.59 34.59 0 0 1 26 26h8.58a3.87 3.87 0 0 1 3.84 3.84z"/>
    </SvgIconMaterial>
);

const WorkerPoolIcon = (props: any) => (
    <SvgIconMaterial {...props} viewBox="0 0 200 200">
        {/* tslint:disable-next-line */}
    <path d="M30.5 13.5v172h35v-172zm26 163h-17v-17h17zM83.5 90.5v95h35v-95zm26 86h-17v-17h17zM134.5 13.5v172h35v-172zm26 163h-17v-17h17z"/>
    </SvgIconMaterial>
);
const DynamicWorkerPoolIcon = (props: any) => (
    <SvgIconMaterial {...props} viewBox="0 0 200 200">
        {/* tslint:disable-next-line */}
    <path fill="#ffffff" d="M 31 14 v 172 h 35 v -172 z m 26 163 h -17 v -17 h 17 z M 84 91 v 95 h 35 v -95 z m 26 86 h -17 v -17 h 17 z M 135 14 v 172 h 35 v -172 z m 26 163 h -17 v -17 h 17 z"/>
    </SvgIconMaterial>
);
const WorkerIcon = (props: any) => (
    <SvgIconMaterial {...props} viewBox="0 0 200 200">
        {/* tslint:disable-next-line */}
    <path d="M170.17 16.15H29.83A3.82 3.82 0 0 0 26 20v116.71a3.82 3.82 0 0 0 3.83 3.82h65.34v15.74a14.92 14.92 0 0 0-9.3 9.3H42a4.83 4.83 0 1 0 0 9.66h43.87a14.92 14.92 0 0 0 28.26 0H158a4.83 4.83 0 1 0 0-9.66h-43.87a14.92 14.92 0 0 0-9.3-9.3v-15.74h65.34a3.82 3.82 0 0 0 3.83-3.82V20a3.82 3.82 0 0 0-3.83-3.85zM94.06 28.08h11.88a8.35 8.35 0 0 1 6.7 3.37l-5 21.51h-15L87 31.89a8.4 8.4 0 0 1 7.06-3.81zm6 98.92a40.74 40.74 0 0 1-40-33.1h80a40.74 40.74 0 0 1-39.95 33.1zm43.4-41.44h-87a6.14 6.14 0 0 1 0-12.28h1.68a41.77 41.77 0 0 1 21.54-36.54l5.74 21.48a4.19 4.19 0 0 0 4 3.09H111a4.18 4.18 0 0 0 4-3.23l5-21.5a41.81 41.81 0 0 1 21.81 36.7h1.67a6.14 6.14 0 1 1 0 12.28z"/>
    </SvgIconMaterial>
);

const UnhealthyMachineIcon = (props: any) => (
    <SvgIcon {...props} viewBox="0 0 16 16" fontSize="" style={{ fill: white, margin: 5 }}>
        {/* tslint:disable-next-line */}
    <path d="M11.53 10.037728L9.007273 7.515 11.53 4.992273 10.537728 4 8.015 6.522727 5.492273 4 4.5 4.992273 7.022727 7.515 4.5 10.037728l.992273.992272L8.015 8.507273 10.537728 11.03z"/>
    </SvgIcon>
);

const UnavailableMachineIcon = (props: any) => (
    <SvgIcon {...props} viewBox="0 0 16 16" fontSize="" style={{ fill: white, margin: 5 }}>
        {/* tslint:disable-next-line */}
    <path d="M12.240622 3.58747c0-.126157-.09669-.228427-.21596-.228427H4.802677l7.437945 6.58802V3.58747zm-3.739554 7.18861h2.69302l.896546.793832L12.5 11.05282 3.409366 3 3 3.517093l.895418.793235v6.237922c0 .126157.09669.228428.21596.228428h3.684295v.938163c-.246475.089186-.440077.293965-.524395.55467H4.797602c-.15057 0-.27263.129105-.27263.288366 0 .15926.12206.288368.27263.288368H7.27297c.116063.36099.43654.603756.797023.603756.360484 0 .68096-.242766.797024-.603756h2.47142c.15057 0 .27263-.129107.27263-.288368 0-.15926-.12206-.288367-.27263-.288367H8.86307c-.084762-.260412-.278197-.465014-.524395-.55467v-.93876h.162393z"/>
    </SvgIcon>
);

const ShellNameIcon = (props: any) => (
    <SvgIconMaterial {...props} viewBox="0 0 200 200">
        {/* tslint:disable-next-line */}
    <path d="M31.62,145.85l-10.26-16.2L86.89,90.94,21.36,51.34,31.62,35.13,115.7,87.34v9Z"/>
        {/* tslint:disable-next-line */}
    <path d="M98.8,143.74h79.93V163H98.8Z"/>
    </SvgIconMaterial>
);

const ExcludedMachineIcon = (props: any) => {
    return (
        <SvgIconMaterial {...props} viewBox="0 0 200 200" style={{ fill: white, margin: 5, height: 19.2, width: 19.2 }}>
            {/* tslint:disable-next-line */}
        <path d="M6.713 20.367l7.784-8.206 176.512 167.446-7.783 8.205z"/>
            {/* tslint:disable-next-line */}
        <path d="M158 165.57h-43.87a14.92 14.92 0 0 0-9.3-9.3v-15.74h45l-33.6-31.88a34.39 34.39 0 0 1-8.52 3.2v8.58a3.87 3.87 0 0 1-3.84 3.84h-7.71a3.87 3.87 0 0 1-3.84-3.84v-8.58a34.59 34.59 0 0 1-26-26h-8.55A3.87 3.87 0 0 1 53.93 82v-7.64a3.87 3.87 0 0 1 3.84-3.84h8.58a34.57 34.57 0 0 1 2.37-6.91L26 23.08v113.63a3.82 3.82 0 0 0 3.83 3.82h65.34v15.74a14.92 14.92 0 0 0-9.3 9.3H42a4.83 4.83 0 1 0 0 9.66h43.87a14.92 14.92 0 0 0 28.26 0H158a4.83 4.83 0 1 0 0-9.66zM75.23 54.2a34.5 34.5 0 0 1 17.09-9.65V36a3.87 3.87 0 0 1 3.84-3.84h7.68a3.87 3.87 0 0 1 3.84 3.84v8.58a34.59 34.59 0 0 1 26 26h8.58a3.87 3.87 0 0 1 3.84 3.84V82a3.87 3.87 0 0 1-3.84 3.84h-8.58a34.42 34.42 0 0 1-8.39 15.79l41 38.86h3.94a3.82 3.82 0 0 0 3.83-3.82V20a3.82 3.82 0 0 0-3.83-3.82h-135z"/>
            {/* tslint:disable-next-line */}
        <path d="M74.69 69.27c-.15.41-.3.83-.42 1.25H76zM119.71 96.4a26.81 26.81 0 0 0 6-10.52h-6.54a3.86 3.86 0 0 1-3.81-3.88v-7.64a3.86 3.86 0 0 1 3.83-3.84h6.54a27 27 0 0 0-18-18V59a3.87 3.87 0 0 1-3.84 3.84h-7.73A3.87 3.87 0 0 1 92.32 59v-6.53a26.83 26.83 0 0 0-11.53 7zM107.68 100.57v3.36c.87-.26 1.73-.58 2.57-.92zM84.64 78.72V82a3.86 3.86 0 0 1-3.83 3.84h-6.54a27 27 0 0 0 18 18.05v-6.5a3.87 3.87 0 0 1 3.84-3.84h4.12z"/>
        </SvgIconMaterial>
    );
};

const DeploymentProcessIcon = (props: any) => {
    return (
        <SvgIconMaterial {...props} viewBox="0 0 200 200">
            {/* tslint:disable-next-line */}
            <path d="M176.84 15.09l-21.16 3.44C134.57 21.44 116 31 102.56 45.89l-23.72 26L46.52 70a4.9 4.9 0 00-4.35 2.16L25.91 96.43a4.89 4.89 0 002 7.17L50.89 114 86 149.11l10.44 23a4.89 4.89 0 007.16 2.05l24.64-16.35a4.87 4.87 0 002.18-4.4l-2.16-32.31 25.88-23.62C169 84 178.56 65.43 181.47 44.32l3.44-21.16a7 7 0 00-8.07-8.07zm-39 67.8a14.67 14.67 0 110-20.75 14.67 14.67 0 01.02 20.75zM73.59 153.49l-27.08-27.08a1.94 1.94 0 00-2.71 0c-1.47 1.42-3.49 3.42-4.21 4.14C15.67 154.44 15 185 15 185s30.56-.67 54.48-24.59c.72-.72 2.72-2.74 4.14-4.21a1.94 1.94 0 00-.03-2.71z"></path>
        </SvgIconMaterial>
    );
};

const RunbookProcessIcon = (props: any) => {
    return (
        <SvgIconMaterial {...props} viewBox="0 0 200 200">
            {/* tslint:disable-next-line */}
            <path d="M185.74 7.38a13.85 13.85 0 00-10.44-4.75H43.84A13.87 13.87 0 0030 16.5q0 .62.06 1.23L9.91 159.52a33.58 33.58 0 007.71 26.7 33.29 33.29 0 0025.15 11.15h109.86a13.87 13.87 0 0013.74-12l22.63-167a13.84 13.84 0 00-3.26-10.99zM135 106H60.57a8.59 8.59 0 010-17.17H135a8.59 8.59 0 110 17.17zm8.59-42.93H69.16a8.59 8.59 0 110-17.17h74.41a8.59 8.59 0 110 17.17z"></path>
        </SvgIconMaterial>
    );
};

export {
    EnvironmentIcon,
    ExcludedEnvironmentIcon,
    ProjectIcon,
    MachineIcon,
    WorkerPoolIcon,
    DynamicWorkerPoolIcon,
    WorkerIcon,
    ExcludedMachineIcon,
    UnhealthyMachineIcon,
    UnavailableMachineIcon,
    ShellNameIcon,
    DeploymentProcessIcon,
    RunbookProcessIcon,
};
