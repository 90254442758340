import DeploymentActionResource from "./deploymentActionResource";
import ActionProperties from "client/resources/actionProperties";

export interface DeploymentStepResource {
    Id: string;
    Name: string;
    Properties: ActionProperties;
    Condition: RunCondition;
    StartTrigger: StartTrigger;
    PackageRequirement: PackageRequirement;
    Actions: DeploymentActionResource[];
}

export enum StartTrigger {
    StartWithPrevious = "StartWithPrevious",
    StartAfterPrevious = "StartAfterPrevious",
}

export enum RunCondition {
    Success = "Success",
    Failure = "Failure",
    Always = "Always",
    Variable = "Variable",
}

export enum PackageRequirement {
    LetOctopusDecide = "LetOctopusDecide",
    BeforePackageAcquisition = "BeforePackageAcquisition",
    AfterPackageAcquisition = "AfterPackageAcquisition",
}

export default DeploymentStepResource;
