import * as React from "react";
import { createContext } from "react";
import { repository } from "clientInstance";
import { ProjectResource } from "client/resources";
import { useDoBusyTaskEffect, DoBusyTask } from "components/DataBaseComponent";
import { useRequiredContext } from "hooks";

type ProjectContextProviderProps = {
    doBusyTask: DoBusyTask;
    projectIdOrSlug: string;
    children: (props: ProjectContextProps) => React.ReactNode;
};

export interface ProjectContextState {
    model: Readonly<ProjectResource>;
}

export interface ProjectContextActions extends ProjectLayoutActions {
    refreshModel: () => Promise<boolean>;
    refreshAndGetModel: () => Promise<ProjectContextState["model"]>;
}

export type ProjectContextProps = ReturnType<typeof useProjectLayoutSetup>;
export const ProjectContext = createContext<ProjectContextProps>(null);

const ProjectContextProvider: React.FC<ProjectContextProviderProps> = ({ children, doBusyTask, projectIdOrSlug }) => {
    const value = useProjectLayoutSetup(doBusyTask, projectIdOrSlug);
    return <ProjectContext.Provider value={value}>{children(value)}</ProjectContext.Provider>;
};

export const useProjectContext = () => {
    return useRequiredContext(ProjectContext, "Project");
};

const getStateUpdaters = (setState: React.Dispatch<React.SetStateAction<ProjectContextState>>) => {
    return {
        onProjectUpdated: (project: ProjectResource) => setState(current => ({ ...current, model: project })),
    };
};

const useProjectLayoutState = () => {
    return React.useState<ProjectContextState>({
        model: null,
    });
};

const invokeBusyWithResponse = async <T extends unknown>(action: () => Promise<T>, doBusyTask: DoBusyTask) => {
    const result = action();
    await doBusyTask(() => result);
    return result;
};

type ProjectLayoutActions = ReturnType<typeof getStateUpdaters>;

const useProjectLayoutSetup = (doBusyTask: DoBusyTask, projectIdOrSlug: string) => {
    const [state, setState] = useProjectLayoutState();
    const updaters = React.useMemo(() => getStateUpdaters(setState), [setState]);

    const refreshAndGetModel = React.useCallback(
        () =>
            invokeBusyWithResponse(async () => {
                const result = await repository.Projects.get(projectIdOrSlug);
                updaters.onProjectUpdated(result);
                return result;
            }, doBusyTask),
        [doBusyTask, updaters]
    );

    const refreshModel = React.useCallback(() => refreshAndGetModel().then(x => true), [refreshAndGetModel]);

    React.useEffect(() => {
        // tslint:disable-next-line: no-floating-promises
        refreshAndGetModel();
    }, [refreshAndGetModel]);

    const supportedActions = {
        refreshAndGetModel,
        refreshModel,
        ...updaters,
    };

    return {
        actions: supportedActions,
        state,
        setState,
    };
};

type ProjectContextConsumerProps = Parameters<typeof ProjectContext.Consumer>[0];
const ProjectContextConsumer: React.SFC<ProjectContextConsumerProps> = ({ children }) => {
    const context = useProjectContext();
    return <React.Fragment>{children(context)}</React.Fragment>;
};

export { ProjectContextProvider, ProjectContextConsumer };
