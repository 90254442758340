import * as React from "react";
import cn from "classnames";
import { alert } from "theme/colors";
const styles = require("./style.less");

interface WarningIconProps {
    color?: string;
    iconOverride?: string;
}

export default class WarningIcon extends React.Component<WarningIconProps> {
    render() {
        const color = this.props.color ? this.props.color : alert;
        return <em className={cn(`fa ${this.props.iconOverride ? this.props.iconOverride : "fa-warning"}`, styles.warningIcon)} style={{ color }} />;
    }
}
