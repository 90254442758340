import * as React from "react";
import { repository, client } from "clientInstance";
import { required, Text, MarkdownEditor, Select, Note } from "components/form";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { RunbookResource, ResourceCollection } from "client/resources";
import { WithProjectContextInjectedProps, withProjectContext } from "areas/projects/context";

export interface AddRunbookProps {
    projectId: string;
    cloneId?: string;
    onProcessCreated(id: string): void;
}

interface AddRunbookState extends DataBaseComponentState {
    name: string;
    description: string;
    selectedCloneId: string;
    projectRunbooks: ResourceCollection<RunbookResource>;
}

type Props = AddRunbookProps & WithProjectContextInjectedProps;

class AddRunbookInternal extends DataBaseComponent<Props, AddRunbookState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            name: "",
            description: "",
            selectedCloneId: this.props.cloneId,
            projectRunbooks: null,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            if (this.props.cloneId) {
                const project = this.props.projectContext.state.model;
                const projectRunbooks = await repository.Projects.getRunbooks(project);
                this.setState({
                    projectRunbooks,
                });
            }
        });
    }

    save() {
        const args = { clone: this.state.selectedCloneId };
        return this.doBusyTask(async () => {
            const result = await repository.Runbooks.create(
                {
                    Id: null,
                    Name: this.state.name,
                    Description: this.state.description,
                    SpaceId: client.spaceId,
                    RunbookProcessId: null, // Server will create this for us.
                    ProjectId: this.props.projectId,
                    Links: null,
                    ConnectivityPolicy: null,
                    MultiTenancyMode: this.props.projectContext.state.model.TenantedDeploymentMode, //Default to the project's setting
                },
                args
            );
            this.props.onProcessCreated(result.Id);
        });
    }

    render() {
        return (
            <SaveDialogLayout title={"Add New Runbook"} busy={this.state.busy} errors={this.state.errors} onSaveClick={() => this.save()}>
                <Text label="Name" value={this.state.name} onChange={name => this.setState({ name })} validate={required("Please enter a Runbook name")} autoFocus={true} />
                <MarkdownEditor value={this.state.description} label="Description" bottomNoteText="Any description will be shown at the top of this Runbook's Overview page." onChange={description => this.setState({ description })} />
                {this.props.cloneId &&
                    this.state.projectRunbooks && [
                        <Select
                            allowClear={true}
                            allowFilter={true}
                            value={this.state.selectedCloneId}
                            onChange={cloneId => this.setState({ selectedCloneId: cloneId.length > 0 ? cloneId : null })}
                            items={this.state.projectRunbooks.Items.map(pg => ({ value: pg.Id, text: pg.Name }))}
                            label="Clone from"
                        />,
                        <Note>Select an existing Runbook to clone. The steps in the other Runbook will be copied into the new Runbook when it is created.</Note>,
                    ]}
            </SaveDialogLayout>
        );
    }
}

const AddRunbook = withProjectContext(AddRunbookInternal);
export default AddRunbook;
