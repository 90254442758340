import * as React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import DateFormatter from "utils/DateFormatter";
import { DataTableRow, DataTableRowColumn } from "components/DataTable";
import TaskStatusIcon from "../../TaskStatusIcon";

const styles = require("./style.less");
import InternalLink from "../../../../../components/Navigation/InternalLink/InternalLink";
import TaskDetails from "../../../../../components/TaskDetails/TaskDetails";
import DeploymentDetailsIcon from "./DeploymentDetailsIcon";
import { TaskResource } from "../../../../../client/resources/taskResource";
import { DeploymentResource } from "../../../../../client/resources/deploymentResource";
import routeLinks from "../../../../../routeLinks";

interface DeploymentChildRowProps {
    deployment: DeploymentResource;
    task: TaskResource<{ DeploymentId: string }>;
    showChildRows: boolean;
    [others: string]: any;
}

const DeploymentChildRow: React.StatelessComponent<DeploymentChildRowProps> = props => {
    const { className, showChildRows, deployment, task } = props;

    return (
        <DataTableRow key={`${deployment.Id}`} className={cn(className, showChildRows ? styles.visible : styles.hidden, styles.deploymentsTableRow)}>
            <DataTableRowColumn />
            <DataTableRowColumn>
                <InternalLink to={routeLinks.task(deployment).root}>
                    <DeploymentDetailsIcon task={task} deployment={deployment} stripTopBottomPadding={true} />
                </InternalLink>
            </DataTableRowColumn>
            <DataTableRowColumn>
                <span className={styles.deploymentDate}>{DateFormatter.dateToLongFormat(deployment.Created)}</span>
            </DataTableRowColumn>
            <DataTableRowColumn />
        </DataTableRow>
    );
};

DeploymentChildRow.displayName = "DeploymentChildRow";
export default DeploymentChildRow;
