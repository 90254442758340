import * as React from "react";
import { RunbookResource } from "client/resources";
import MultiSelect from "./MultiSelect";
import { FormFieldProps } from "components/form";
import { RunbookChip } from "components/Chips/index";
import LookupResourceChipComponent from "components/LookupResourceChip";
import { ChipIcon } from "components/Chips";

interface RunbookMultiSelectProps extends FormFieldProps<string[]> {
    items: RunbookResource[];
    autoFocus?: boolean;
    label?: string | JSX.Element;
    error?: string;
}

const RunbookTypedMultiSelect = MultiSelect<RunbookResource>();
const LookupRunbookChip = LookupResourceChipComponent<RunbookResource>();

const RunbookMultiSelect: React.StatelessComponent<RunbookMultiSelectProps> = props => {
    const chipRenderer = (r: RunbookResource, onRequestDelete: (event: object) => void) => {
        return <LookupRunbookChip lookupCollection={props.items} lookupId={r.Id} type={ChipIcon.Runbook} chipRender={item => <RunbookChip onRequestDelete={onRequestDelete} runbook={item} />} />;
    };
    return <RunbookTypedMultiSelect items={props.items} fieldName="runbooks" renderChip={chipRenderer} {...props} />;
};

export default RunbookMultiSelect;
