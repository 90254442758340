import { Color } from "@material-ui/core";

export const white = "#ffffff";
export const primaryText = "#333333";
export const secondaryText = "#9e9e9e";
export const ternaryText = "#666666";

export const primary = "#2f93e0";
export const primaryDarkest = "#0F2535";
export const primaryDark = "#0A67B1";
export const primaryLight = "#74c0fb";
export const primaryLightest = "#EFF4F8";

export const secondaryDark = "#053B62";

export const hover = "rgba(153, 153, 153, 0.08)";
export const divider = "#cccccc";
export const primaryBackground = "#FAFAFA";
export const disabledBackground = "rgba(153, 153, 153, 0.7)";
export const secondaryBackground = "#e0e0e0";
export const secondaryLight = "#ecf5fb";

export const info = "#0A67B1";
export const success = "#48B350";
export const danger = "#db4437";
export const alert = "#fb8c00";
export const disable = "#fbe1e9";
export const auditHighlight = "#f7f7f7";
export const alertHighlight = "#fcf1e2";
export const dangerHighlight = "#f8e8e6";
export const codeHighlight = "#f4ebf9";
export const codeText = "#441c7f";

export const shadow10 = "rgba(0, 0, 0, 0.1)";
export const shadow40 = "rgba(0, 0, 0, 0.4)";
export const shadow60 = "rgba(0, 0, 0, 0.6)";
export const boxshadow1 = "rgba(0, 0, 0, 0.2) 0rem 2/16rem 1/16rem -1/16rem, rgba(0, 0, 0, 0.14) 0rem 1/16rem 1/16rem 0rem, rgba(0, 0, 0, 0.12) 0rem 1/16rem 3/16rem 0rem";
export const boxshadow2 = "rgba(0, 0, 0, 0.2) 0rem 3/16rem 1/16rem -2/16rem, rgba(0, 0, 0, 0.14) 0rem 2/16rem 2/16rem 0rem, rgba(0, 0, 0, 0.12) 0rem 1/16rem 5/16rem 0rem";
export const boxshadow3 = "rgba(0, 0, 0, 0.2) 0rem 3/16rem 3/16rem -2/16rem, rgba(0, 0, 0, 0.14) 0rem 3/16rem 4/16rem 0rem, rgba(0, 0, 0, 0.12) 0rem 1/16rem 8/16rem 0rem";
export const boxshadow4 = "rgba(0, 0, 0, 0.25) 0rem 14/16rem 45/16rem, rgba(0, 0, 0, 0.22) 0rem 10/16rem 18/16rem"; //shadow for dialogs
export const boxshadowSticky = "0 0.0625rem 0.1875rem -0.0625rem rgba(0, 0, 0, 0.34)";

export const chipDeleteButton = "rgba(51, 51, 51, 0.26)";

// Define @material-ui Color objects which consist of different shades of a particular hue along with accent colors. Please see
// https://material-ui.com/style/color/#color

// Color object for #2f93e0 along with shades and accent colors
export const octopusBlue: Color = {
    50: "#ecf5fb",
    100: "#c1dff6",
    200: "#97c9f0",
    300: "#6db3e9",
    400: "#4ea3e5",
    500: "#2f93e0",
    600: "#2a8bdc",
    700: "#2380d8",
    800: "#1d76d3",
    900: "#1264cb",
    A100: "#fafcff",
    A200: "#c7deff",
    A400: "#94bfff",
    A700: "#7ab0ff",
};

// Color object for #48b350 along with shades and accent colors
export const octopusGreen: Color = {
    50: "#e9f6ea",
    100: "#c8e8cb",
    200: "#a4d9a8",
    300: "#7fca85",
    400: "#63be6a",
    500: "#48b350",
    600: "#41ac49",
    700: "#38a340",
    800: "#309a37",
    900: "#218b27",
    A100: "#c8ffca",
    A200: "#95ff9a",
    A400: "#62ff69",
    A700: "#48ff51",
};
