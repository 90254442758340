import * as React from "react";
import { DeploymentStepResource, DeploymentActionResource, IProcessResource, StartTrigger, isRunbookProcessResource, isDeploymentProcessResource, ProjectResource, RunbookResource, processPermission } from "client/resources";
import { isAllowed } from "components/PermissionCheck/PermissionCheck";
import { OverflowMenuItems } from "components/Menu";
import SelectParentStep from "./SelectParentStep";
import SpecialVariables from "client/specialVariables";
import CloneStep, { CloneSourceDefinition, isRunbookProcessCloneSource, CloneStepsSource, isDeploymentsStepsCloneSource, CloneStepContextType } from "./CloneStep";
import { RunbookStepSorter, DeploymentProcessStepSorter } from "./DeploymentPartSorter";
import pluginRegistry, { ActionScope } from "components/Actions/pluginRegistry";
import routeLinks from "routeLinks";
import { repository } from "clientInstance";
import { OverflowMenuNavLink, OverflowMenuDialogItem, OverflowMenuDisabledItem, OverflowMenuGenericItem } from "components/Menu/OverflowMenu";
import { cloneDeep } from "lodash";
import { useProjectContext } from "areas/projects/context";
import { useProcessContext } from "../../context";
import { useOptionalRunbookContext } from "../Runbooks/RunbookContext";

export interface DeploymentProcessEditorFilter extends EditorFilter {
    channelId?: string;
}

export type RunbookProcessEditorFilter = EditorFilter;

export interface EditorFilter {
    filterKeyword: string;
    environmentId?: string;
    includeUnscoped?: boolean;
}

export interface DeploymentProcessEditorQuery {
    filterKeyword?: string;
    environmentId?: string;
    channelId?: string;
    includeUnscoped?: boolean;
}

export function superEncodeURI(value: string) {
    if (!value) {
        return null;
    }
    // encodeURIComponent doesn't encode (), which may be valid keywords.
    let encodedValue = encodeURIComponent(value);
    encodedValue = encodedValue.replace(/\(/g, "%28");
    encodedValue = encodedValue.replace(/\)/g, "%29");
    return encodedValue;
}

export function getDeploymentProcessQueryFromFilters(filter: DeploymentProcessEditorFilter): DeploymentProcessEditorQuery {
    return {
        ...filter,
        filterKeyword: filter.filterKeyword,
    };
}

export function getDeploymentProcessFilter(query: DeploymentProcessEditorQuery): DeploymentProcessEditorFilter {
    return {
        filterKeyword: query.filterKeyword || "",
        environmentId: query.environmentId || "",
        channelId: query.channelId || "",
        includeUnscoped: query.includeUnscoped,
    };
}

type OverflowMenuTypes = OverflowMenuNavLink | OverflowMenuDialogItem | OverflowMenuDisabledItem | OverflowMenuGenericItem;

interface StepsRenderProps {
    name: string;
    index: string;
    detailsUrl: string;
    menuItems: Array<OverflowMenuNavLink | OverflowMenuDialogItem | OverflowMenuDisabledItem | OverflowMenuGenericItem>;
    isParentGroup: boolean;
    isCurrentAction: boolean;
    isChildAction: boolean;
    isPlaceholder: boolean;
    isRunInParallelWithLast: boolean;
    isDisabled: boolean;
}

export interface StepContextMenuProps {
    scope: ActionScope;
    step: DeploymentStepResource;
    action?: DeploymentActionResource;
    stepIndex?: number;
    actionIndex?: number;
    isChildAction?: boolean;
    isParentGroup?: boolean;
    isCurrentAction?: boolean;
    keywordSearch?: string;
    render: (props: StepsRenderProps) => React.ReactElement<any>;
}

interface StepsOverflowMenuOptions {
    project: Readonly<ProjectResource>;
    runbook: Readonly<RunbookResource>;
    step: Readonly<DeploymentStepResource>;
    action?: Readonly<DeploymentActionResource>;
    scope: ActionScope;
    steps: Readonly<IProcessResource>;
    saveChanges(deploymentProcess: IProcessResource): Promise<any>;
    refreshData(): Promise<any>;
    onStepsUpdated(process: IProcessResource): void;
}

function getOverflowMenuItems(options: StepsOverflowMenuOptions, process: IProcessResource, isChildAction?: boolean): OverflowMenuTypes[] {
    const { action, step, project } = options;
    const projectSlug = project && project.Slug;
    const projectId = project && project.Id;
    const menuItems = [];
    const processEditPermission = { permission: processPermission(process), project: projectId, wildcard: true };
    const canEdit = isAllowed(processEditPermission);
    menuItems.push(OverflowMenuItems.navItem(canEdit ? "Edit" : "View Details", calculateDetailsUrl(projectSlug, action ? action.Id : step.Id, process)));

    if (!isChildAction) {
        if (!canHaveChildren(options, step)) {
            menuItems.push(OverflowMenuItems.disabledItem("Add child step", "This step type does not support child steps"));
        } else {
            menuItems.push(OverflowMenuItems.navItem("Add child step", addChildStepUrl(step, projectSlug, options.steps), null, processEditPermission));
        }
    }

    if (action) {
        menuItems.push(OverflowMenuItems.item(action.IsDisabled ? "Enable" : "Disable", () => (action.IsDisabled ? enable(options, action) : disable(options, action)), processEditPermission));
    }

    if (!action && canHaveChildren(options, step)) {
        menuItems.push(OverflowMenuItems.item("Enable all", () => enableAll(options, step), processEditPermission));
        menuItems.push(OverflowMenuItems.item("Disable all", () => disableAll(options, step), processEditPermission));
    }

    if (isChildAction) {
        menuItems.push(OverflowMenuItems.item("Move out", () => moveOut(options, step, action), processEditPermission));
    }

    if (action) {
        if (!canMoveIn(options, step.Id)) {
            menuItems.push(OverflowMenuItems.disabledItem("Move into...", "No steps available that can have children or this step type cannot be a child step."));
        } else {
            const stepsToMoveInto = options.steps.Steps.filter(s => s.Id !== step.Id && canHaveChildren(options, s));
            const selectParentStep = (
                <SelectParentStep steps={stepsToMoveInto} actionName={action.Name} currentlyTargetedRoles={step.Properties[SpecialVariables.Action.TargetRoles] as string} onStepSelected={parentStepId => moveIn(options, step, action, parentStepId)} />
            );
            menuItems.push(OverflowMenuItems.dialogItem("Move into...", selectParentStep, processEditPermission));
        }
    }

    const cloneStepAction = (
        <CloneStep
            stepId={options.step.Id}
            actionId={options.action && options.action.Id}
            currentRunbook={options.runbook}
            actionName={action ? action.Name : step.Name}
            currentProject={project}
            onCloneTargetSelected={definition => cloneStep(options, definition, step, action)}
        />
    );
    menuItems.push(OverflowMenuItems.dialogItem("Clone...", cloneStepAction, processEditPermission));

    if (action) {
        menuItems.push(OverflowMenuItems.deleteItemDefault("step", () => deleteStep(options.saveChanges, options.steps, step, action), processEditPermission));
    } else {
        menuItems.push(OverflowMenuItems.deleteItemDefault("parent step", () => deleteStep(options.saveChanges, options.steps, step), processEditPermission));
    }

    if (!action) {
        menuItems.push(
            OverflowMenuItems.dialogItem(
                "Reorder child steps",
                isRunbookProcessResource(options.steps) ? (
                    <RunbookStepSorter processId={options.steps.Id} title={"Reorder child steps"} stepId={step.Id} saveDone={options.refreshData} onStepsUpdated={options.onStepsUpdated} />
                ) : (
                    <DeploymentProcessStepSorter processId={options.steps.Id} title="Reorder child steps" stepId={step.Id} saveDone={options.refreshData} onStepsUpdated={options.onStepsUpdated} />
                ),
                processEditPermission
            )
        );
    }

    return menuItems;
}

export function deleteStep(saveChanges: (steps: IProcessResource) => Promise<any>, steps: IProcessResource, step: DeploymentStepResource, action?: DeploymentActionResource) {
    const actionId = action ? action.Id : null;

    const clonedSteps = cloneDeep(steps);
    const clonedStep = clonedSteps.Steps.find(x => x.Id === step.Id);

    if (!clonedStep) {
        throw Error(`The step "${step.Id}" could not be found in deployment process ${clonedSteps.Id}`);
    }

    if (actionId) {
        return removeParentStepChildAction(saveChanges, clonedSteps, clonedStep, actionId);
    }

    return removeParentStepOrSimpleAction(saveChanges, clonedSteps, clonedStep);
}

async function removeParentStepOrSimpleAction(saveChanges: (steps: IProcessResource) => Promise<any>, clonedSteps: IProcessResource, clonedStep: DeploymentStepResource) {
    clonedSteps.Steps = clonedSteps.Steps.filter(x => x.Id !== clonedStep.Id);
    await saveChanges(clonedSteps);
    return true;
}

async function removeParentStepChildAction(saveChanges: (steps: IProcessResource) => Promise<any>, clonedSteps: IProcessResource, clonedStep: DeploymentStepResource, actionId: string) {
    clonedStep.Actions = clonedStep.Actions.filter(x => x.Id !== actionId);

    if (clonedStep.Actions.length === 0) {
        clonedSteps.Steps = clonedSteps.Steps.filter(x => x.Id !== clonedStep.Id);
    } else if (clonedStep.Actions.length === 1) {
        clonedStep.Name = clonedStep.Actions[0].Name;
    }

    await saveChanges(clonedSteps);
    return true;
}

function canHaveChildren({ scope }: StepsOverflowMenuOptions, step: DeploymentStepResource) {
    return (
        step.Actions.filter(a => {
            const actionDefinition = pluginRegistry.getAction(a.ActionType, scope);
            return actionDefinition.canHaveChildren(step);
        }).length > 0
    );
}

async function disableAll(options: StepsOverflowMenuOptions, step: DeploymentStepResource) {
    step.Actions.forEach(a => (a.IsDisabled = true));
    await options.saveChanges(options.steps);
}

async function enableAll(options: StepsOverflowMenuOptions, step: DeploymentStepResource) {
    step.Actions.forEach(a => (a.IsDisabled = false));
    await options.saveChanges(options.steps);
}

function newStepFromAction(step: DeploymentStepResource, action: DeploymentActionResource): DeploymentStepResource {
    return {
        Id: null as any,
        Name: action.Name,
        PackageRequirement: step.PackageRequirement,
        Properties: step.Properties,
        Condition: step.Condition,
        StartTrigger: step.StartTrigger,
        Actions: [action],
    };
}

async function moveOut(options: StepsOverflowMenuOptions, step: DeploymentStepResource, action: DeploymentActionResource) {
    const newStep = newStepFromAction(step, action);
    const name = newStep.Name;
    let i = 1;
    const isStep = (s: any) => s.Name === newStep.Name;

    while (true) {
        if (!options.steps.Steps.some(isStep)) {
            break;
        }
        newStep.Name = name + " " + i;
        i++;
    }

    const actionIndex = step.Actions.indexOf(action);
    step.Actions.splice(actionIndex, 1);
    if (step.Actions.length === 1) {
        step.Name = step.Actions[0].Name;
    }

    const stepIndex = options.steps.Steps.indexOf(step);
    options.steps.Steps.splice(stepIndex + 1, 0, newStep);

    await options.saveChanges(options.steps);
}

async function moveIn(options: StepsOverflowMenuOptions, step: DeploymentStepResource, action: DeploymentActionResource, parentStepId: string) {
    // Copy the action to the new step.
    const newParent = options.steps.Steps.find(s => s.Id === parentStepId);
    newParent.Actions.splice(newParent.Actions.length, 0, action);

    // Remove action from the old step.
    const actionIndex = step.Actions.indexOf(action);
    step.Actions.splice(actionIndex, 1);

    // Remove parent step if no actions left.
    if (step.Actions.length === 0) {
        const stepIndex = options.steps.Steps.indexOf(step);
        options.steps.Steps.splice(stepIndex, 1);
    }

    await options.saveChanges(options.steps);
}

async function enable(options: StepsOverflowMenuOptions, action: DeploymentActionResource) {
    action.IsDisabled = false;
    await options.saveChanges(options.steps);
}

async function disable(props: StepsOverflowMenuOptions, action: any) {
    action.IsDisabled = true;
    await props.saveChanges(props.steps);
}

function calculateDetailsUrl(projectSlug: string, stepOrActionId: string, steps: IProcessResource): string | null {
    if (isDeploymentProcessResource(steps)) {
        return routeLinks.project(projectSlug).deployments.process.step(stepOrActionId);
    } else if (isRunbookProcessResource(steps)) {
        return routeLinks
            .project(projectSlug)
            .operations.runbook(steps.RunbookId)
            .runbookProcess.runbookProcess(steps.Id)
            .steps.step(stepOrActionId);
    } //, superEncodeURI(this.props.keywordSearch));
}

function canMoveIn(options: StepsOverflowMenuOptions, stepId: string) {
    const currentStep = options.steps.Steps.find(step => step.Id === stepId);
    if (!currentStep) {
        return false;
    }

    const isChildStep = canBeChildStep(options, currentStep);
    const hasParentStep = options.steps.Steps.filter(step => step.Id !== stepId && canHaveChildren(options, step)).length > 0;

    return isChildStep && hasParentStep;
}

const canBeChildStep = (options: StepsOverflowMenuOptions, step: DeploymentStepResource) => step.Actions.every(action => pluginRegistry.getAction(action.ActionType, options.scope).canBeChild);

const cloneStep = async (options: StepsOverflowMenuOptions, definition: CloneSourceDefinition, step: DeploymentStepResource, action?: DeploymentActionResource) => {
    const getSourceSteps = (source: CloneStepsSource) => {
        if (isRunbookProcessCloneSource(source)) {
            return repository.RunbookProcess.get(source.runbook.RunbookProcessId);
        } else if (isDeploymentsStepsCloneSource(source)) {
            return repository.DeploymentProcesses.get(source.project.DeploymentProcessId);
        }
    };

    const stepNameExists = (steps: DeploymentStepResource[], stepName: string) => {
        return steps.filter(s => s.Name === stepName).length > 0;
    };

    const nameOfActionExists = (steps: DeploymentStepResource[], actionName: string) => {
        return steps.filter(s => s.Name === actionName || s.Actions.filter(a => a.Name === actionName).length > 0).length > 0;
    };

    const getNewActionName = (steps: DeploymentStepResource[], clonedAction: DeploymentActionResource) => {
        let suffix = "";
        let counter = 1;
        while (nameOfActionExists(steps, clonedAction.Name + suffix)) {
            suffix = " - clone (" + counter + ")";
            counter++;
        }
        return clonedAction.Name + suffix;
    };

    const getNewStepName = (steps: DeploymentStepResource[], clonedStep: any) => {
        let suffix = "";
        let counter = 1;
        while (stepNameExists(steps, clonedStep.Name + suffix)) {
            suffix = " - clone (" + counter + ")";
            counter++;
        }
        return clonedStep.Name + suffix;
    };

    const targetSteps = await getSourceSteps(definition.target);

    if (action && step.Actions.length > 1 && definition.targetType !== CloneStepContextType.CurrentContext) {
        //Child step action being cloned to a different context
        const newStep = newStepFromAction(step, action);
        newStep.Actions[0].Id = "";
        newStep.Actions[0].Name = getNewActionName(targetSteps.Steps, newStep.Actions[0]);
        newStep.Actions[0].Channels = [];
        targetSteps.Steps.splice(targetSteps.Steps.length, 0, newStep);
    } else if (action && step.Actions.length > 1 && definition.targetType === CloneStepContextType.CurrentContext) {
        // It's a child step being cloned within the same step.
        const clonedAction = cloneDeep(action);
        clonedAction.Id = "";
        clonedAction.Name = getNewActionName(targetSteps.Steps, clonedAction);

        const targetStep = targetSteps.Steps.find(x => x.Id === step.Id);
        const actionIndex = step.Actions.indexOf(action);
        if (actionIndex === -1) {
            targetStep.Actions.splice(step.Actions.length, 0, clonedAction);
        } else {
            targetStep.Actions.splice(actionIndex + 1, 0, clonedAction);
        }
    } else {
        // It's a step being cloned (either to the same project or a different project).
        const clonedStep = cloneDeep(step);
        clonedStep.Id = "";
        clonedStep.Name = getNewStepName(targetSteps.Steps, clonedStep);
        clonedStep.Actions.forEach((a: any, index: number) => {
            clonedStep.Actions[index].Id = "";
            clonedStep.Actions[index].Name = getNewActionName(targetSteps.Steps, clonedStep.Actions[index]);
            clonedStep.Actions[index].Channels = [];
        });

        const stepIndex = targetSteps.Steps.indexOf(step);
        if (stepIndex === -1) {
            targetSteps.Steps.splice(targetSteps.Steps.length, 0, clonedStep);
        } else {
            targetSteps.Steps.splice(stepIndex + 1, 0, clonedStep);
        }
    }

    if (definition.targetType === CloneStepContextType.CurrentContext) {
        await options.saveChanges(targetSteps);
    } else {
        //We are in a different context and cloning outside of the current project which may be a runbook/deployment process existing in the current project or another project
        if (isRunbookProcessResource(targetSteps)) {
            await repository.RunbookProcess.modify(targetSteps);
        } else if (isDeploymentProcessResource(targetSteps)) {
            await repository.DeploymentProcesses.modify(targetSteps);
        }
    }
};

function addChildStepUrl(step: DeploymentStepResource, projectSlug: string, process: IProcessResource): string {
    if (isRunbookProcessResource(process)) {
        return routeLinks
            .project(projectSlug)
            .operations.runbook(process.RunbookId)
            .runbookProcess.runbookProcess(process.Id)
            .steps.childStepTemplates(step.Id).root;
    }
    return routeLinks.project(projectSlug).deployments.process.childStepTemplates(step.Id).root;
}

const DeploymentPartContextMenu: React.FC<StepContextMenuProps> = props => {
    const { step, action, stepIndex, actionIndex, keywordSearch, isParentGroup, isChildAction } = props;
    const projectContext = useProjectContext();
    const processContext = useProcessContext();
    const optionalRunbookContext = useOptionalRunbookContext();
    const runbook = optionalRunbookContext && optionalRunbookContext.state.runbook;

    const name = props.isParentGroup ? step && step.Name : action && action.Name;
    const index = props.isParentGroup ? stepIndex + "." : stepIndex ? `${stepIndex}.${actionIndex}.` : `${actionIndex}.`;
    const isPlaceholder = props.isParentGroup ? !step || !step.Id : !action || !action.Id;
    const isRunInParallelWithLast = isParentGroup ? step.StartTrigger === StartTrigger.StartWithPrevious && !keywordSearch : step.StartTrigger === StartTrigger.StartWithPrevious && !isChildAction && !keywordSearch;
    const isDisabled = isParentGroup ? step.Actions && step.Actions.every(x => !!x.IsDisabled) : action.IsDisabled;

    const options: StepsOverflowMenuOptions = {
        action,
        step,
        steps: processContext.state.process,
        project: projectContext.state.model,
        saveChanges: processContext.actions.saveSteps,
        onStepsUpdated: processContext.actions.onStepsChange,
        refreshData: processContext.actions.refreshSteps,
        scope: props.scope,
        runbook,
    };

    const menuItems = getOverflowMenuItems(options, processContext.state.process, isChildAction);

    return props.render({
        name,
        index,
        detailsUrl: calculateDetailsUrl(options.project && options.project.Slug, props.action ? props.action.Id : props.step.Id, processContext.state.process),
        menuItems,
        isParentGroup: props.isParentGroup,
        isCurrentAction: props.isCurrentAction,
        isChildAction: props.isChildAction,
        isPlaceholder,
        isRunInParallelWithLast,
        isDisabled,
    });
};

export default React.memo(DeploymentPartContextMenu);
