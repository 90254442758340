import * as React from "react";
import CustomDialog from "components/Dialog/CustomDialog";
import { getRawPackageEndpoint, getPackageEndpoint } from "areas/library/components/BuiltInRepository/BuiltInRepositoryUtils";
import Section from "components/Section";
import ExternalLink from "components/Navigation/ExternalLink";
import { CustomInfoDialogLayout, SmallDialogFrame } from "components/DialogLayout/Custom";
import ActionButton from "components/Button";

interface Props {
    label: string;
    asSecondaryButton?: boolean;
}

interface State {
    showDialog: boolean;
}

export class HowToPushYourPackageDialogLink extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showDialog: false,
        };
    }

    render() {
        return (
            <>
                <CustomDialog
                    open={this.state.showDialog}
                    close={this.closeDialog}
                    render={renderProps => (
                        <CustomInfoDialogLayout title="How to Push Packages" open={this.state.showDialog} close={() => this.setState({ showDialog: false })} frame={SmallDialogFrame}>
                            <Section>
                                Use the <code>Upload package</code> button to add a package to the library. Alternatively use a command line to push packages using a NuGet client, Curl or Powershell.
                            </Section>
                            <Section sectionHeader="NuGet client">
                                <pre>
                                    NuGet.exe push <em>YourApp.nupkg</em> -ApiKey <em>&lt;Your API Key&gt;</em> -Source <strong>{getPackageEndpoint()}</strong>
                                </pre>
                            </Section>
                            <Section sectionHeader="PowerShell">
                                <pre>
                                    $wc = new-object System.Net.WebClient <br />
                                    $wc.UploadFile("<strong>{getRawPackageEndpoint()}</strong>?apiKey=<em>&lt;Your API Key&gt;</em>", "<em>YourApp.1.0.0.zip</em>")
                                </pre>
                            </Section>
                            <Section sectionHeader="Curl">
                                <pre>
                                    curl -X POST <strong>{getRawPackageEndpoint()}</strong> -H "X-Octopus-ApiKey: <em>&lt; Your API Key&gt;</em>" -F "data=@Demo.1.0.0.zip"
                                </pre>
                            </Section>
                            <Section>
                                <ExternalLink href="OnboardingPackagingCommandLineLearnMore">Learn more about pushing packages to the built-in repository.</ExternalLink>
                                <br />
                                <ExternalLink href={"ApiKey"}>Learn how to create an API Key.</ExternalLink>
                            </Section>
                        </CustomInfoDialogLayout>
                    )}
                />
                {this.props.asSecondaryButton ? (
                    <ActionButton onClick={this.showDialog} label={this.props.label} />
                ) : (
                    <a href="#" onClick={this.showDialog}>
                        {this.props.label}
                    </a>
                )}
            </>
        );
    }

    private showDialog = (e: any) => {
        e.preventDefault();
        this.setState({ showDialog: true });
    };

    private closeDialog = () => {
        this.setState({ showDialog: false });
    };
}
