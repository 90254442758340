import * as React from "react";
const styles = require("./style.less");
import cn from "classnames";

const ComponentRow = (props: any) => {
    return <div className={cn(styles.componentRow, props.className)}>{props.children}</div>;
};

(ComponentRow as any).displayName = "ComponentRow";

export default ComponentRow;
