import * as React from "react";
import { default as MaterialChip } from "material-ui/Chip";
import { primaryText, secondaryBackground } from "theme/colors";
import { merge } from "lodash";
import ToolTip, { ToolTipContent } from "components/ToolTip/ToolTip";
import { normal } from "../../fontWeights";
import { LocationDescriptor } from "history";
import { colors } from "theme";

// @types/material-ui currently buggy. remove this if it ever works
interface ChipProps {
    className?: string;
    description?: string | ToolTipContent;
    onRequestDelete?: (event: object) => void;
    onClick?: (event: object) => void;
    backgroundColor?: string;
    labelColor?: string;
    markAsRemoved?: boolean;
    tabIndex?: number;
    avatar?: React.ReactNode;
    bordercolor?: string; // Had to use lowercase here because it's showing warnings otherwise ><
    style?: object;
    fullWidth?: boolean;
    noMargin?: boolean;
    to?: LocationDescriptor;
}

const defaultChip = {
    style: {
        lineHeight: "1.5rem",
        height: "1.5rem", // Specify height explicitly, or svg icons can push the container height out (eg. if you display
        // multiple chips in a row where some do/don't contain an avatar. E.g. environments list).
        margin: 4,
        display: "inline-flex",
        backgroundColor: secondaryBackground,
        borderWidth: "0",
        borderStyle: "none",
        borderColor: "transparent",
        cursor: "inherit",
        verticalAlign: "middle",
    },
    labelStyle: {
        lineHeight: "1.5rem",
        fontSize: "0.75rem",
        color: primaryText,
        fontWeight: normal,
        maxWidth: "12.5rem",
        textDecoration: "none",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
    },
    deleteIconStyle: {
        margin: "0.0625rem 0.0625rem 0 0",
        width: "1.375rem",
        height: "1.375rem",
        fill: colors.chipDeleteButton,
    },
};

const Chip: React.StatelessComponent<ChipProps> = props => {
    const chipStyle = merge({}, defaultChip);
    const { markAsRemoved, avatar, fullWidth, noMargin, ...rest } = props;
    const otherProps = { tabIndex: -1, ...rest };
    chipStyle.labelStyle.textDecoration = markAsRemoved ? "line-through" : "none";

    if (props.backgroundColor) {
        chipStyle.style["backgroundColor"] = props.backgroundColor;
    }
    if (props.bordercolor) {
        chipStyle.style["borderWidth"] = "0.0625rem";
        chipStyle.style["borderStyle"] = "solid";
        chipStyle.style["borderColor"] = props.bordercolor;
    }
    if (props.labelColor) {
        chipStyle.labelStyle["color"] = props.labelColor;
    }
    if (props.fullWidth) {
        chipStyle.labelStyle["maxWidth"] = "none";
    }
    if (props.noMargin) {
        chipStyle.style["margin"] = 0;
    }

    return (
        <MaterialChip style={chipStyle.style as any} labelStyle={chipStyle.labelStyle as any} deleteIconStyle={chipStyle.deleteIconStyle as any} {...otherProps}>
            {avatar}
            <ToolTip content={<span>{props.description || props.children}</span>}>{props.children}</ToolTip>
        </MaterialChip>
    );
};

export default Chip;
export { ChipProps };
