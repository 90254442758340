import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab, { TabProps } from "@material-ui/core/Tab";
import routeLinks from "routeLinks";
import { generatePath } from "react-router";
import { createStyles, makeStyles, withStyles } from "@material-ui/styles";
import { Theme } from "theme";
import { keyBy } from "lodash";
import { useRunbookContext } from "../Runbooks/RunbookContext";
import { primaryDark, shadow10, primary, primaryBackground } from "theme/colors";
import { isAllowed } from "components/PermissionCheck/PermissionCheck";
import { Permission } from "client/resources";
import { useProjectContext } from "areas/projects/context";

const useStyles = makeStyles<Theme>(theme => ({
    root: {
        color: theme.palette.text.primary,
    },
    rootTab: {
        minWidth: "7.5rem", // Needed for DrawerWrapperLayout.
        textTransform: "none",
        fontWeight: "normal",
        letterSpacing: "0",
        fontSize: "0.9125rem",
    },
    tabsContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: primaryBackground,
        borderTopStyle: "solid",
        borderTopWidth: "0.0625rem",
        borderTopColor: shadow10,
        borderBottomStyle: "solid",
        borderBottomWidth: "0.0625rem",
        borderBottomColor: shadow10,
    },
}));

type NavTabProps = TabProps;

const navTabStyles = createStyles((theme: Theme) => ({
    root: {
        "&:hover": {
            opacity: 1,
            color: primaryDark,
            transition: "backgroundColor 10s",
        },
        "&$selected": {
            borderBottom: `0.125rem solid ${primary}`,
            fontWeight: "500",
        },
    },
    selected: {},
}));

const NavTab = withStyles(navTabStyles)((props: NavTabProps) => <Tab {...props} />);

const RunbooksNavigationTabsInternal: React.FC<RouteComponentProps<{ projectSlug: string; spaceId: string; runbookId: string }>> = props => {
    const match = props.match;
    const context = useRunbookContext();
    const processId = context.state.runbook && context.state.runbook.RunbookProcessId;
    const links = routeLinks.project(":projectSlug").operations.runbook(":runbookId");

    const projectContext = useProjectContext();
    const tabs = [];
    tabs.push({ label: "Overview", value: generatePath(links.overview, match.params) });
    if (
        isAllowed({
            permission: Permission.TaskView,
            project: projectContext.state.model.Id,
            wildcard: true,
        })
    ) {
        tabs.push({ label: "Runs", value: generatePath(links.runslist, match.params) });
    }
    tabs.push({ label: "Process", value: generatePath(links.runbookProcess.runbookProcess(processId).root, match.params) });
    tabs.push({ label: "Settings", value: generatePath(links.settings, match.params) });
    const lookup = keyBy(tabs, x => x.value);

    const found = lookup[props.location.pathname] || tabs.find(x => props.location.pathname.indexOf(x.value) >= 0);
    const partialTabMatch = found && found.value;

    const classes = useStyles(props);

    return (
        <div className={classes.tabsContainer}>
            <Tabs
                value={partialTabMatch}
                className={classes.root}
                onChange={(_, val) => {
                    props.history.push(val);
                }}
                variant="scrollable"
                scrollButtons="off"
            >
                {tabs.map(x => (
                    <NavTab key={x.label} label={x.label} value={x.value} className={classes.rootTab} />
                ))}
            </Tabs>
        </div>
    );
};

const RunbooksNavigationTabs = withRouter(RunbooksNavigationTabsInternal);
export default RunbooksNavigationTabs;
