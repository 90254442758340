import { CommunicationStyle, CommunicationStyleResource, EndpointResource, TentacleEndpointResource } from "client/resources";
import { each } from "lodash";

export interface EndpointCommunicationStyle {
    label: string;
    value: CommunicationStyle;
    port?: number;
    discoverable: boolean;
    helpHtml: string;
    isListening?: boolean;
    isPolling?: boolean;
    workerOnly: boolean;
    hasMachinePolicy: boolean;
}

export const RawEndpointCommunicationStyle: EndpointCommunicationStyle[] = [
    {
        label: "Listening Tentacle",
        value: CommunicationStyle.TentaclePassive,
        port: 10933,
        discoverable: true,
        helpHtml: "Connect to a $machineType via a Tentacle in listening mode",
        workerOnly: true,
        isListening: true,
        isPolling: false,
        hasMachinePolicy: true,
    },
    {
        label: "Polling Tentacle",
        value: CommunicationStyle.TentacleActive,
        port: 10943,
        discoverable: false,
        helpHtml: "Connect to a $machineType via a Tentacle in polling mode",
        workerOnly: true,
        isListening: false,
        isPolling: true,
        hasMachinePolicy: true,
    },
    {
        label: "SSH Connection",
        value: CommunicationStyle.Ssh,
        port: 22,
        discoverable: true,
        helpHtml: "Connect to a $machineType via Secure Shell (SSH)",
        workerOnly: true,
        isListening: false,
        isPolling: false,
        hasMachinePolicy: true,
    },
    {
        label: "Offline Package Drop",
        value: CommunicationStyle.OfflineDrop,
        discoverable: false,
        helpHtml: "Configure a location to drop Offline Deployment Packages",
        workerOnly: false,
        isListening: false,
        isPolling: false,
        hasMachinePolicy: false,
    },
    {
        label: "Azure Web App",
        value: CommunicationStyle.AzureWebApp,
        discoverable: false,
        helpHtml: "Configure an Azure Web App target",
        workerOnly: false,
        isListening: false,
        isPolling: false,
        hasMachinePolicy: false,
    },
    {
        label: "Azure Cloud Service",
        value: CommunicationStyle.AzureCloudService,
        discoverable: false,
        helpHtml: "Configure an Azure Cloud Service target",
        workerOnly: false,
        isListening: false,
        isPolling: false,
        hasMachinePolicy: false,
    },
    {
        label: "Service Fabric Cluster",
        value: CommunicationStyle.AzureServiceFabricCluster,
        discoverable: false,
        helpHtml: "Configure a Service Fabric Cluster target",
        workerOnly: false,
        isListening: false,
        isPolling: false,
        hasMachinePolicy: false,
    },
    {
        label: "Kubernetes Cluster",
        value: CommunicationStyle.Kubernetes,
        discoverable: false,
        helpHtml: "Connect to a Kubernetes API",
        workerOnly: false,
        isListening: false,
        isPolling: false,
        hasMachinePolicy: false,
    },
    {
        label: "Cloud Region",
        value: CommunicationStyle.None,
        discoverable: false,
        helpHtml: "A convenient way to model multiple geographic regions",
        workerOnly: false,
        isListening: false,
        isPolling: false,
        hasMachinePolicy: false,
    },
    {
        label: "Ftp",
        value: CommunicationStyle.Ftp,
        discoverable: false,
        helpHtml: "Upload via FTP",
        workerOnly: false,
        isListening: false,
        isPolling: false,
        hasMachinePolicy: false,
    },
];

class EndpointsHelper {
    public communicationStyles: EndpointCommunicationStyle[];

    constructor(isWorker?: boolean) {
        if (!isWorker) {
            this.communicationStyles = RawEndpointCommunicationStyle.map(a => ({ ...a }));
            this.communicationStyles.forEach(value => {
                value.helpHtml = value.helpHtml.replace("$machineType", "deployment target");
            });
        } else {
            this.communicationStyles = RawEndpointCommunicationStyle.map(a => ({ ...a })).filter(variable => variable.workerOnly);
            this.communicationStyles.forEach(value => {
                value.helpHtml = value.helpHtml.replace("$machineType", "worker");
            });
        }
    }

    public canDiscover(value: CommunicationStyle) {
        if (value) {
            const communicationStyle = this.communicationStyles.filter(x => {
                return x.value === value;
            });
            return communicationStyle.length > 0 ? communicationStyle[0].discoverable : null;
        }
    }

    public isListeningTentacle(value: CommunicationStyle) {
        return this.communicationStyles.some(comm => comm.value === value && comm.isListening);
    }

    public isPollingTentacle(value: CommunicationStyle) {
        return this.communicationStyles.some(comm => comm.value === value && comm.isPolling);
    }

    public isTentacle(value: EndpointResource): value is TentacleEndpointResource {
        return value && (this.isListeningTentacle(value.CommunicationStyle) || this.isPollingTentacle(value.CommunicationStyle));
    }

    public getDefaultPort(value: CommunicationStyle) {
        if (value) {
            const communicationStyle = this.communicationStyles.filter(x => {
                return x.value === value;
            });
            return communicationStyle.length > 0 ? communicationStyle[0].port : null;
        }
    }

    public getFriendlyName(commStyle: CommunicationStyle) {
        return this.communicationStyles.find(style => style.value === commStyle).label;
    }

    public hasMachinePolicy(endpoint: EndpointResource): boolean {
        return this.communicationStyles.find(style => style.value === endpoint.CommunicationStyle).hasMachinePolicy;
    }

    public getCommunicationStyleResources(): CommunicationStyleResource[] {
        // Could hardcode this, but if we add other enums, this future proofs it.
        const statuses: CommunicationStyleResource[] = [];
        const objValues = Object.keys(CommunicationStyle).map((k: CommunicationStyle) => CommunicationStyle[k]);
        const names = objValues.filter(v => typeof v === "string") as string[];
        each(names, statusText => {
            const statusEnum = statusText as CommunicationStyle;
            const name = this.getFriendlyName(statusEnum);
            const status: CommunicationStyleResource = {
                Id: statusEnum,
                Name: name,
            };
            statuses.push(status);
        });
        return statuses;
    }
}

export default new EndpointsHelper();
