import projectRouteLinks from "./areas/projects/components/ProjectsRoutes/projectRouteLinks";
import tenantRouteLinks from "./areas/tenants/TenantsRoutes/tenantRouteLinks";
import libraryRouteLinks from "./areas/library/components/LibraryRoutes/libraryRouteLinks";
import infrastructureRouteLinks from "./areas/infrastructure/components/InfrastructureRoutes/infrastructureRouteLinks";
import taskRouteLinks from "./areas/tasks/components/TaskRoutes/taskRouteLinks";
import configurationRouteLinks from "./areas/configuration/components/ConfigurationRoutes/configurationRouteLinks";
import projectGroupRouteLinks from "./areas/projects/components/ProjectGroupLayout/projectGroupRouteLinks";
import deploymentRouteLinks from "./areas/deployments/deploymentRouteLinks";
import userProfileRouteLinks from "./areas/users/UserProfileRoutes/userProfileRouteLinks";
import dashboardRouteLinks from "./areas/dashboard/DashboardOverview/dashboardRouteLinks";
import runbookRunRouteLinks from "areas/runbookRuns/runbookRunRouteLinks";

// This regex is used in the function resolveStringPathWithSpaceId,
// such that it gets replaced with the spaceId from the route at link navigation time
const routeLinks = parameterizedRouteLinks(":spaceId(Spaces-[0-9]+)?");

function spaceRouteLinks(spaceId: string | null) {
    return parameterizedRouteLinks(spaceId);
}

function routeLinksWithoutSpace() {
    return parameterizedRouteLinks();
}

function parameterizedRouteLinks(spaceId?: string | null) {
    const root = spaceId ? `/${spaceId}` : "";

    return {
        forSpace: spaceRouteLinks,
        withoutSpace: routeLinksWithoutSpace,
        root,
        dashboard: dashboardRouteLinks(root),
        ...projectRouteLinks(root),
        ...tenantRouteLinks(root),
        ...taskRouteLinks(root),
        ...projectGroupRouteLinks(root),
        ...deploymentRouteLinks(root),
        ...runbookRunRouteLinks(root),
        currentUser: userProfileRouteLinks(root, ""),
        library: libraryRouteLinks(root),
        infrastructure: infrastructureRouteLinks(root),
        configuration: configurationRouteLinks(root),
        space: (id: string) => `/${id}`,
        styleGuide: `${root}/style-guide`,
        codeGuide: `${root}/code-guide`,
        legacy: {
            environments: `${root}/environments`,
            accounts: `${root}/accounts`,
            machines: `${root}/machines`,
            manage: {
                console: `${root}/manage/console`,
            },
        },
        noPermissions: `${root}/noPermissions`,
    };
}

export default routeLinks;
