export interface MetadataTypeCollection {
    Description: string;
    Types: TypeMetadata[];
}

export interface TypeMetadata {
    Name: string;
    Properties: PropertyMetadata[];
}

export interface PropertyMetadata {
    Name: string;
    Type: string;
    DisplayInfo: DisplayInfo;
}

export interface DisplayInfo {
    Required: boolean;
    Sensitive: boolean;
    Label: string;
    Description: string;
    Options: any;
    ListApi: string;
    ReadOnly: boolean;
    ShowCopyToClipboard: boolean;
    PropertyApplicability?: PropertyApplicability;
    ConnectivityCheck?: ConnectivityCheck;
}

export interface MetadataTypeDisplayOptions {
    SelectMode: string;
    Values: any;
}

export enum PropertyApplicabilityMode {
    ApplicableIfHasAnyValue = "ApplicableIfHasAnyValue",
    ApplicableIfHasNoValue = "ApplicableIfHasNoValue",
    ApplicableIfSpecificValue = "ApplicableIfSpecificValue",
    ApplicableIfNotSpecificValue = "ApplicableIfNotSpecificValue",
}

export interface PropertyApplicability {
    Mode: PropertyApplicabilityMode;
    DependsOnPropertyName: string;
    DependsOnPropertyValue: any;
}

export interface ConnectivityCheck {
    Title: string;
    Url: string;
    DependsOnPropertyNames: string[];
}

export interface ConnectivityCheckResponse {
    WasSuccessful: boolean;
    ErrorMessages: string[];
}

export type DataContext = { [key: string]: any };
