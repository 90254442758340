import * as React from "react";
import InternalLink from "components/Navigation/InternalLink";
import { ActionTemplateUsageResource, ProcessType } from "client/resources";
import routeLinks from "routeLinks";
import { exhaustiveCheck } from "utils/exhaustiveCheck";

export const ActionTemplateUsageProcessLink: React.FC<{ usage: ActionTemplateUsageResource }> = ({ usage }) => {
    switch (usage.ProcessType) {
        case ProcessType.Runbook:
            return (
                <InternalLink
                    to={
                        routeLinks
                            .project(usage.ProjectSlug)
                            .operations.runbook(usage.RunbookId)
                            .runbookProcess.runbookProcess(usage.ProcessId).steps.root
                    }
                >{`Runbook: ${usage.RunbookName}`}</InternalLink>
            );
        case ProcessType.Deployment:
            return <InternalLink to={routeLinks.project(usage.ProjectSlug).process.root}>{usage.ProcessType}</InternalLink>;
    }

    exhaustiveCheck(usage.ProcessType);
};

export const ActionTemplateUsageStepLink: React.FC<{ usage: ActionTemplateUsageResource }> = ({ usage }) => (
    <InternalLink
        to={
            usage.ProcessType === ProcessType.Deployment
                ? routeLinks.project(usage.ProjectSlug).process.step(usage.StepId)
                : routeLinks
                      .project(usage.ProjectSlug)
                      .operations.runbook(usage.RunbookId)
                      .runbookProcess.runbookProcess(usage.ProcessId)
                      .steps.step(usage.ActionId)
        }
        openInSelf={false}
    >
        {usage.ActionName}
    </InternalLink>
);
