import * as React from "react";
import { repository } from "clientInstance";
import { RouteComponentProps } from "react-router";
import FormPaperLayout from "components/FormPaperLayout/FormPaperLayout";
import FormBaseComponent, { OptionalFormBaseComponentState } from "components/FormBaseComponent";
import { cloneDeep, sortBy, last } from "lodash";
import { FeaturesConfigurationResource, TaskResource } from "client/resources";
import { ActionButton } from "components/Button";
import { ExpandableFormSection, Summary, Note, BooleanRadioButtonGroup, RadioButton, Text, FormSectionHeading } from "components/form";
import ExternalLink from "components/Navigation/ExternalLink";
import InternalLink from "components/Navigation/InternalLink";
import DateFormatter from "utils/DateFormatter";
import Permission from "client/resources/permission";
const styles = require("./style.less");
import { danger, success } from "theme/colors";
import routeLinks from "../../../../routeLinks";
import { connect, MapStateToProps, MapDispatchToProps } from "react-redux";
import { bindActionCreators, Dispatch, Action } from "redux";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";
import { configurationActions, ConfigurationFeaturesState } from "../../reducers/configurationArea";
import { default as Callout, CalloutType } from "../../../../components/Callout";
import { EarlyAccessFeatureText } from "areas/projects/components/Runbooks/RunbooksOnboarding";

type FeaturesProps = RouteComponentProps<any>;

interface GlobalConnectedProps {
    features: ConfigurationFeaturesState;
}

interface GlobalDispatchProps {
    onFeaturesFetched: (features: FeaturesConfigurationResource) => void;
}

interface FeaturesState extends OptionalFormBaseComponentState<FeaturesConfigurationResource> {
    lastSyncedTask?: TaskResource<any>;
    redirectToTaskId?: string;
}

type Props = FeaturesProps & GlobalConnectedProps & GlobalDispatchProps;

class FeaturesLayout extends FormBaseComponent<Props, FeaturesState, FeaturesConfigurationResource> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const featuresConfiguration = await repository.FeaturesConfiguration.get();
            this.props.onFeaturesFetched(featuresConfiguration);

            if (this.props.features.isCommunityActionTemplatesEnabled) {
                await this.loadLastSyncedTask();
            }

            this.setState({
                model: featuresConfiguration,
                cleanModel: cloneDeep(featuresConfiguration),
            });
        });
    }

    async componentWillReceiveProps(nextProps: Props) {
        if (!this.props.features.isCommunityActionTemplatesEnabled && nextProps.features.isCommunityActionTemplatesEnabled) {
            await this.loadLastSyncedTask();
        }
    }

    render() {
        if (this.state.redirectToTaskId) {
            return <InternalRedirect to={routeLinks.task(this.state.redirectToTaskId).root} push={true} />;
        }

        return (
            <FormPaperLayout
                title={"Features"}
                busy={this.state.busy}
                errors={this.state.errors}
                model={this.state.model}
                cleanModel={this.state.cleanModel}
                savePermission={{ permission: Permission.ConfigureServer }}
                onSaveClick={this.handleSaveClick}
                saveText={"Saved"}
                expandAllOnMount={false}
                overFlowActions={[]}
            >
                {this.state.model && (
                    <TransitionAnimation>
                        <FormSectionHeading title="Steps" />
                        <ExpandableFormSection
                            errorKey="IsCommunityActionTemplatesEnabled"
                            title="Community Step Templates"
                            summary={this.state.model.IsCommunityActionTemplatesEnabled ? Summary.default("Enabled") : Summary.summary("Disabled")}
                            help="Enable access to the Community Library."
                        >
                            <BooleanRadioButtonGroup value={this.state.model.IsCommunityActionTemplatesEnabled} onChange={IsCommunityActionTemplatesEnabled => this.setModelState({ IsCommunityActionTemplatesEnabled })}>
                                <RadioButton value={true} label="Enabled" isDefault={true} />
                                <RadioButton value={false} label="Disabled" />
                            </BooleanRadioButtonGroup>
                            <br />
                            {this.state.model.IsCommunityActionTemplatesEnabled && (
                                <div>
                                    {this.state.lastSyncedTask ? (
                                        <Note>
                                            {!this.state.lastSyncedTask.FinishedSuccessfully ? (
                                                <span className={styles.taskFailed}>
                                                    <em className="fa fa-exclamation-triangle" style={{ color: danger }} /> Last sync failed
                                                </span>
                                            ) : (
                                                <span className={styles.taskSucceeded}>
                                                    <em className="fa fa-check" style={{ color: success }} /> Last sync succeeded
                                                </span>
                                            )}
                                            <InternalLink to={routeLinks.task(this.state.lastSyncedTask).root} className={styles.taskTime}>
                                                {" "}
                                                {DateFormatter.momentAgo(this.state.lastSyncedTask.CompletedTime)}
                                            </InternalLink>
                                            <ActionButton label={"Sync now"} disabled={this.state.busy} onClick={() => this.synchronizeLibrarySteps()} />
                                        </Note>
                                    ) : (
                                        <Note>
                                            Not run
                                            <ActionButton label={"Sync now"} disabled={this.state.busy} onClick={() => this.synchronizeLibrarySteps()} />
                                        </Note>
                                    )}
                                </div>
                            )}
                            <Note>
                                This feature requires internet to access the <ExternalLink href="CommunityLibrary">Community Library</ExternalLink>. Octopus will fetch and store the
                                <ExternalLink href="CommunityContributedStepTemplates"> community contributed steps</ExternalLink> locally, to be available when creating a deployment process and step templates.
                            </Note>
                        </ExpandableFormSection>
                        <ExpandableFormSection
                            errorKey="IsBuiltInWorkerEnabled"
                            title="Run steps on Octopus Server"
                            summary={this.state.model.IsBuiltInWorkerEnabled ? Summary.default("Enabled") : Summary.summary("Disabled")}
                            help="Enable steps to execute on the Octopus Server's built-in worker."
                        >
                            <BooleanRadioButtonGroup value={this.state.model.IsBuiltInWorkerEnabled} onChange={IsBuiltInWorkerEnabled => this.setModelState({ IsBuiltInWorkerEnabled })}>
                                <RadioButton value={true} label="Enabled" />
                                <RadioButton value={false} label="Disabled" />
                            </BooleanRadioButtonGroup>
                            <Note style={{ marginTop: "1rem" }}>
                                This feature enables Azure, AWS, Terraform and some scripts steps to use the
                                <ExternalLink href="BuiltinWorker"> built-in worker</ExternalLink> to run Calamari on the Octopus Server. If the built-in worker is disabled, these steps can't run on the Octopus Server and worker pools should be
                                provisioned to allow these steps to run. Learn more about <ExternalLink href="Worker">workers</ExternalLink>.
                            </Note>
                        </ExpandableFormSection>
                        <FormSectionHeading title="Help Sidebar" />
                        <ExpandableFormSection
                            errorKey="HelpSidebarSupportLink"
                            title="Customize the Help Sidebar"
                            summary={this.state.model.HelpSidebarSupportLink ? Summary.summary(this.state.model.HelpSidebarSupportLink) : Summary.default("Octopus support")}
                            help="Customize the Help Sidebar support link in the Octopus web-portal UI."
                        >
                            <Text value={this.state.model.HelpSidebarSupportLink} onChange={x => this.setModelState({ HelpSidebarSupportLink: x })} label="Support Link" error={this.getFieldError("HelpSidebarSupportLink")} autoFocus={true} />
                            <Note style={{ marginTop: "1rem" }}>
                                The sidebar includes a link to the Octopus support website by default. This feature allows you to customize the link destination. For example, in cases where you'd prefer users contact your own support system. If the
                                sidebar feature is disabled, this support link is located in the top profile menu.
                            </Note>
                        </ExpandableFormSection>
                        <ExpandableFormSection
                            errorKey="IsHelpSidebarEnabled"
                            title="Toggle the Help Sidebar"
                            summary={this.state.model.IsHelpSidebarEnabled ? Summary.default("Enabled") : Summary.summary("Disabled")}
                            help="Enable/disable the contextual help sidebar in the Octopus web-portal UI."
                        >
                            <BooleanRadioButtonGroup value={this.state.model.IsHelpSidebarEnabled} onChange={IsHelpSidebarEnabled => this.setModelState({ IsHelpSidebarEnabled })}>
                                <RadioButton value={true} label="Enabled" />
                                <RadioButton value={false} label="Disabled" />
                            </BooleanRadioButtonGroup>
                            <Note style={{ marginTop: "1rem" }}>
                                This feature provides your users with contextual-help to improve their understanding of Octopus' key concepts and includes easy access to the support and documentation resources. Users can dismiss the sidebar
                                themselves. <em>Disabling this feature is not recommended.</em>
                            </Note>
                        </ExpandableFormSection>
                        {/*<FormSectionHeading title="Early Access" />
                        <Callout type={CalloutType.Warning} title={"Early Access Features"}>
                            {EarlyAccessFeatureText}
                        </Callout>*/}
                    </TransitionAnimation>
                )}
            </FormPaperLayout>
        );
    }

    private handleSaveClick = async () => {
        await this.doBusyTask(async () => {
            const isNew = this.state.model.Id == null;
            const result = await repository.FeaturesConfiguration.modify(this.state.model);
            this.props.onFeaturesFetched(result);
            this.setState({
                model: result,
                cleanModel: cloneDeep(result),
            });
        });
    };

    private async synchronizeLibrarySteps() {
        return this.doBusyTask(async () => {
            const task = await repository.Tasks.createSynchronizeCommunityStepTemplatesTask();
            this.setState({ redirectToTaskId: task.Id });
        });
    }

    private async loadLastSyncedTask() {
        const tasks = await repository.Tasks.filter({ name: "SyncCommunityActionTemplates", take: 1 });
        if (tasks.Items.length > 0) {
            const tasksByCompleted = sortBy(tasks.Items, "CompletedTime");
            const lastSyncedTask = last(tasksByCompleted);
            this.setState({ lastSyncedTask });
        }
    }
}

const mapGlobalStateToProps: MapStateToProps<GlobalConnectedProps, FeaturesProps, GlobalState> = state => {
    return {
        features: state.configurationArea.features,
    };
};

const mapGlobalActionDispatchersToProps: MapDispatchToProps<GlobalDispatchProps, {}> = (dispatch: Dispatch<Action<GlobalState>>) => bindActionCreators({ onFeaturesFetched: configurationActions.featuresFetched }, dispatch);

export default connect(
    mapGlobalStateToProps,
    mapGlobalActionDispatchersToProps
)(FeaturesLayout);
