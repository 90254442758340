import * as React from "react";
import { cloneDeep } from "lodash";
import { VariableType, ReferenceType } from "client/resources/variableResource";
import Popover from "components/Popover/Popover";
import Menu from "material-ui/Menu";
import MenuItem from "material-ui/MenuItem";
import Text, { TextInput } from "components/form/Text/Text";
import { CertificateIndex } from "components/certificates";
import { secondaryText, primary } from "theme/colors";
import FocusActionsLayout from "components/FocusActionsLayout/FocusActionsLayout";
import ActionButton, { ActionButtonType } from "components/Button/ActionButton";
const styles = require("./style.less");
import ReadonlyText from "components/ReadonlyText/ReadonlyText";
import HttpsIcon from "material-ui/svg-icons/action/https";
import VariableCell from "areas/variables/VariableCell/VariableCell";
import DebounceValue from "components/DebounceValue/DebounceValue";
import RateReviewIcon from "material-ui/svg-icons/maps/rate-review";
import ToolTip from "components/ToolTip/ToolTip";
import { SingleVariableRowHeight } from "areas/variables/SingleVariableRow/SingleVariableRow";
import { createInitialSensitiveState, SensitiveState, StatelessSensitive, StatelessSensitiveProps } from "components/form/Sensitive/Sensitive";
import { SensitiveValue } from "client/resources/propertyValueResource";
import ReadonlySensitive from "components/ReadonlySensitive/ReadonlySensitive";
import { AccountIcon } from "areas/infrastructure/components/AccountsLayout/AccountIcons";
import AccountDisplay from "areas/infrastructure/components/AccountDisplay";
import { AccountType } from "client/resources";
import { isReferenceType } from "../isReferenceType";
import { filteredVariableTypes } from "../VariableDetails";
import { VariableCellIcon, CellIcons } from "../VariableCellIcon";
import MultilineValueContainer from "./MultilineValueContainer";
interface VariableValueCellProps {
    id: string;
    value: string | null;
    type: VariableType;
    sensitiveState: SensitiveState | undefined;
    existingSensitiveValue: string | null | undefined; // null is a valid existing value, undefined means no existing value
    hintText: string;
    deleted?: boolean;
    certificateIndex: CertificateIndex;
    isPromptedVariable: boolean;
    onValueChanged: (name: string) => void;
    onVariableTypeChanged: (type: VariableType) => void;
    onSensitiveStateChanged: (state: SensitiveState) => void;
    onOpenEditorClicked: () => void;
    onChangeToReferenceType: (type: ReferenceType) => void;
    isFocused: boolean;
    onFocus: () => void;
    onBlur: () => void;
}

interface VariableValueCellState {
    showChangeTypeMenu: boolean;
}

const DebouncedText = DebounceValue(Text);
const DebouncedSensitive = DebounceValue<StatelessSensitiveProps, SensitiveValue>(StatelessSensitive);
interface Index {
    [index: string]: string;
}

const getReferenceNotSelectedText = (reference: string) => `(No ${reference} selected)`;
const accountNotSelectedText = getReferenceNotSelectedText("account");
const certificateNotSelectedText = getReferenceNotSelectedText("certificate");

const indexOrValue = (index: Index, id: string) => (index && index[id] ? index[id] : id);
export const isMultilineValue = (value: string) => value && value.split(/\r?\n/).length > 1;

export default class VariableValueCell extends React.Component<VariableValueCellProps, VariableValueCellState> {
    private selectInputAfterNextUpdate: boolean;
    private textField: TextInput | null = null;
    private sensitiveField: StatelessSensitive | null;
    private changeTypeLink: HTMLElement | null;
    private itemLink: HTMLElement | null;

    private readonly onBlur: () => void;

    constructor(props: VariableValueCellProps) {
        super(props);
        this.state = {
            showChangeTypeMenu: false,
        };
        this.selectInputAfterNextUpdate = this.props.isFocused;
        this.onBlur = () => this.props.onBlur();
    }

    componentWillReceiveProps(nextProps: VariableValueCellProps) {
        if (nextProps.isFocused && !this.props.isFocused) {
            this.selectInputAfterNextUpdate = true;
        }
    }

    componentDidMount() {
        this.selectInputIfNotFocused();
    }

    componentDidUpdate() {
        this.selectInputIfNotFocused();
    }

    selectInputIfNotFocused() {
        if (this.selectInputAfterNextUpdate) {
            if (this.textField && !this.textField.isFocused()) {
                this.textField.select();
            }
            if (this.sensitiveField) {
                this.sensitiveField.select();
            }
            if (this.itemLink) {
                this.itemLink.focus();
            }
            this.selectInputAfterNextUpdate = false;
        }
    }

    render() {
        const sensitiveValue: SensitiveValue = {
            HasValue: this.props.existingSensitiveValue !== undefined,
            NewValue: this.props.value,
        };
        const promptedVariableIcon = this.props.isPromptedVariable && (
            <div className={styles.promptedVariablePositionContainer}>
                <div className={styles.promptedVariableIconSizeContainer}>
                    <ToolTip content="You will be prompted for a value during a deployment">
                        <RateReviewIcon className={styles.promptedVariableIcon} />
                    </ToolTip>
                </div>
            </div>
        );

        const isMultiline = isMultilineValue(this.props.value);
        const multilineTextIcon = isMultiline && !this.props.isFocused ? <VariableCellIcon type={CellIcons.multiline} description="This is a multi line value, please open the editor to edit" /> : "";

        return (
            <div className={styles.valueCell} onFocus={this.props.onFocus} onClick={this.props.onFocus}>
                {this.props.deleted ? (
                    <VariableCell className={styles.valueCellContentReadonly}>
                        {promptedVariableIcon}
                        {this.props.type === VariableType.Sensitive && <ReadonlySensitive hasValue={true} className={styles.deletedSensitive} monoSpacedFont={true} />}
                        {this.props.type === VariableType.String && <ReadonlyText className={styles.deletedText} text={this.props.value} monoSpacedFont={true} />}
                        {this.props.type === VariableType.Certificate && (
                            <div className={styles.certificate} style={{ height: `${SingleVariableRowHeight}px` }}>
                                <span className={styles.iconContainer}>
                                    <HttpsIcon color={secondaryText} />
                                </span>
                                <ReadonlyText className={styles.deletedText} text={this.certificateDisplay()} />
                            </div>
                        )}
                        {this.props.type === VariableType.AmazonWebServicesAccount && (
                            <AccountDisplay
                                accountId={this.props.value}
                                render={({ account, accountId }) => (
                                    <div className={styles.account} style={{ height: `${SingleVariableRowHeight}px` }}>
                                        <span className={styles.iconContainer}>
                                            <AccountIcon accountType={AccountType.AmazonWebServicesAccount} />
                                        </span>
                                        <ReadonlyText className={styles.deletedText} text={account ? account.name : accountId ? accountId : accountNotSelectedText} />
                                    </div>
                                )}
                            />
                        )}
                        {this.props.type === VariableType.AzureAccount && (
                            <AccountDisplay
                                accountId={this.props.value}
                                render={({ account, accountId }) => (
                                    <div className={styles.account} style={{ height: `${SingleVariableRowHeight}px` }}>
                                        <span className={styles.iconContainer}>
                                            <AccountIcon accountType={account && account.accountType ? account.accountType : AccountType.AzureSubscription} />
                                        </span>
                                        <ReadonlyText className={styles.deletedText} text={account ? account.name : accountId ? accountId : accountNotSelectedText} />
                                    </div>
                                )}
                            />
                        )}
                    </VariableCell>
                ) : (
                    <FocusActionsLayout
                        isFocused={this.props.isFocused}
                        onClickOutside={this.onBlur}
                        actions={[
                            <ActionButton tabIndex={-1} type={ActionButtonType.Ternary} label="Change Type" onClick={(e: any) => this.onChangeTypeClick(e)} />,
                            <ActionButton tabIndex={-1} type={ActionButtonType.Ternary} label="Open Editor" onClick={this.props.onOpenEditorClicked} />,
                        ]}
                    >
                        <VariableCell className={styles.value}>
                            {multilineTextIcon}

                            {promptedVariableIcon}
                            {this.props.type === VariableType.Certificate && (
                                <div className={styles.certificate} ref={certificateLink => (this.itemLink = certificateLink)} tabIndex={0} style={{ height: `${SingleVariableRowHeight}px` }} onClick={this.props.onOpenEditorClicked}>
                                    <span className={styles.iconContainer}>
                                        <HttpsIcon color={secondaryText} />
                                    </span>
                                    <span className={styles.certificateLink}>{this.certificateDisplay()}</span>
                                </div>
                            )}
                            {this.props.type === VariableType.AmazonWebServicesAccount && (
                                <AccountDisplay
                                    accountId={this.props.value}
                                    render={({ account, accountId }) => (
                                        <div className={styles.account} ref={accountLink => (this.itemLink = accountLink)} tabIndex={0} style={{ height: `${SingleVariableRowHeight}px` }} onClick={this.props.onOpenEditorClicked}>
                                            <span className={styles.iconContainer}>
                                                <AccountIcon accountType={AccountType.AmazonWebServicesAccount} />
                                            </span>
                                            {account && <span className={styles.accountLink}>{account.name}</span>}
                                            {!account && <span className={styles.accountLink}>{accountId ? accountId : accountNotSelectedText}</span>}
                                        </div>
                                    )}
                                />
                            )}
                            {this.props.type === VariableType.AzureAccount && (
                                <AccountDisplay
                                    accountId={this.props.value}
                                    render={({ account, accountId }) => (
                                        <div className={styles.account} ref={accountLink => (this.itemLink = accountLink)} tabIndex={0} style={{ height: `${SingleVariableRowHeight}px` }} onClick={this.props.onOpenEditorClicked}>
                                            <span className={styles.iconContainer}>
                                                <AccountIcon accountType={account && account.accountType ? account.accountType : AccountType.AzureSubscription} />
                                            </span>
                                            {account && <span className={styles.accountLink}>{account.name}</span>}
                                            {!account && <span className={styles.accountLink}>{accountId ? accountId : accountNotSelectedText}</span>}
                                        </div>
                                    )}
                                />
                            )}
                            {this.props.type === VariableType.String &&
                                (isMultiline ? (
                                    <MultilineValueContainer onClick={this.props.onOpenEditorClicked} isFocused={this.props.isFocused}>
                                        {this.props.value}
                                    </MultilineValueContainer>
                                ) : (
                                    <DebouncedText
                                        textInputRef={textField => (this.textField = textField)}
                                        value={this.props.value || ""}
                                        showValueAsTitleAttribute={true}
                                        hintText={this.props.hintText}
                                        underlineShow={this.props.isFocused}
                                        applyMaxWidth={false}
                                        monoSpacedFont={true}
                                        onChange={this.props.onValueChanged}
                                    />
                                ))}
                            {this.props.type === VariableType.Sensitive && (
                                <DebouncedSensitive
                                    innerRef={sensitive => (this.sensitiveField = sensitive)}
                                    value={sensitiveValue}
                                    cancelTabIndex={-1}
                                    showHideTabIndex={-1}
                                    onStateChanged={state => this.props.onSensitiveStateChanged(state)}
                                    hintText={this.props.hintText}
                                    underlineShow={this.props.isFocused}
                                    canRemove={false}
                                    applyMaxWidth={false}
                                    monoSpacedFont={true}
                                    onChange={v => this.props.onValueChanged(v.NewValue)}
                                    {...(this.props.sensitiveState || this.createSensitiveInitialStateFromOriginalValue())}
                                />
                            )}
                            {/*Make sure we don't render anything here if the menu is closed.*/
                            /*We want animation on the popover when opening it up, but not on closing.*/
                            /*The popover component will continue to render the contents up to 500ms after it has been closed, regardless of what animation is used*/
                            /*This is problematic for MenuItem components, which try to steal focus after being rendered, and means we can't focus anything else*/
                            this.state.showChangeTypeMenu && (
                                <Popover open={true} key={"popover"} anchorEl={this.changeTypeLink} onClose={() => this.onMenuBlurred()}>
                                    <Menu onChange={(e, val) => this.onTypeChanged(val)} value={this.props.type} selectedMenuItemStyle={{ color: primary }}>
                                        {filteredVariableTypes(this.props.type).map(variable => (
                                            <MenuItem primaryText={variable.text} value={variable.value} />
                                        ))}
                                    </Menu>
                                </Popover>
                            )}
                        </VariableCell>
                    </FocusActionsLayout>
                )}
            </div>
        );
    }

    private certificateDisplay() {
        return this.props.value ? indexOrValue(this.props.certificateIndex, this.props.value) : certificateNotSelectedText;
    }

    private onMenuBlurred() {
        this.setState({ showChangeTypeMenu: false });
    }

    private onTypeChanged(type: VariableType) {
        if (isReferenceType(type)) {
            this.setState({ showChangeTypeMenu: false });
            this.props.onChangeToReferenceType(type);
        } else {
            this.props.onVariableTypeChanged(type);
            this.setState({ showChangeTypeMenu: false });
            this.selectInputAfterNextUpdate = true;
            if (type === VariableType.Sensitive) {
                const sensitiveState = this.createSensitiveInitialStateFromOriginalValue();
                sensitiveState.isEditing = true;
                sensitiveState.valueBeforeEditing = cloneDeep(sensitiveState.originalValue);
                this.props.onSensitiveStateChanged(sensitiveState);
            }
        }
    }

    private onChangeTypeClick(e: React.MouseEvent<HTMLElement>) {
        this.changeTypeLink = e.currentTarget;
        this.setState({ showChangeTypeMenu: true });
        // Should this re-focus the input? What if the input type changes?
    }

    private createSensitiveInitialStateFromOriginalValue() {
        const originalValue: SensitiveValue =
            this.props.existingSensitiveValue !== undefined
                ? {
                      HasValue: true,
                      NewValue: this.props.existingSensitiveValue,
                  }
                : {
                      HasValue: false,
                  };
        return createInitialSensitiveState(originalValue);
    }
}
